import {
  PAYMENT_METHOD_CASH_APP,
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_TYPE_CASH_APP,
  PAYMENT_TYPE_INSTRUCTION,
  PAYMENT_TYPE_LINK,
  PAYMENT_TYPE_OTHER,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_VENMO,
} from "Constants";
import * as yup from "yup";

export const paymentValidationSchema = (existingMethods: string[]) =>
  yup.object().shape({
    method: yup
      .string()
      .when("type", {
        is: PAYMENT_TYPE_LINK,
        then: yup
          .string()
          .notOneOf(existingMethods, "The payment method already exists.")
          .required("Payment method is required"),
      })
      .when("type", {
        is: PAYMENT_TYPE_INSTRUCTION,
        then: yup
          .string()
          .notOneOf(existingMethods, "The payment method already exists.")
          .required("Payment method is required"),
      }),
    type: yup
      .string()
      .oneOf([
        PAYMENT_TYPE_LINK,
        PAYMENT_TYPE_INSTRUCTION,
        PAYMENT_TYPE_CASH_APP,
        PAYMENT_TYPE_PAYPAL,
        PAYMENT_TYPE_VENMO,
      ])
      .required("Payment type is required"),
    link: yup.string().when("type", {
      is: PAYMENT_TYPE_LINK,
      then: yup.string().required("Payment link is required"),
      otherwise: yup.string().nullable(),
    }),
    instruction: yup
      .string()
      .when("type", {
        is: PAYMENT_TYPE_INSTRUCTION,
        then: yup.string().required("This field is required"),
        otherwise: yup.string().nullable(),
      })
      .when("type", {
        is: PAYMENT_TYPE_CASH_APP,
        then: yup.string().required("This field is required"),
        otherwise: yup.string().nullable(),
      })
      .when("type", {
        is: PAYMENT_TYPE_PAYPAL,
        then: yup.string().required("This field is required"),
        otherwise: yup.string().nullable(),
      })
      .when("type", {
        is: PAYMENT_TYPE_VENMO,
        then: yup.string().required("This field is required"),
        otherwise: yup.string().nullable(),
      }),
  });

export interface Payment {
  method: string;
  type: string; // link or instruction
  link: string; // link
  instruction: string; // instruction
}

export const defaultPayment: Payment = {
  method: "",
  type: PAYMENT_TYPE_INSTRUCTION,
  link: "",
  instruction: "",
};
