import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AggregatedOrderItem } from "Model/PresaleOrder";
import { Box, Typography, Stack, Chip, Button, Link } from "@mui/material";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import CastConnectedIcon from "@mui/icons-material/CastConnected";
import CastIcon from "@mui/icons-material/Cast";
import { lowerCase } from "lodash";

type ItemProps = {
  itemInfo: AggregatedOrderItem;
};

export const RevenuePerItem = ({ itemInfo }: ItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box
        sx={{
          width: "64px",
          height: "64px",
          borderRadius: 1,
          backgroundColor: "background.default",
        }}
      >
        {itemInfo.img ? (
          <img
            src={itemInfo.img}
            style={{
              width: "64px",
              height: "64px",
              display: "block",
              borderRadius: "4px",
              objectFit: "cover",
            }}
          />
        ) : null}
      </Box>
      <Stack direction="row" spacing={1} alignItems="flex-start" flex={1}>
        <Stack flex={1}>
          <Typography variant="subtitle2">{itemInfo.title}</Typography>
          {itemInfo.flavor ? (
            <Chip
              size="small"
              label={itemInfo.flavor}
              sx={{ width: "fit-content" }}
            />
          ) : null}
        </Stack>
        <Typography variant="body2" color="text.secondary">
          Sold: {itemInfo.count}
        </Typography>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: 500 }}
        >
          ${itemInfo.price * itemInfo.count}
        </Typography>
      </Stack>
    </Stack>
  );
};
