import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useCanceledOrders,
  useCompletedOrders,
} from "Redux/hooks";
import { select } from "Redux/actionCreators";
import { selectedOrderSelector } from "Redux/selectors";

// ui related
import {
  Box,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Stack,
} from "@mui/material";
import { getDateFromString, getMonthFromString } from "Util/dateUtil";

//icons
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import { Unit } from "Model/CustomerRequestInfo";

interface Props {
  isCancel?: boolean;
}

export const CompletedOrdersDrawer = ({ isCancel }: Props) => {
  const dispatch = useAppDispatch();
  const completedOrders = useCompletedOrders();
  const canceledOrders = useCanceledOrders();
  const localOrders = isCancel ? canceledOrders : completedOrders;
  const selectedOrder = useAppSelector(selectedOrderSelector);
  const navigate = useNavigate();

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        {isCancel ? "Canceled" : "Completed"} orders
      </Typography>
      <List component="nav" sx={{ p: 0 }}>
        {localOrders.map((order, index) => (
          <div key={order.orderId}>
            <ListItemButton
              selected={order.orderId === selectedOrder.orderId}
              onClick={() => {
                dispatch(select(order));
                if (isCancel) {
                  navigate(`/admin/canceled-orders/${order.orderId}`);
                } else {
                  navigate(`/admin/completed-orders/${order.orderId}`);
                }
              }}
            >
              <Stack
                sx={{
                  mr: 2,
                  alignItems: "center",
                  width: 32,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ textTransform: "uppercase", fontWeight: 600 }}
                >
                  {getMonthFromString(order.customerDate).toUpperCase()}
                </Typography>
                <Typography variant="body2">
                  {getDateFromString(order.customerDate)}
                </Typography>
              </Stack>
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center">
                    <Typography
                      flex={1}
                      variant="body2"
                      sx={{ fontWeight: 600 }}
                    >
                      {"Order #" + order.orderId}
                    </Typography>
                    {order.customerFulfillment === "Shipping" ? (
                      <LocalShippingRoundedIcon fontSize="small" />
                    ) : null}
                  </Stack>
                }
                secondary={
                  order.firstName +
                  " · " +
                  order.count +
                  (order.unit == Unit.Dozen ? " dozen" : " cookies")
                }
              />
            </ListItemButton>
          </div>
        ))}
      </List>
    </Box>
  );
};
