import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderInfo } from "Model/OrderInfo";
import { defaultOrdersStatus, OrdersStatus } from "Model/OrdersStatus";
import { updateOrderStatus, db } from "Util/firebaseHelper";
import { selectedOrderSlice } from "./selectedOrder";
import produce from "immer";
import { ordersSlice } from "./orders";
import { doc, onSnapshot } from "firebase/firestore";
import { ORDERS_TABLE_NAME } from "Constants";

export const ordersStatusSlice = createSlice({
  name: "ordersStatus",
  initialState: defaultOrdersStatus,
  reducers: {
    editOrdersStatus: (state, action: PayloadAction<OrdersStatus>) => {
      //   // if state = action.payload, it will not work
      //   //   return action.payload;
      //   state.ordersMap = action.payload.ordersMap;
      //   state.totalOrders = action.payload.totalOrders;
      return produce(state, (draftState) => {
        draftState.ordersMap = action.payload.ordersMap;
        draftState.totalOrders = action.payload.totalOrders;
      });
    },
    sortOrdersStatus: (
      state,
      action: PayloadAction<{ orders: string[]; key: string }>
    ) => {
      state.ordersMap[action.payload.key] = action.payload.orders;
    },
  },
});

export const updateOrderStatusAsync = createAsyncThunk(
  "ordersStatus/updateOrderStatus",
  async (
    {
      userId,
      orderInfo,
      orderStatus,
      ordersStatus,
    }: {
      userId: string;
      orderInfo: OrderInfo;
      orderStatus: string;
      ordersStatus: OrdersStatus;
    },
    thunkAPI
  ) => {
    if (userId) {
      await updateOrderStatus(
        userId,
        orderInfo.orderId,
        orderStatus,
        ordersStatus
      );
      thunkAPI.dispatch(
        ordersStatusSlice.actions.editOrdersStatus(ordersStatus)
      );
      thunkAPI.dispatch(ordersSlice.actions.edit(orderInfo));
      thunkAPI.dispatch(selectedOrderSlice.actions.select(orderInfo));
    }
  }
);

export const fetchOrdersStatusAsync = createAsyncThunk(
  "ordersStatus/fetchOrdersStatus",
  async (userId: string, thunkAPI) => {
    const ordersStatusRef = doc(db, ORDERS_TABLE_NAME, userId);
    onSnapshot(ordersStatusRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const ordersStatusData = docSnapshot.data() as OrdersStatus;
        thunkAPI.dispatch(
          ordersStatusSlice.actions.editOrdersStatus({
            totalOrders: ordersStatusData.totalOrders,
            ordersMap: ordersStatusData.ordersMap,
          })
        );
      } else {
        thunkAPI.dispatch(
          ordersStatusSlice.actions.editOrdersStatus(defaultOrdersStatus)
        );
      }
    });
  }
);
