import React, { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import _ from "lodash";

import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { useAppDispatch } from "Redux/hooks";
import { Payment, defaultPayment } from "Model/Payment";
import {
  PAYMENT_TYPE_INSTRUCTION,
  PAYMENT_TYPE_LINK,
  REVOKE_FUNCTION,
} from "Constants";
import { Profile } from "Model/Profile";
import { PaymentMethod } from "Components/PaymentMethod";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";

export const PaymentLinks = ({ sellerProfile }: { sellerProfile: Profile }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [currentMethod, setCurrentMethod] = useState("");
  const [currentInstruction, setCurrentInstruction] = useState("");

  return (
    <div>
      <Box>
        {sellerProfile.payments.map((p) =>
          p.type === PAYMENT_TYPE_LINK ||
          p.type === PAYMENT_TYPE_INSTRUCTION ? (
            <Chip
              variant="outlined"
              label={p.method}
              icon={
                p.type === PAYMENT_TYPE_LINK ? (
                  <InsertLinkOutlinedIcon />
                ) : (
                  <></>
                )
              }
              onClick={() => {
                if (p.type === PAYMENT_TYPE_LINK) {
                  window.open(p.link, "_blank");
                } else {
                  setCurrentMethod(p.method);
                  setCurrentInstruction(p.instruction);
                  handleOpenModal();
                }
              }}
              sx={{
                mr: 1,
                mb: 1,
                backgroundColor: "white",
                border: "none",
              }}
            />
          ) : (
            <PaymentMethod
              editing={false}
              payment={p}
              key={p.type + p.method}
              onSave={() => {}}
              onDelete={() => {}}
            />
          )
        )}
      </Box>
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>{currentMethod}</DialogTitle>
        <DialogContent>
          <Typography>{currentInstruction}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
