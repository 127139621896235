import { useState } from "react";
import { useSession } from "Context/userAuthContext";

// ui related
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import {
  QuoteRequestForm,
  SubNav,
  ContentWrapNarrow,
  Loading,
} from "Components/AllComponents";
import { QuoteRequestFormSettings } from "./QuoteRequestForm/QuoteRequestFormSettings";
import { useFormik } from "formik";
import { businessInfoValidationSchema } from "Model/BusinessInfo";
import { editBusinessInfo, setBusinessInfoDirty } from "Redux/actionCreators";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { businessInfoSelector } from "Redux/selectors";
import { publishBusinessInfo } from "Util/firebaseHelper";
import { SITE_URL } from "Constants";
import { useNavigate } from "react-router-dom";

const drawerWidth = 260;
interface Props {
  window?: () => Window;
}

const EditForm = (props: Props) => {
  const { user, sellerProfile } = useSession();
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { businessInfo } = useAppSelector(businessInfoSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [publishResult, setPublishResult] = useState("");
  const [openPublishForm, setOpenPublishForm] = useState(false);
  const handleOpenPublishForm = () => {
    setOpenPublishForm(true);
  };
  const handleClosePublishForm = () => {
    setOpenPublishForm(false);
    navigate(-1);
  };
  const formik = useFormik({
    validateOnChange: false,
    initialValues: { ...businessInfo, sellerEmail: user?.email || "" },
    validationSchema: businessInfoValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 2));
      handleOpenPublishForm();
      setPublishResult("Publishing form");
      if (user) {
        var result = await publishBusinessInfo(sellerProfile, values, user.uid);
        console.log("published form", result);
        if (!result) {
          setPublishResult("We couldn't publish your form. Please retry.");
        } else {
          dispatch(editBusinessInfo(values));
          setPublishResult("Your form was published successfully.");
          resetForm({ values: { ...values } });
        }
      }
    },
  });

  return (
    <Box sx={{ display: "flex" }}>
      <Dialog open={openPublishForm} onClose={handleClosePublishForm} fullWidth>
        {publishResult === "Publishing form" ? (
          <Loading />
        ) : publishResult === "Your form was published successfully." ? (
          <div>
            <DialogTitle>Form published</DialogTitle>
            <DialogContent>
              <Typography sx={{ mb: 1 }}>
                You will receive an email once a new request is submitted.
              </Typography>
              <Link
                href={"/" + sellerProfile.username + "/form/" + businessInfo.id}
                target="_blank"
              >
                View form
              </Link>
            </DialogContent>
          </div>
        ) : (
          <DialogContent>{publishResult}</DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClosePublishForm} color="secondary">
            Go back
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      {/* ---------- app bar ---------- */}
      <SubNav
        drawerName="Form settings"
        returnTo="-1"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
        backBtn="Back"
        onConfirm={() => {
          formik.resetForm();
          dispatch(setBusinessInfoDirty(false));
        }}
      />

      {/* ---------- form settings drawer ---------- */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <QuoteRequestFormSettings formik={formik} />
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
          open
        >
          <Box
            sx={{
              overflow: "auto",

              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <QuoteRequestFormSettings formik={formik} />
          </Box>
        </Drawer>
      </Box>

      {/* ---------- form ---------- */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: {
            md: `calc(100% - ${(
              <QuoteRequestFormSettings formik={formik} />
            )}px)`,
          },
          minHeight: "100vh",
        }}
      >
        <ContentWrapNarrow>
          <QuoteRequestForm
            formik={formik}
            formPublished={
              sellerProfile.forms && sellerProfile.forms.length > 0
            }
          />
        </ContentWrapNarrow>
      </Box>
    </Box>
  );
};

export default EditForm;
