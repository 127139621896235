import React, { ReactChild } from "react";

import { Typography, Stack, Box, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TuneIcon from "@mui/icons-material/Tune";

type Props = {
  visible: boolean;
  children: React.ReactNode;
};

export const FormEditor = ({ visible, children }: Props) => {
  if (visible) {
    return (
      <Stack
        sx={{
          border: "2px solid",
          borderColor: "primary.main",
          borderRadius: 2,
          p: { xs: 1, sm: 2 },
        }}
      >
        {children}
      </Stack>
    );
  } else {
    return null;
  }
};
