import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultOrderInfo, OrderInfo } from "Model/OrderInfo";
import { updateOrderInfo } from "Util/firebaseHelper";
import { ordersSlice } from "./orders";

export const selectedOrderSlice = createSlice({
  name: "selectedOrder",
  initialState: defaultOrderInfo,
  reducers: {
    select: (state, { payload }: PayloadAction<OrderInfo>) => payload,
  },
});

export const updateSelectedOrderAsync = createAsyncThunk(
  "selectedOrder/updateOrder",
  async (
    { userId, orderInfo }: { userId: string; orderInfo: OrderInfo },
    thunkAPI
  ) => {
    if (userId) {
      await updateOrderInfo(userId, orderInfo);
      thunkAPI.dispatch(ordersSlice.actions.edit(orderInfo));
      thunkAPI.dispatch(selectedOrderSlice.actions.select(orderInfo));
    }
  }
);
