import { Link, Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type Props = {
  icon?: React.ReactElement;
  label: string;
  text: string;
  bold?: boolean;
  keepTextFormatting?: boolean;
  onClickInfo?: any;
};

export const LabeledText = ({
  icon,
  label,
  text,
  bold,
  keepTextFormatting,
  onClickInfo,
}: Props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2} flex={1}>
      {icon ? (
        <Stack
          sx={{
            p: 1.5,
            backgroundColor: "background.default",
            borderRadius: 1,
          }}
        >
          <Stack sx={{ opacity: 0.4, p: 0 }}>{icon}</Stack>
        </Stack>
      ) : null}
      <Stack>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
        <Stack flexDirection="row" alignItems="center" columnGap={0.5}>
          <Typography
            variant={bold ? "subtitle2" : "body1"}
            sx={{
              overflowWrap: "anywhere",
              whiteSpace: keepTextFormatting ? "pre-line" : "inherit",
            }}
          >
            {text}
          </Typography>
          {onClickInfo ? (
            <InfoOutlinedIcon
              onClick={onClickInfo}
              color="success"
              fontSize="small"
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};
