import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { BusinessInfo, defaultBusinessInfo } from "Model/BusinessInfo";
import { getBusinessInfoFromUser } from "Util/firebaseHelper";
import { fetchAllRequests } from "./requests";

const initialState = {
  businessInfo: defaultBusinessInfo,
  loading: false,
  dirty: false,
};

export const fetchBusinessInfoAsync = createAsyncThunk(
  "businessInfo/fetchBusinessInfo",
  async (user: User, thunkAPI) => {
    if (user && user.uid) {
      const form = await getBusinessInfoFromUser(user.uid);
      let business = form?.data();
      if (business) {
        thunkAPI.dispatch(
          businessInfoSlice.actions.editBusinessInfo({
            id: business?.id || "",
            businessName: business?.businessName || "",
            formIntro: business?.formIntro || "",
            sellerName: business?.sellerName || "",
            sellerIntro: business?.sellerIntro || "",
            sellerCity: business?.sellerCity || "",
            sellerState: business?.sellerState || "",
            fulfillmentMethod: business?.fulfillmentMethod || "Pickup only",
            minOrder: business?.minOrder || 1,
            priceChoice: business?.priceChoice || "Starting price",
            price: business?.price || 1,
            sellerBookedUp: business?.sellerBookedUp || false,
            sellerAvailability: business?.sellerAvailability || 0,
            orderDetails: business?.orderDetails || "",
            sellerEmail: business?.sellerEmail || user.email || "",
            bucketLists: business?.bucketLists || [""],
            flavors: business?.flavors || [""],
            packagings: business?.packagings || [""],
            paymentTypes: business?.paymentTypes || [""],
            agreements: business?.agreements || "",
            showBucketLists: business?.showBucketLists || false,
            showFlavors: business?.showFlavors || false,
            showPackagings: business?.showPackagings || false,
            showPaymentTypes: business?.showPaymentTypes || false,
            sellerPhoto: business?.sellerPhoto || "",
            aic: business?.aic || false,
            username: business?.username || "",
          })
        );
        if (business.id) {
          thunkAPI.dispatch(fetchAllRequests(business.id));
        }
      } else {
        thunkAPI.dispatch(
          businessInfoSlice.actions.editBusinessInfo(defaultBusinessInfo)
        );
      }
    }
  }
);

export const businessInfoSlice = createSlice({
  name: "businessInfo",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    editBusinessInfo: (state, action: PayloadAction<BusinessInfo>) => {
      state.businessInfo = action.payload;
    },
    setBusinessInfoDirty: (state, action: PayloadAction<boolean>) => {
      state.dirty = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessInfoAsync.fulfilled, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(fetchBusinessInfoAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBusinessInfoAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});
