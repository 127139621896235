import { useState } from "react";
import { useSession } from "Context/userAuthContext";
import { logOut, publishProfile, uploadImage } from "Util/firebaseHelper";
import { Formik, Form } from "formik";
import { profileValidationSchema } from "Model/Profile";
import { editSellerProfile } from "Redux/actionCreators";
import { useAppDispatch } from "Redux/hooks";
import USStates from "Components/USStates.json";

import {
  Button,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
  InputAdornment,
  Stack,
  InputLabel,
  Select,
  Divider,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import {
  InfoBox,
  ContentWrapNarrow,
  CardStyle,
} from "Components/AllComponents";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "boring-avatars";
import { resizeFile } from "Util/helpers";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const { user, sellerProfile } = useSession();
  const dispatch = useAppDispatch();
  const [uploadingImage, setUploadingImage] = useState(false);
  const navigate = useNavigate();

  return (
    <Stack
      alignItems="center"
      sx={{ backgroundColor: "background.default", minHeight: "100vh", p: 2 }}
    >
      <ContentWrapNarrow>
        <Formik
          validateOnChange={false}
          initialValues={sellerProfile}
          enableReinitialize={true}
          validationSchema={profileValidationSchema(
            sellerProfile.username.toLowerCase()
          )}
          onSubmit={(values, actions) => {
            // alert(JSON.stringify(values, null, 2));
            publishProfile(
              {
                ...values,
                username: values.username.toLowerCase(),
                links: sellerProfile.links,
                uid: user?.uid || "",
                sellerEmail: user?.email || "",
              },
              user?.uid || ""
            );
            actions.resetForm({
              values: values,
            });
            dispatch(editSellerProfile(values));
            navigate("/admin/home");
          }}
        >
          {(props) => (
            <Form>
              <Stack spacing={3}>
                <Stack>
                  <Typography variant="subtitle2">Google account</Typography>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body1" flex={1}>
                      {user?.email}
                    </Typography>
                    <Button
                      onClick={() => {
                        logOut();
                        window.location.reload();
                      }}
                      sx={{ color: "text.secondary" }}
                    >
                      Sign out
                    </Button>
                  </Stack>
                </Stack>
                <CardStyle>
                  <Typography color="text.primary" variant="h4">
                    One more step
                  </Typography>
                  <Typography
                    color="text.primary"
                    variant="body1"
                    sx={{ mt: 1 }}
                  >
                    Let's work on completing your profile. You can always change
                    it later.
                  </Typography>
                </CardStyle>

                <CardStyle>
                  <Typography color="text.primary" variant="h6">
                    Username
                  </Typography>
                  <TextField
                    fullWidth
                    margin="dense"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography color="text.primary">
                            flowlylink.com/
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    required
                    id="username"
                    name="username"
                    value={props.values.username.toLowerCase()}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.touched.username && Boolean(props.errors.username)
                    }
                    helperText={props.touched.username && props.errors.username}
                  />
                </CardStyle>

                <CardStyle>
                  <Typography color="text.primary" variant="h6" sx={{ mb: 1 }}>
                    About your business
                  </Typography>
                  <Box
                    sx={{
                      aspectRatio: "1/1",
                      borderRadius: "50%",
                      backgroundColor: "background.default",
                      width: "72px",
                      overflow: "hidden",
                      mb: 3,
                    }}
                  >
                    {props.values.sellerPhoto === "" ? (
                      <LoadingButton
                        color="secondary"
                        component="label"
                        loading={uploadingImage}
                        sx={{
                          position: "relative",
                          width: "100%",
                          aspectRatio: "1/1",
                          backgroundColor: "background.default",
                          overflow: "hidden",
                          "&:hover": { opacity: 0.8 },
                          textAlign: "center",
                        }}
                      >
                        Logo (optional)
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={async (e) => {
                            // e.preventDefault();
                            setUploadingImage(true);
                            if (
                              user &&
                              e.target.files &&
                              e.target.files.length > 0
                            ) {
                              const image = e.target.files[0];
                              const resizedImage = await resizeFile(
                                image,
                                300,
                                300
                              );
                              var url = await uploadImage(
                                resizedImage as File,
                                user.uid
                              );
                              props.setFieldValue("sellerPhoto", url);
                              console.log("uploaded photo", url);
                            }
                            setUploadingImage(false);
                          }}
                        />
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        color="secondary"
                        component="label"
                        loading={uploadingImage}
                        sx={{
                          position: "relative",
                          width: "100%",
                          p: 0,
                          "&:hover": { opacity: 0.8 },
                        }}
                      >
                        <img
                          src={props.values.sellerPhoto}
                          width={72}
                          height={72}
                          style={{ objectFit: "cover", borderRadius: "50%" }}
                        />
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={async (e) => {
                            // e.preventDefault();
                            setUploadingImage(true);
                            if (
                              user &&
                              e.target.files &&
                              e.target.files.length > 0
                            ) {
                              const image = e.target.files[0];
                              const resizedImage = await resizeFile(
                                image,
                                300,
                                300
                              );
                              var url = await uploadImage(
                                resizedImage as File,
                                user.uid
                              );
                              props.setFieldValue("sellerPhoto", url);
                              console.log("uploaded photo", url);
                            }
                            setUploadingImage(false);
                          }}
                        />
                      </LoadingButton>
                    )}
                  </Box>
                  <TextField
                    fullWidth
                    required
                    id="businessName"
                    name="businessName"
                    label="Business name"
                    value={props.values.businessName}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.touched.businessName &&
                      Boolean(props.errors.businessName)
                    }
                    helperText={
                      props.touched.businessName && props.errors.businessName
                    }
                  />
                </CardStyle>

                <CardStyle>
                  <Typography color="text.primary" variant="h6" sx={{ mb: 1 }}>
                    Fulfillment methods
                  </Typography>
                  <FormControl required fullWidth>
                    <RadioGroup
                      name="fulfillmentMethod"
                      id="fulfillmentMethod"
                      value={props.values.fulfillmentMethod}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <Stack direction="row" spacing={1}>
                        <FormControlLabel
                          value="Pickup only"
                          control={<Radio />}
                          label="Pickup"
                        />
                        <FormControlLabel
                          value="Pickup or shipping"
                          control={<Radio />}
                          label="Pickup or shipping"
                        />
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        required
                        id="defaultPickupAddress"
                        name="defaultPickupAddress"
                        label="Default pickup address"
                        value={props.values.defaultPickupAddress}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={
                          props.touched.defaultPickupAddress &&
                          Boolean(props.errors.defaultPickupAddress)
                        }
                        helperText={
                          props.touched.defaultPickupAddress &&
                          props.errors.defaultPickupAddress
                            ? props.errors.defaultPickupAddress
                            : "This address is saved for your convenience when setting up presale pickup locations and will never be disclosed to your customers without your permission."
                        }
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        margin="normal"
                        required
                        id="sellerCity"
                        name="sellerCity"
                        label="City"
                        value={props.values.sellerCity}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={
                          props.touched.sellerCity &&
                          Boolean(props.errors.sellerCity)
                        }
                        helperText={
                          props.touched.sellerCity && props.errors.sellerCity
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FormControl required fullWidth sx={{ mt: 2, mb: 1 }}>
                        <Autocomplete
                          freeSolo
                          options={USStates.map((state) => state.name)}
                          onChange={(event: any, newValue: string | null) => {
                            if (newValue) {
                              props.setFieldValue("sellerState", newValue);
                            }
                          }}
                          inputValue={props.values.sellerState}
                          onInputChange={(event, newInputValue) => {
                            props.setFieldValue("sellerState", newInputValue);
                          }}
                          renderInput={(params) => (
                            <TextField required label="State" {...params} />
                          )}
                        />
                        {/* <InputLabel>State</InputLabel>
                        <Select
                          label="State"
                          value={props.values.sellerState}
                          onChange={(e) => {
                            props.setFieldValue("sellerState", e.target.value);
                          }}
                        >
                          {USStates.map((state) => (
                            <MenuItem
                              value={state.abbreviation}
                              key={state.name}
                            >
                              {state.name}
                            </MenuItem>
                          ))}
                        </Select> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        fullWidth
                        margin="normal"
                        required
                        id="sellerZip"
                        name="sellerZip"
                        label="Zip code"
                        value={props.values.sellerZip}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={
                          props.touched.sellerZip &&
                          Boolean(props.errors.sellerZip)
                        }
                        helperText={
                          props.touched.sellerZip && props.errors.sellerZip
                        }
                      />
                    </Grid>
                  </Grid>
                </CardStyle>

                <Stack alignItems="center">
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={!user}
                    sx={{ width: "fit-content" }}
                  >
                    All set
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </ContentWrapNarrow>
    </Stack>
  );
};

export default Onboarding;
