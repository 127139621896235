import {
  Body,
  Container,
  Column,
  Head,
  Hr,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
  Button,
} from "@react-email/components";
import { PRESALE_ANNOUCEMENT_SUB_EMAIL } from "Constants";
import { PresaleInfo } from "Model/PresaleInfo";
import * as React from "react";

interface PresaleAnnouncementProps {
  emailHeading: string;
  emailBody: string;
  presale: PresaleInfo;
  sellerName: string;
  sellerPhoto: string;
  businessName: string;
  displayMode: boolean;
  smallScreen?: boolean;
}

export const PresaleAnnouncement = ({
  sellerName,
  sellerPhoto,
  businessName,
  emailHeading,
  emailBody,
  presale,
  displayMode,
  smallScreen,
}: PresaleAnnouncementProps) => {
  return (
    <Html>
      <Head />
      <Preview>{emailHeading}</Preview>
      <Body style={main}>
        <Container
          style={{
            width: smallScreen ? "100%" : "600px",
            maxWidth: "100%",
            border: "1px solid #E5E5E5",
            padding: "16px",
            borderRadius: "4px",
            marginBottom: "8px",
            marginTop: "8px",
          }}
        >
          <Link href={`https://flowlylink.com/${sellerName}`} target="_blank">
            <Row>
              <Column style={{ width: 0 }}>
                <Img
                  src={sellerPhoto}
                  width="40"
                  height="40"
                  alt="Seller info"
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "white",
                  }}
                />
              </Column>
              <Text
                style={{
                  fontSize: "15px",
                  fontWeight: 600,
                  color: "#000",
                  paddingLeft: "12px",
                }}
              >
                {businessName}
              </Text>
            </Row>
          </Link>

          <Hr style={{ borderColor: "#E5E5E5", margin: "16px 0 40px 0" }} />

          <Text style={heading}>{emailHeading}</Text>
          <Text style={text}>{emailBody}</Text>
          {presale.presaleItems.map((item, index) =>
            index % 2 === 0 ? (
              <Row key={index}>
                <Column width="50%">
                  <a
                    href={`https://flowlylink.com/${sellerName}/presale/${presale.presaleId}`}
                    target="_blank"
                  >
                    <Img
                      src={item.img}
                      alt={item.title}
                      width="100%"
                      height="284px"
                      style={{ objectFit: "cover" }}
                    />
                  </a>
                </Column>
                <Column width="50%">
                  {presale.presaleItems[index + 1] && (
                    <a
                      href={`https://flowlylink.com/${sellerName}/presale/${presale.presaleId}`}
                      target="_blank"
                    >
                      <Img
                        src={presale.presaleItems[index + 1].img}
                        alt={presale.presaleItems[index + 1].title}
                        width="100%"
                        height="284px"
                        style={{ objectFit: "cover" }}
                      />
                    </a>
                  )}
                </Column>
              </Row>
            ) : null
          )}
          <Section style={btnContainer}>
            <Button
              style={button}
              href={`https://flowlylink.com/${sellerName}/presale/${presale.presaleId}`}
            >
              Order Now
            </Button>
          </Section>

          <Hr style={{ borderColor: "#E5E5E5", margin: "32px 0 0 0" }} />
          <Text style={footer}>
            This email was sent from{" "}
            <a href={`https://flowlylink.com/${sellerName}`} target="_blank">
              this site
            </a>
            . If you no longer wish to receive this email,{" "}
            {displayMode ? (
              <a>unsubscribe</a>
            ) : (
              <a
                href={`https://flowlylink.com/unsubscribe?data=${PRESALE_ANNOUCEMENT_SUB_EMAIL}`}
              >
                unsubscribe
              </a>
            )}{" "}
            here.
          </Text>
        </Container>
      </Body>
    </Html>
  );
};

const main = {
  backgroundColor: "#ffffff",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  height: "fit-content",
};

const heading = {
  fontSize: "26px",
  fontWeight: "600",
  textAlign: "center",
} as React.CSSProperties;

const text = {
  fontSize: "15px",
  marginTop: 0,
  fontWeight: 500,
  color: "#747474",
  textAlign: "center",
} as React.CSSProperties;

const btnContainer = {
  marginTop: "24px",
  textAlign: "center" as const,
};

const button = {
  backgroundColor: "#000000",
  borderRadius: "3px",
  color: "#fff",
  fontSize: "16px",
  fontWeight: "600",
  textDecoration: "none",
  textAlign: "center" as const,
  display: "block",
  padding: "12px",
};

const footer = {
  color: "#747474",
  fontSize: "12px",
};
