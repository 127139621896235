import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  Typography,
  Stack,
  IconButton,
  TextField,
  Chip,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  ToggleButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  Autocomplete,
  ListItemText,
  List,
  ListItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";

//icons
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Flavor, PresaleItem } from "Model/PresaleItem";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { updateItemByCount } from "Redux/actionCreators";
import { selectCartItems } from "Redux/selectors";
import _, { set } from "lodash";
import { InfoBox } from "Components/AllComponents";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {
  PresaleOrderItemCustomization,
  defaultPresaleOrderItemCustomization,
} from "Model/PresaleOrder";
import {
  PERSONALIZATION_TYPE_BOTH,
  PERSONALIZATION_TYPE_CUSTOM_ONLY,
  PERSONALIZATION_TYPE_PRESET_ONLY,
} from "Constants";

export const PresaleItems = ({
  presaleItems,
}: {
  presaleItems: PresaleItem[];
}) => {
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector(selectCartItems);
  const [openAddName, setAddName] = useState(false);
  const [currentItemId, setCurrentItemId] = useState("");
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [currentPersonalization, setCurrentPersonalization] = useState("");

  const [currentFlavor, setCurrentFlavor] = useState<Flavor>({
    flavorPrice: 0,
    flavorName: "",
  }); // Use the Flavor type for currentFlavor
  const [currentColor, setCurrentColor] = useState("");

  // dont touch it, gpt generated
  const generateLabel = (p: PresaleOrderItemCustomization) => {
    const result = `${p.personalization}${
      p.flavor ? `${p.personalization ? ", " : ""}${p.flavor}` : ""
    }${
      p.color && !p.flavor ? `${p.personalization ? ", " : ""}${p.color}` : ""
    }${p.flavor && p.color ? `, ${p.color}` : ""}${
      (p.flavor || p.color) && p.personalization ? "" : ""
    }`;
    return result;
  };

  const flavorToDisplay = (item: PresaleItem) => {
    if (
      currentItemId === item.id &&
      currentFlavor &&
      currentFlavor.flavorName !== ""
    ) {
      return `$${item.price + currentFlavor?.flavorPrice}`;
    }
    if (item.flavors.length > 0) {
      const flavorPrices = item.flavors.map((f) => f.flavorPrice);
      const minPrice = item.price + Math.min(...flavorPrices);
      const maxPrice = item.price + Math.max(...flavorPrices);
      const priceRange =
        minPrice !== maxPrice ? `$${minPrice} - $${maxPrice}` : `$${minPrice}`;
      return priceRange;
    } else {
      return `$${item.price}`;
    }
  };

  const theme = useTheme();
  const colors = {
    frontColor: theme.palette.primary.main,
    backColor: theme.palette.background.default,
  };

  const customizable = (item: PresaleItem) =>
    (item.personalizable &&
      !(
        item.personalizationType === PERSONALIZATION_TYPE_PRESET_ONLY &&
        item.personalizationOptions.length === 0
      )) ||
    item.flavors.length > 0 ||
    item.colors.length > 0;

  const backgroundPattern = `
    repeating-linear-gradient(
      -45deg,
      ${colors.frontColor},
      ${colors.frontColor} 8px,
      ${colors.backColor} 8px,
      ${colors.backColor} 64px
    )
  `;

  // item images
  const Carousel = require("react-responsive-carousel").Carousel;

  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4, 1500: 5 }}
    >
      <Masonry gutter="24px">
        {presaleItems.map((item, index) => (
          <Stack
            key={item.id}
            sx={{
              borderRadius: 3,
              overflow: "hidden",
              boxShadow:
                cartItems[item.id]?.count > 0
                  ? "rgba(0, 0, 0, 0.1) 0px 4px 12px 0px"
                  : "none",
              outline: cartItems[item.id]?.count > 0 ? "2px solid" : "none",
              outlineColor:
                cartItems[item.id]?.count > 0
                  ? "rgba(0, 0, 0, 0.6)"
                  : "transparent",
            }}
          >
            {/* image */}
            {item.img ? (
              <img
                style={{
                  width: "100%",
                  display: "block",
                }}
                src={item.img}
              />
            ) : (
              <Box
                sx={{
                  aspectRatio: "1/1",
                  backgroundColor: "background.default",
                  opacity: 0.04,
                  background: backgroundPattern,
                }}
              />
            )}

            <Stack
              sx={{
                p: 2,
                backgroundColor: "white",
              }}
            >
              {/* title & description */}
              <Stack>
                {item.title && (
                  <Typography variant="subtitle2">{item.title}</Typography>
                )}
                {item.description && (
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {item.description}
                  </Typography>
                )}
              </Stack>

              {/* price & quantity */}
              <Stack
                direction="row"
                alignItems="center"
                flex={1}
                sx={{
                  height: "40px",
                  mb: 1,
                }}
              >
                <Stack flex={1}>
                  <Typography variant="subtitle2">${item.price}</Typography>
                </Stack>
                {item.soldOut ? (
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ fontWeight: 600 }}
                  >
                    SOLD OUT
                  </Typography>
                ) : (
                  <Stack direction="row" alignItems="center">
                    {cartItems[item.id]?.count > 1 && !customizable(item) ? (
                      <Box
                        sx={{
                          backgroundColor: "rgba(0,0,0,0.04)",
                          borderRadius: 100,
                        }}
                      >
                        <IconButton
                          size="medium"
                          disabled={openAddName}
                          onClick={() => {
                            setCurrentItemId(item.id);
                            setCurrentItemIndex(index);
                            dispatch(
                              updateItemByCount({
                                item: cartItems[item.id],
                                deltaCount: -1,
                                customizable: false,
                                customization:
                                  defaultPresaleOrderItemCustomization,
                                customizationToDeleteIndex:
                                  cartItems[item.id].count - 1,
                              })
                            );
                          }}
                        >
                          <RemoveRoundedIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    ) : cartItems[item.id]?.count === 1 &&
                      !customizable(item) ? (
                      <Box
                        sx={{
                          backgroundColor: "rgba(0,0,0,0.04)",
                          borderRadius: 100,
                        }}
                      >
                        <IconButton
                          size="medium"
                          disabled={openAddName}
                          onClick={() => {
                            setCurrentItemId(item.id);
                            setCurrentItemIndex(index);
                            dispatch(
                              updateItemByCount({
                                item: cartItems[item.id],
                                deltaCount: -1,
                                customizable: false,
                                customization:
                                  defaultPresaleOrderItemCustomization,
                                customizationToDeleteIndex:
                                  cartItems[item.id].count - 1,
                              })
                            );
                          }}
                        >
                          <DeleteOutlineRoundedIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    ) : null}

                    {cartItems[item.id]?.count > 0 && !customizable(item) ? (
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        sx={{
                          maxWidth: "20px",
                          ml: 2,
                          mr: 2,
                          lineHeight: 1,
                        }}
                      >
                        {cartItems[item.id]?.count}
                      </Typography>
                    ) : null}

                    <Box
                      sx={{
                        backgroundColor: "rgba(0,0,0,0.04)",
                        borderRadius: 100,
                      }}
                    >
                      <IconButton
                        size="medium"
                        color="secondary"
                        disabled={openAddName}
                        onClick={() => {
                          setCurrentPersonalization(
                            item.personalizable &&
                              item.personalizationType ===
                                PERSONALIZATION_TYPE_PRESET_ONLY &&
                              item.personalizationOptions.length > 0
                              ? item.personalizationOptions[0]
                              : ""
                          );
                          setCurrentItemId(item.id);
                          setCurrentItemIndex(index);
                          if (customizable(item)) {
                            setAddName(true);
                            setCurrentFlavor(item.flavors[0]);
                            setCurrentColor(item.colors[0]);
                          } else {
                            dispatch(
                              updateItemByCount({
                                item: cartItems[item.id],
                                deltaCount: 1,
                                customizable: false,
                                customization: {
                                  price: item.price,
                                  flavor: "",
                                  color: "",
                                  personalization: "",
                                  count: 1,
                                },
                                customizationToDeleteIndex: -1,
                              })
                            );
                          }
                        }}
                      >
                        <AddRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Stack>
                )}
              </Stack>

              {/* personalization and other options */}
              {openAddName &&
              index === currentItemIndex &&
              customizable(item) ? (
                <InfoBox sx={{ p: 1, mb: 1 }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      dispatch(
                        updateItemByCount({
                          item: cartItems[currentItemId],
                          deltaCount: 1,
                          customizable: customizable(
                            presaleItems[currentItemIndex]
                          ),
                          customization: {
                            price:
                              item.flavors.length > 0
                                ? item.price + currentFlavor.flavorPrice
                                : item.price,
                            flavor:
                              item.flavors.length > 0
                                ? currentFlavor.flavorName
                                : "",
                            color: item.colors.length > 0 ? currentColor : "",
                            personalization: item.personalizable
                              ? currentPersonalization
                              : "",
                            count: 1,
                          },
                          customizationToDeleteIndex: -1,
                        })
                      );
                      setAddName(false);
                      setCurrentPersonalization("");
                      setCurrentFlavor({
                        flavorPrice: 0,
                        flavorName: "",
                      });
                    }}
                  >
                    <Stack>
                      {item.personalizable &&
                      item.personalizationType ===
                        PERSONALIZATION_TYPE_CUSTOM_ONLY ? (
                        <TextField
                          autoFocus
                          fullWidth
                          required
                          size="small"
                          margin="dense"
                          placeholder="Free text"
                          value={currentPersonalization}
                          onChange={(e) => {
                            setCurrentPersonalization(e.target.value);
                          }}
                        />
                      ) : null}
                      {item.personalizable &&
                      item.personalizationType === PERSONALIZATION_TYPE_BOTH ? (
                        <Autocomplete
                          freeSolo
                          size="small"
                          options={item.personalizationOptions}
                          onChange={(event: any, newValue: string | null) => {
                            if (newValue) {
                              setCurrentPersonalization(newValue);
                            }
                          }}
                          inputValue={currentPersonalization}
                          onInputChange={(event, newInputValue) => {
                            setCurrentPersonalization(newInputValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              label="Text"
                              margin="dense"
                              placeholder="Choose or enter free text"
                              {...params}
                            />
                          )}
                        />
                      ) : null}
                      {item.personalizable &&
                      item.personalizationType ===
                        PERSONALIZATION_TYPE_PRESET_ONLY &&
                      item.personalizationOptions.length > 0 ? (
                        <FormControl fullWidth size="small" margin="dense">
                          <InputLabel>Text</InputLabel>
                          <Select
                            value={currentPersonalization}
                            defaultValue={item.personalizationOptions[0]}
                            label="Text"
                            onChange={(e) => {
                              setCurrentPersonalization(e.target.value);
                            }}
                          >
                            {item.personalizationOptions.map(
                              (option, index) => (
                                <MenuItem key={option + index} value={option}>
                                  {option}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      ) : null}
                      {item.flavors.length > 0 ? (
                        <FormControl fullWidth size="small" margin="dense">
                          <InputLabel>Flavor</InputLabel>
                          <Select
                            value={currentFlavor.flavorName}
                            label="Flavor"
                            onChange={(e) => {
                              const selectedFlavor = item.flavors.find(
                                (flavor) => flavor.flavorName === e.target.value
                              );
                              if (selectedFlavor) {
                                setCurrentFlavor(selectedFlavor);
                              }
                            }}
                            sx={{
                              "& .MuiOutlinedInput-input.MuiSelect-select": {
                                whiteSpace: "normal",
                              },
                            }}
                          >
                            {item.flavors.map((f) => (
                              <MenuItem
                                key={f.flavorName}
                                value={f.flavorName}
                                sx={{ whiteSpace: "normal" }}
                              >
                                {f.flavorName}{" "}
                                {f.flavorPrice !== 0 && `(+$${f.flavorPrice})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : null}
                      {item.colors.length > 0 ? (
                        <FormControl fullWidth size="small" margin="dense">
                          <InputLabel>Design</InputLabel>
                          <Select
                            value={currentColor}
                            label="Design"
                            onChange={(e) => {
                              setCurrentColor(e.target.value);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-input.MuiSelect-select": {
                                whiteSpace: "normal",
                              },
                            }}
                          >
                            {item.colors.map((color) => (
                              <MenuItem
                                key={color}
                                value={color}
                                sx={{ whiteSpace: "normal" }}
                              >
                                {color}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : null}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={1}
                        mt={1}
                      >
                        <Typography variant="subtitle2" flex={1}>
                          Total: {flavorToDisplay(item)}
                        </Typography>
                        <ToggleButton
                          size="small"
                          value="check"
                          onClick={() => {
                            setAddName(false);
                            setCurrentPersonalization("");
                            setCurrentFlavor({
                              flavorPrice: 0,
                              flavorName: "",
                            });
                          }}
                        >
                          <CloseRoundedIcon />
                        </ToggleButton>
                        <ToggleButton size="small" value="check" type="submit">
                          <CheckRoundedIcon />
                        </ToggleButton>
                      </Stack>
                    </Stack>
                  </form>
                </InfoBox>
              ) : null}

              {/* added customizations */}
              {customizable(item) &&
              cartItems[item.id]?.customization.length > 0 ? (
                <List
                  dense
                  disablePadding
                  sx={{
                    width: "100%",
                  }}
                >
                  {cartItems[item.id]?.customization.map((p, index) => (
                    <InfoBox sx={{ p: 0.5, pl: 1, mb: 1 }}>
                      <ListItem
                        key={index}
                        disableGutters
                        secondaryAction={
                          <IconButton
                            size="small"
                            aria-label="delete"
                            disabled={openAddName}
                            onClick={() => {
                              dispatch(
                                updateItemByCount({
                                  item: cartItems[item.id],
                                  deltaCount: -1,
                                  customizable: customizable(item),
                                  customization:
                                    cartItems[item.id]?.customization[index],
                                  customizationToDeleteIndex: index,
                                })
                              );
                            }}
                          >
                            <DeleteOutlineRoundedIcon />
                          </IconButton>
                        }
                        sx={{ borderWidth: 1 }}
                      >
                        <ListItemText primary={generateLabel(p)} />
                      </ListItem>
                    </InfoBox>
                  ))}
                </List>
              ) : null}
            </Stack>
          </Stack>
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};
