import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  defaultPresaleInfo,
  presaleInfoValidationSchema,
} from "Model/PresaleInfo";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  presalesLoadingSelector,
  presalesSelector,
  selectOrdersByFulfillments,
  selectUnpaidPresaleOrders,
  selectedPresaleSelector,
} from "Redux/selectors";
import { useFormik } from "formik";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { stringToFulfillment } from "Model/PresaleInfo";

// ui related
import {
  Box,
  CssBaseline,
  Drawer,
  Tab,
  Tabs,
  Stack,
  Typography,
  Button,
  Grid,
  Link,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  AlertTitle,
  Divider,
} from "@mui/material";
import {
  AppNav,
  CardStyle,
  ContentWrapNarrow,
  InfoBox,
} from "Components/AllComponents";
import { PresalesDrawer } from "./PresalesDrawer";
import { EditPresale } from "./Components/General/EditPresale";
import { PresaleOrdersByDate } from "./Components/Orders/PresaleOrdersByDate";
import { PRESALE_STATUS_NOTPUBLISHED, PRESALE_STATUS_ENDED } from "Constants";
import { useSession } from "Context/userAuthContext";
import { createNewPresaleAsync } from "Redux/Reducers/presales";

import { UnpaidOrders } from "./Components/Orders/UnpaidOrders";
import { PresalItemList } from "./Components/Items/PresalItemList";
import {
  aggregateOrders,
  calculateOrdersTotalPriceForPresale,
  calculateTotalItemsSoldForPresale,
  calculateTotalOrdersCountForPresale,
} from "Model/PresaleOrder";
import { PresaleHeader } from "./PresaleHeader";
import { LabeledText } from "Components/LabeledText";
import { RevenuePerItem } from "./Components/Orders/RevenuePerItem";
import { MoreMenu } from "./Components/Orders/MoreMenu";
import { PresaleOrderItems } from "./Components/Orders/PresaleOrderItems";

const drawerWidth = 260;

const Presales = () => {
  const { user, sellerProfile } = useSession();
  const navigate = useNavigate();
  const selectedPresale = useAppSelector(selectedPresaleSelector);
  const presales = useAppSelector(presalesSelector);
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // Use the selectOrdersByFulfillments selector to get orders grouped by pickup date
  const ordersByFulfillments = useAppSelector(selectOrdersByFulfillments);
  const ordersForPresale =
    ordersByFulfillments[selectedPresale.presaleId] || {};

  const unpaidOrders = useAppSelector(selectUnpaidPresaleOrders)[
    selectedPresale.presaleId
  ];
  const presalesLoading = useAppSelector(presalesLoadingSelector);
  const [sortItemsBy, setSortItemsBy] = useState("title");
  const aggregatedOrders = aggregateOrders(
    Object.values(ordersForPresale).reduce(
      (acc, orders) => [...acc, ...orders],
      []
    ),
    sortItemsBy
  );

  const [sortRevenueBy, setSortRevenueBy] = useState("revenue");
  const aggregatedRevenueOrders = aggregateOrders(
    Object.values(ordersForPresale).reduce(
      (acc, orders) => [...acc, ...orders],
      []
    ),
    sortRevenueBy
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...selectedPresale,
      paymentIds: sellerProfile.payments.map((p) => p.method),
    },
    validationSchema: presaleInfoValidationSchema,
    onSubmit: async (values) => {},
  });
  const [creatingNewPresale, setCreatingNewPresale] = useState(false);

  const [activeTab, setActiveTab] = useState(
    window.location.hash.replace("#", "")
  );

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (!["items", "orders", "general"].includes(hash)) {
      if (selectedPresale.status === PRESALE_STATUS_NOTPUBLISHED) {
        setActiveTab("items");
      } else {
        setActiveTab("orders");
      }
    } else {
      if (
        hash === "orders" &&
        selectedPresale.status === PRESALE_STATUS_NOTPUBLISHED
      ) {
        setActiveTab("items");
      } else {
        setActiveTab(hash);
      }
    }
  }, [selectedPresale]);

  const handleClickTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    navigate("#" + newValue);
  };

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openItems, setOpenItems] = useState(false);
  const handleOpenItems = () => {
    setOpenItems(true);
  };
  const handleCloseItems = () => {
    setOpenItems(false);
  };

  const [openRevenue, setOpenRevenue] = useState(false);
  const handleOpenRevenue = () => {
    setOpenRevenue(true);
  };
  const handleCloseRevenue = () => {
    setOpenRevenue(false);
  };

  const isPast = selectedPresale.fulfillments.every((d) =>
    moment(d.date).isBefore(moment(), "day")
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* ---------- app bar ---------- */}
      <AppNav
        required={presales.length !== 0}
        drawerName="All presales"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />
      {/* ---------- drawer ---------- */}
      {presales.length !== 0 ? (
        <Box
          component="nav"
          sx={{
            width: { md: drawerWidth },
            flexShrink: { md: 0 },
          }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                backgroundColor: "background.default",
              },
            }}
          >
            <Box
              sx={{
                overflow: "auto",
                p: { xs: 2, sm: 3 },
                mt: 6,
              }}
            >
              <PresalesDrawer />
            </Box>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                backgroundColor: "background.default",
              },
            }}
            open
          >
            <Box
              sx={{
                overflow: "auto",
                p: { xs: 2, sm: 3 },
                mt: 6,
              }}
            >
              <PresalesDrawer />
            </Box>
          </Drawer>
        </Box>
      ) : null}

      {/* ---------- presale content ---------- */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: {
            md: `calc(100% - ${(<PresalesDrawer />)}px)`,
          },
          minHeight: "100%",
          backgroundColor: "background.default",
        }}
      >
        {!presalesLoading && presales.length === 0 ? (
          <CardStyle>
            <Stack alignItems="center" spacing={2}>
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                Start creating your first presale!
              </Typography>
              <Button
                variant="contained"
                sx={{ mb: 2 }}
                disabled={creatingNewPresale}
                onClick={async () => {
                  if (user) {
                    setCreatingNewPresale(true);
                    await dispatch(
                      createNewPresaleAsync({
                        uid: user.uid,
                        presaleInfo: {
                          ...defaultPresaleInfo(
                            sellerProfile.defaultPickupAddress ? true : false,
                            sellerProfile.payments.map((p) => p.method)
                          ),
                          uid: user.uid,
                        },
                      })
                    );
                    setCreatingNewPresale(false);
                    navigate("/admin/presales");
                  }
                }}
              >
                New presale
              </Button>
            </Stack>
          </CardStyle>
        ) : (
          <ContentWrapNarrow>
            <Stack>
              <PresaleHeader editing={false} formik={formik} />
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tabs value={activeTab} onChange={handleClickTab}>
                  {selectedPresale.status !== PRESALE_STATUS_NOTPUBLISHED ? (
                    <Tab
                      label="Orders"
                      value="orders"
                      disableRipple
                      sx={{
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                    />
                  ) : null}

                  <Tab
                    label="Items"
                    value="items"
                    disableRipple
                    sx={{
                      textTransform: "none",
                      fontWeight: 600,
                    }}
                  />
                  <Tab
                    label="Settings"
                    value="general"
                    disableRipple
                    sx={{
                      textTransform: "none",
                      fontWeight: 600,
                    }}
                  />
                </Tabs>
              </Box>

              {activeTab === "general" ? (
                <EditPresale formik={formik} />
              ) : activeTab === "items" ? (
                <PresalItemList formik={formik} />
              ) : Object.keys(ordersForPresale).length === 0 ? (
                <Typography
                  color="text.secondary"
                  sx={{ textAlign: "center", mt: 4, pl: 3, pr: 3 }}
                >
                  Your orders will appear here, grouped by pickup dates, and
                  you'll receive an email once a new order is submitted.
                </Typography>
              ) : (
                <Stack spacing={2}>
                  <CardStyle>
                    <Stack spacing={2}>
                      <Typography flex={1} variant="h6" id="tableTitle">
                        Summary
                      </Typography>
                      <Stack>
                        <Grid container>
                          <Grid item xs={4}>
                            <LabeledText
                              label="Total orders"
                              text={calculateTotalOrdersCountForPresale(
                                ordersForPresale
                              ).toString()}
                              bold
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Stack>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Total items
                              </Typography>
                              <Typography variant="subtitle2">
                                {calculateTotalItemsSoldForPresale(
                                  ordersForPresale
                                )}{" "}
                                (
                                <Link
                                  sx={{ textDecoration: "none" }}
                                  onClick={handleOpenItems}
                                >
                                  details
                                </Link>
                                )
                              </Typography>
                            </Stack>
                          </Grid>

                          <Grid item xs={4}>
                            <Stack>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Total revenue
                              </Typography>
                              <Typography variant="subtitle2">
                                $
                                {calculateOrdersTotalPriceForPresale(
                                  ordersForPresale
                                )}{" "}
                                (
                                <Link
                                  sx={{ textDecoration: "none" }}
                                  onClick={handleOpenRevenue}
                                >
                                  details
                                </Link>
                                )
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>

                      {/* ------- items ------- */}
                      <Dialog
                        open={openItems}
                        onClose={handleCloseItems}
                        fullWidth
                        fullScreen={fullScreen}
                      >
                        <DialogTitle>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            All items
                            <Stack
                              flex={1}
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-end"
                              columnGap={1}
                            >
                              <Typography variant="body2">Sort by</Typography>
                              <ToggleButtonGroup
                                size="small"
                                color="primary"
                                value={sortItemsBy}
                                exclusive
                                onChange={(e, newSortItemsBy) => {
                                  if (newSortItemsBy) {
                                    setSortItemsBy(newSortItemsBy);
                                  }
                                }}
                              >
                                <ToggleButton value="title">Item</ToggleButton>
                                <ToggleButton value="flavor">
                                  Flavor
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Stack>
                            <MoreMenu ordersForPresale={ordersForPresale} />
                          </Stack>
                        </DialogTitle>
                        <DialogContent dividers>
                          <Stack spacing={2} divider={<Divider />}>
                            {aggregatedOrders.map((o, index) =>
                              o.count === 0 ? null : (
                                <PresaleOrderItems
                                  itemInfo={o}
                                  key={o.aggregatedOrderId}
                                />
                              )
                            )}
                          </Stack>
                        </DialogContent>
                        <DialogActions>
                          <Button autoFocus onClick={handleCloseItems}>
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* ------- revenue ------- */}
                      <Dialog
                        open={openRevenue}
                        onClose={handleCloseRevenue}
                        fullWidth
                        fullScreen={fullScreen}
                      >
                        <DialogTitle>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            Revenue
                            <Stack
                              flex={1}
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-end"
                              columnGap={1}
                            >
                              <Typography variant="body2">Sort by</Typography>
                              <ToggleButtonGroup
                                size="small"
                                color="primary"
                                value={sortRevenueBy}
                                exclusive
                                onChange={(e, newSortRevenueBy) => {
                                  if (newSortRevenueBy) {
                                    setSortRevenueBy(newSortRevenueBy);
                                    console.log("sort ", newSortRevenueBy);
                                  }
                                }}
                              >
                                <ToggleButton value="revenue">
                                  Revenue
                                </ToggleButton>
                                <ToggleButton value="sold">Sold</ToggleButton>
                              </ToggleButtonGroup>
                            </Stack>
                          </Stack>
                        </DialogTitle>
                        <DialogContent dividers>
                          <Stack spacing={2}>
                            <Stack spacing={2} divider={<Divider />}>
                              {aggregatedRevenueOrders.map((o, index) =>
                                o.count === 0 ? null : (
                                  <RevenuePerItem
                                    itemInfo={o}
                                    key={o.aggregatedOrderId}
                                  />
                                )
                              )}
                            </Stack>
                            <Divider />
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                              columnGap={1}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: 600 }}
                              >
                                Total
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: 600 }}
                              >
                                $
                                {calculateOrdersTotalPriceForPresale(
                                  ordersForPresale
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </DialogContent>
                        <DialogActions>
                          <Button autoFocus onClick={handleCloseRevenue}>
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {unpaidOrders.length === 0 ? null : (
                        <InfoBox>
                          <UnpaidOrders unpaidOrders={unpaidOrders} />
                        </InfoBox>
                      )}

                      {isPast ||
                      selectedPresale.status !== PRESALE_STATUS_ENDED ? null : (
                        <Alert icon={false} severity="info">
                          <AlertTitle>Remind pickups</AlertTitle>
                          Remember to send your customers an email regarding the
                          pickup instructions before the pickup dates.
                        </Alert>
                      )}
                    </Stack>
                  </CardStyle>

                  {Object.entries(ordersForPresale).map(
                    ([fulfillment, orders]) => (
                      <PresaleOrdersByDate
                        defaultPickupAddress={
                          sellerProfile.defaultPickupAddress
                        }
                        key={fulfillment}
                        fulfillmentStr={fulfillment}
                        presaleOrders={orders}
                        presaleId={selectedPresale.presaleId}
                      />
                    )
                  )}
                </Stack>
              )}
            </Stack>
          </ContentWrapNarrow>
        )}
      </Box>
    </Box>
  );
};

export default Presales;
