import { Link, Typography, Box } from "@mui/material";

export const Footer = () => {
  return (
    <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
      <Typography variant="caption" color="text.secondary">
        Copyright © 2024 Flowly LLC<span> | </span>
        <Link
          id="contact"
          href="https://www.instagram.com/flowly.cookies/"
          target="_blank"
        >
          Contact us
        </Link>
        <span> | </span>
        <Link id="tos" href="/terms-of-service" target="_blank">
          Terms
        </Link>
        {" & "}
        <Link id="privacy-policy" href="/privacy-policy" target="_blank">
          Privacy
        </Link>
      </Typography>
    </Box>
  );
};
