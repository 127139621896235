import React, { useState, useEffect } from "react";
import { defaultOrderInfo, OrderInfo } from "Model/OrderInfo";
import { Droppable } from "react-beautiful-dnd";
import { OrderItem } from "./OrderItem";
import { useSession } from "Context/userAuthContext";
import _ from "lodash";
// ui related
import {
  Box,
  Button,
  Typography,
  Divider,
  Stack,
  Chip,
  IconButton,
} from "@mui/material";
import { Unit } from "Model/CustomerRequestInfo";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { ordersSelector } from "Redux/selectors";
import { sortOrdersStatus } from "Redux/actionCreators";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

type OrderColumnProps = {
  id: string;
  title: string;
  showCount: boolean;
  orderIds: string[];
  collapsible: boolean;
};

export const OrderColumn = ({
  id,
  title,
  showCount,
  orderIds,
  collapsible,
}: OrderColumnProps) => {
  const dispatch = useAppDispatch();
  const allOrders = useAppSelector(ordersSelector);
  const allOrdersMap = allOrders.reduce((acc, order) => {
    acc.set(order.orderId, order);
    return acc;
  }, new Map<string, OrderInfo>());
  const orders = orderIds.map(
    (orderId) => allOrdersMap.get(orderId) || defaultOrderInfo
  );
  const columnTotal = orders.reduce(
    (acc, curr) =>
      acc + (curr.unit === Unit.Dozen ? curr.count * 12 : curr.count),
    0
  );

  const [showOrders, setShowOrders] = useState(true);

  return (
    <Stack sx={{ flex: 1 }} spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Stack direction="row" flex={1} spacing={1} alignItems="center">
          {collapsible && (
            <IconButton size="small" onClick={() => setShowOrders(!showOrders)}>
              {showOrders ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <ChevronRightRoundedIcon />
              )}
            </IconButton>
          )}
          <Typography
            variant="subtitle2"
            sx={{
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
          {showCount ? (
            <Chip
              label={
                columnTotal % 12 === 0
                  ? Math.floor(columnTotal / 12) + " dozen"
                  : Math.floor(columnTotal / 12) +
                    " dozen " +
                    Math.floor(columnTotal % 12) +
                    " cookies"
              }
              size="small"
              variant="outlined"
            />
          ) : null}
        </Stack>

        <Button
          size="small"
          variant="text"
          sx={{
            color: "text.secondary",
          }}
          onClick={() => {
            const sortedOrdersIds = _.orderBy(
              orders,
              ["customerDate"],
              ["asc"]
            ).map((order) => order.orderId);
            dispatch(sortOrdersStatus({ orders: sortedOrdersIds, key: id }));
          }}
        >
          Sort by date
        </Button>
      </Stack>

      {showOrders && (
        <Droppable droppableId={id}>
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              spacing={2}
              flex={1}
            >
              {orders.map((order, index) => (
                <OrderItem order={order} index={index} key={index} />
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      )}
    </Stack>
  );
};
