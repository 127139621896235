import { UserAuthProvider } from "Context/userAuthContext";
import { useSession } from "Context/userAuthContext";
import {
  Routes,
  BrowserRouter,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "Components/Styles/Styles";
import { Provider } from "react-redux";
import store from "Redux/store";
import ScrollToTop from "Components/ScrollToTop";

// pages
import Landing from "./Pages/Landing";
import Onboarding from "./Pages/BusinessView/Onboarding/Onboarding";

import Hub from "./Pages/BusinessView/Hub/Hub";
import EditForm from "./Pages/BusinessView/EditForm/EditForm";
import Confirmation from "./Pages/CustomerView/OrderForm/Confirmation";
import {
  PresaleConfirmation,
  PresaleCreditCardConfirmation,
} from "Pages/CustomerView/CustomerPresale/PresaleConfirmation";
import AllRequests from "./Pages/BusinessView/AllRequests/AllRequests";
import AllOrders from "./Pages/BusinessView/AllOrders/AllOrders";
import CompletedOrders from "./Pages/BusinessView/AllOrders/CompletedOrders/CompletedOrders";
import Presales from "./Pages/BusinessView/Presales/Presales";
import SignIn from "./Pages/BusinessView/SignIn";
import Projector from "./Pages/BusinessView/Presales/Projector";

import DisplayForm from "./Pages/CustomerView/OrderForm/DisplayForm";
import HomePage from "./Pages/CustomerView/HomePage";
import CustomerPresale from "./Pages/CustomerView/CustomerPresale/CustomerPresale";

import PresalePreview from "Pages/BusinessView/Presales/PresalePreview";
import { NotFound } from "Components/NotFound";

import Discover from "Pages/Discover/Discover";

import TOS from "./Pages/Legal/TOS";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import CookiePolicy from "./Pages/Legal/CookiePolicy";
import { UnsubscribeEmail } from "Components/CustomerView/UnsubscribeEmail";
import { RQODetails } from "Pages/CustomerView/RequestTracking/RQODetails";

function RequireAuth({ children }: { children: JSX.Element }) {
  let { user } = useSession();
  let location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  // const { user } = useSession();

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <ScrollToTop />
        <Provider store={store}>
          <UserAuthProvider>
            <Routes>
              <Route
                path="/admin/welcome"
                element={
                  <RequireAuth>
                    <Onboarding />
                  </RequireAuth>
                }
              />

              {/* business view: home */}
              <Route
                path="/admin"
                element={<Navigate to="/admin/home" replace />}
              />
              <Route
                path="/admin/home"
                element={
                  <RequireAuth>
                    <Hub />
                  </RequireAuth>
                }
              />

              <Route
                path="/admin/calendar"
                element={
                  <RequireAuth>
                    <Hub />
                  </RequireAuth>
                }
              />

              <Route
                path="/admin/customers"
                element={
                  <RequireAuth>
                    <Hub />
                  </RequireAuth>
                }
              />

              <Route
                path="/admin/form"
                element={
                  <RequireAuth>
                    <EditForm />
                  </RequireAuth>
                }
              />

              {/* business view: requests */}
              <Route
                path={`/admin/requests/:requestId`}
                element={
                  <RequireAuth>
                    <AllRequests />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/requests"
                element={
                  <RequireAuth>
                    <AllRequests />
                  </RequireAuth>
                }
              />

              {/* business view: orders */}
              <Route
                path={`/admin/orders/:orderId`}
                element={
                  <RequireAuth>
                    <AllOrders />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/orders"
                element={
                  <RequireAuth>
                    <AllOrders />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/completed-orders"
                element={
                  <RequireAuth>
                    <CompletedOrders />
                  </RequireAuth>
                }
              />
              <Route
                path={`/admin/completed-orders/:orderId`}
                element={
                  <RequireAuth>
                    <CompletedOrders />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/canceled-orders"
                element={
                  <RequireAuth>
                    <CompletedOrders isCancel={true} />
                  </RequireAuth>
                }
              />
              <Route
                path={`/admin/canceled-orders/:orderId`}
                element={
                  <RequireAuth>
                    <CompletedOrders isCancel={true} />
                  </RequireAuth>
                }
              />

              {/* business view: presales */}
              <Route
                path="/admin/presales"
                element={
                  <RequireAuth>
                    <Presales />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/presales/:presaleId"
                element={
                  <RequireAuth>
                    <Presales />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/presalepreview/:presaleId"
                element={
                  <RequireAuth>
                    <PresalePreview />
                  </RequireAuth>
                }
              />
              <Route
                path="/admin/project"
                element={
                  <RequireAuth>
                    <Projector />
                  </RequireAuth>
                }
              />

              {/* business view: sign in */}
              <Route path="/sign-in" element={<SignIn />} />

              {/* customer view */}
              <Route path={`/:userId`} element={<HomePage />} />
              <Route path={`/:userId/form/:formId`} element={<DisplayForm />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route
                path="/:userId/presale/:presaleId"
                element={<CustomerPresale />}
              />
              <Route
                path="/presale-confirmation"
                element={<PresaleConfirmation />}
              />
              <Route
                path="/presale-cc-confirmation"
                element={<PresaleCreditCardConfirmation />}
              />
              <Route path="/quote-status" element={<RQODetails />} />

              {/* general public view */}
              <Route path="/" element={<Landing />} />
              <Route path="/discover" element={<Discover />} />
              <Route path="/discover/:topic" element={<Discover />} />

              {/* legal */}
              <Route path="/terms-of-service" element={<TOS />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />

              <Route path="/unsubscribe" element={<UnsubscribeEmail />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </UserAuthProvider>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

//https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
