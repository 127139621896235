import { FormikProps } from "formik";

// ui related
import { TextField } from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerQuestions = ({
  customerFormik,
}: {
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  return (
    <FormSection id="questions" sectionName="Questions/comments">
      <TextField
        fullWidth
        multiline
        id="questions"
        onChange={customerFormik.handleChange}
      />
    </FormSection>
  );
};
