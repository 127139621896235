import { useSession } from "Context/userAuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PresaleInfo } from "Model/PresaleInfo";
import { selectedPresaleSelector } from "Redux/selectors";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Stack,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
} from "@mui/material";
import { CardStyle } from "Components/AllComponents";
import { ShareToInspo } from "./ShareToInspo";
import {
  PRESALE_STATUS_ENDED,
  PRESALE_STATUS_NOTPUBLISHED,
  PRESALE_STATUS_PUBLISHED,
} from "Constants";
import { defaultPresaleItem } from "Model/PresaleItem";
import {
  addPresaleItemAsync,
  updatePresaleAsync,
} from "Redux/Reducers/presales";
import { nanoid } from "nanoid";
import { PresaleItemView } from "./PresaleItemView";
import { EndedPresaleItem } from "./EndedPresaleItem";
import { FormikProps } from "formik";
import {
  Draggable,
  DragDropContext,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { reorder } from "Util/helpers";
import { editPresale } from "Redux/actionCreators";

export const PresalItemList = ({
  formik,
}: {
  formik: FormikProps<PresaleInfo>;
}) => {
  const selectedPresale = useAppSelector(selectedPresaleSelector);
  const dispatch = useAppDispatch();

  const [addingNewPresaleItem, setAddingNewPresaleItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [localPresaleItems, setLocalPresaleItems] = useState(
    selectedPresale.presaleItems || []
  );
  useEffect(() => {
    setLocalPresaleItems(selectedPresale.presaleItems || []);
  }, [selectedPresale.presaleItems]);

  const [anchorMoreMenu, setAnchorMoreMenu] = useState<null | HTMLElement>(
    null
  );
  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMoreMenu(event.currentTarget);
  };
  const handleCloseMoreMenu = () => {
    setAnchorMoreMenu(null);
  };

  const [openShareInspo, setOpenShareInspo] = useState(false);
  const handleClickOpenShareInspo = () => {
    setOpenShareInspo(true);
  };
  const handleCloseShareInspo = () => {
    setOpenShareInspo(false);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) {
      return;
    }
    const newItems = reorder(
      localPresaleItems,
      source.index,
      destination.index
    );
    setLocalPresaleItems(newItems);
    setSelectedItem(destination.index);
    dispatch(editPresale({ ...selectedPresale, presaleItems: newItems }));
    dispatch(
      updatePresaleAsync({ ...selectedPresale, presaleItems: newItems })
    );
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <CardStyle>
          {localPresaleItems?.length === 0 ? (
            <Stack>
              <Button
                disabled={addingNewPresaleItem}
                variant="contained"
                startIcon={<AddRoundedIcon />}
                onClick={async () => {
                  if (formik.dirty) {
                    await dispatch(updatePresaleAsync(formik.values));
                  }
                  setAddingNewPresaleItem(true);
                  await dispatch(
                    addPresaleItemAsync({
                      presaleId: selectedPresale.presaleId,
                      presaleItem: { ...defaultPresaleItem, id: nanoid() },
                    })
                  );
                  setAddingNewPresaleItem(false);
                  setSelectedItem(0);
                }}
              >
                Add item
              </Button>
            </Stack>
          ) : (
            <Stack spacing={1}>
              <Stack flexDirection="row" alignItems="center" columnGap={1}>
                <Typography flex={1} variant="h6">
                  {localPresaleItems?.length}{" "}
                  {localPresaleItems?.length === 1 ? "item" : "items"}
                </Typography>
                {formik.values.status === PRESALE_STATUS_ENDED ? null : (
                  <Button
                    disabled={addingNewPresaleItem}
                    variant="outlined"
                    startIcon={<AddRoundedIcon />}
                    onClick={async () => {
                      if (formik.dirty) {
                        await dispatch(updatePresaleAsync(formik.values));
                      }
                      setAddingNewPresaleItem(true);
                      await dispatch(
                        addPresaleItemAsync({
                          presaleId: selectedPresale.presaleId,
                          presaleItem: { ...defaultPresaleItem, id: nanoid() },
                        })
                      );
                      setAddingNewPresaleItem(false);
                      setSelectedItem(localPresaleItems?.length);
                    }}
                  >
                    Add item
                  </Button>
                )}
                {/* {formik.values.status === PRESALE_STATUS_NOTPUBLISHED ? null : (
                  <>
                    <Button
                      disabled={addingNewPresaleItem}
                      variant="contained"
                      onClick={handleClickOpenShareInspo}
                    >
                      Share to inspire
                    </Button>
                    <ShareToInspo
                      open={openShareInspo}
                      handleClose={handleCloseShareInspo}
                    />
                  </>
                )} */}
              </Stack>
              <DragDropContext
                onDragEnd={onDragEnd}
                onDragStart={(start) => {
                  setSelectedItem(start.source.index);
                }}
              >
                <Droppable droppableId="droppable-list">
                  {(provided) => (
                    <List
                      dense
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {localPresaleItems?.map((presaleItem, index) => (
                        <Draggable
                          draggableId={presaleItem?.id}
                          index={index}
                          key={presaleItem?.id}
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              disablePadding
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  onClick={handleOpenMoreMenu}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              }
                            >
                              <ListItemButton
                                dense
                                selected={index === selectedItem}
                                onClick={() => {
                                  setSelectedItem(index);
                                }}
                                sx={{
                                  outline:
                                    index === selectedItem
                                      ? "1.5px solid"
                                      : "none",
                                  p: 0,
                                  mt: 0.25,
                                  mb: 0.25,
                                }}
                              >
                                {localPresaleItems?.length > 1 ? (
                                  <ListItemIcon>
                                    <DragIndicatorIcon />
                                  </ListItemIcon>
                                ) : null}
                                <Box
                                  sx={{
                                    width: "48px",
                                    height: "48px",
                                    borderRadius: 1,
                                    backgroundColor: "background.default",
                                    mr: 2,
                                  }}
                                >
                                  {presaleItem.img ? (
                                    <img
                                      src={presaleItem.img}
                                      style={{
                                        width: "48px",
                                        height: "48px",
                                        display: "block",
                                        borderRadius: "4px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : null}
                                </Box>
                                <ListItemText
                                  primary={presaleItem.title}
                                  secondary={
                                    "$" +
                                    presaleItem.price +
                                    (presaleItem.soldOut ? " (sold out)" : "")
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
          )}
        </CardStyle>

        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorMoreMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorMoreMenu)}
          onClose={handleCloseMoreMenu}
        >
          <MenuItem
            onClick={async () => {
              if (formik.dirty) {
                await dispatch(updatePresaleAsync(formik.values));
              }
              setAddingNewPresaleItem(true);
              await dispatch(
                addPresaleItemAsync({
                  presaleId: selectedPresale.presaleId,
                  presaleItem: {
                    ...localPresaleItems[selectedItem],
                    id: nanoid(),
                  },
                })
              );
              setAddingNewPresaleItem(false);
              setSelectedItem(localPresaleItems?.length);
              handleCloseMoreMenu();
            }}
          >
            <Typography textAlign="center">Duplicate item</Typography>
          </MenuItem>
        </Menu>

        {localPresaleItems?.map((presaleItem, index) =>
          index === selectedItem ? (
            formik.values.status === PRESALE_STATUS_ENDED ? (
              <EndedPresaleItem
                key={presaleItem.id}
                presaleId={selectedPresale.presaleId}
                presaleItem={presaleItem}
                index={index}
                formik={formik}
              />
            ) : (
              <PresaleItemView
                key={presaleItem.id}
                presaleId={selectedPresale.presaleId}
                presaleItem={presaleItem}
                index={index}
                formik={formik}
              />
            )
          ) : null
        )}
      </Stack>
    </Stack>
  );
};
