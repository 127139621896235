import { useEffect, useState } from "react";
import {
  joinEmailList,
  submitCustomerRequestInfoTransaction,
} from "Util/firebaseHelper";
import { useNavigate } from "react-router-dom";
import { BusinessInfo } from "Model/BusinessInfo";
import {
  customerRequestInfoValidationSchema,
  DefaultCustomerRequestInfo,
} from "Model/CustomerRequestInfo";
import { Availability, AvailabilityType } from "Model/Availability";
import { toast } from "react-toastify";
import _ from "lodash";
import { useFormik } from "formik";
import { getMinMaxDueDate, getNewDateWithoutHours } from "Util/dateUtil";

// ui related
import { Button, Typography, Grid, Stack, Box } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import Avatar from "boring-avatars";
import { CardStyle, InfoBox } from "Components/AllComponents";
import { DEFAULT_BUCKET_LIST } from "Constants";

// form sections
import { CustomerQuantity } from "./FormComponents/CustomerQuantity";
import { CustomerDateNeeded } from "./FormComponents/CustomerDateNeeded";
import { CustomerContactInfo } from "./FormComponents/CustomerContactInfo";
import { CustomerFulfillment } from "./FormComponents/CustomerFulfillment";
import { CustomerShipping } from "./FormComponents/CustomerShipping";
import { CustomerBucketLists } from "./FormComponents/CustomerBucketLists";
import { CustomerOrderDetail } from "./FormComponents/CustomerOrderDetail";
import { CustomerDesignInspirations } from "./FormComponents/CustomerDesignInspirations";
import { CustomerFlavor } from "./FormComponents/CustomerFlavor";
import { CustomerPackaging } from "./FormComponents/CustomerPackaging";
import { CustomerPayment } from "./FormComponents/CustomerPayment";
import { CustomerAgreements } from "./FormComponents/CustomerAgreements";
import { CustomerQuestions } from "./FormComponents/CustomerQuestions";
import { SubAppBar } from "Components/CustomerView/SubAppBar";
import { Profile, defaultProfile } from "Model/Profile";
import { CustomerJoinEmail } from "./FormComponents/CustomerJoinEmail";

type OrderProps = {
  sample: boolean;
  paid: boolean;
  form: BusinessInfo;
  showCta: boolean;
  availability: Availability;
  uid: string;
  disableCustomerEmailList: boolean;
};

export const CustomerQuoteRequestForm = ({
  sample,
  paid,
  form,
  showCta,
  availability,
  uid,
  disableCustomerEmailList,
}: OrderProps) => {
  const navigate = useNavigate();
  const [businessInfo, setBusinessInfo] = useState<BusinessInfo>(form);

  const minMaxDue = getMinMaxDueDate(availability);

  const customerFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      ...DefaultCustomerRequestInfo,
      customerDate:
        minMaxDue.start === undefined
          ? getNewDateWithoutHours()
          : minMaxDue.start.toISOString(),
      count: businessInfo.minOrder,
      bucketLists: DEFAULT_BUCKET_LIST,
      flavors:
        businessInfo.showFlavors &&
        businessInfo.flavors &&
        businessInfo.flavors.length > 0
          ? [businessInfo.flavors[0]]
          : ([] as string[]),
      packagings:
        businessInfo.showPackagings &&
        businessInfo.packagings &&
        businessInfo.packagings.length > 0
          ? [businessInfo.packagings[0]]
          : ([] as string[]),
      paymentTypes:
        businessInfo.showPaymentTypes &&
        businessInfo.paymentTypes &&
        businessInfo.paymentTypes.length > 0
          ? [businessInfo.paymentTypes[0]]
          : ([] as string[]),
      joinEmailList: !disableCustomerEmailList,
    },
    validationSchema: customerRequestInfoValidationSchema(
      businessInfo.minOrder
    ),
    onSubmit: async (values) => {
      //alert(JSON.stringify(values, null, 2));
      try {
        submitCustomerRequestInfoTransaction(values, businessInfo);
        if (customerFormik.values.joinEmailList) {
          await joinEmailList(customerFormik.values.email, uid);
        }
        navigate("/confirmation", {
          state: {
            businessName: businessInfo.businessName,
            email: values.email,
          },
        });
      } catch (e) {
        toast.error(`Failed to submit your request. Please try again. ${e}`, {
          position: "top-center",
        });
      }
    },
  });

  useEffect(() => {
    document.title = form.businessName;
    setBusinessInfo(form);
  }, [form]);

  return (
    <form onSubmit={customerFormik.handleSubmit}>
      {/* ------------- form header ------------- */}

      {sample ? null : (
        <SubAppBar
          sellerName={businessInfo.sellerName}
          sellerPhoto={businessInfo.sellerPhoto}
          username={businessInfo.username}
          businessName={businessInfo.businessName}
        />
      )}

      <Box sx={{ pt: { xs: 6, sm: 7 } }}>
        <CardStyle>
          <Stack>
            <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
              Order Request Form
            </Typography>
            {businessInfo.formIntro && (
              <Typography
                variant="body1"
                color="text.primary"
                textAlign="center"
                sx={{ whiteSpace: "pre-line", mb: 2, mt: -1 }}
              >
                {businessInfo.formIntro}
              </Typography>
            )}
            <InfoBox sx={{ textAlign: "center" }}>
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Starting price</Typography>
                  <Typography variant="body1" color="text.secondary">
                    {businessInfo.price
                      ? "$" + businessInfo.price
                      : "[Your price]"}
                    /dozen
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Minimum order</Typography>
                  <Typography variant="body1" color="text.secondary">
                    {businessInfo.minOrder} dozen
                  </Typography>
                </Grid>
              </Grid>
            </InfoBox>
          </Stack>
        </CardStyle>

        <CustomerDateNeeded
          availability={availability}
          customerFormik={customerFormik}
        />

        <CustomerQuantity
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerContactInfo customerFormik={customerFormik} />

        <CustomerFulfillment
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerShipping
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerBucketLists
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerOrderDetail
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerDesignInspirations
          businessInfo={businessInfo}
          customerFormik={customerFormik}
          sample={sample}
        />

        <CustomerFlavor
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerPackaging
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerAgreements
          businessInfo={businessInfo}
          customerFormik={customerFormik}
        />

        <CustomerQuestions customerFormik={customerFormik} />
        {disableCustomerEmailList ? null : (
          <CustomerJoinEmail
            businessInfo={businessInfo}
            customerFormik={customerFormik}
          />
        )}

        {showCta ? (
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              disabled={
                minMaxDue.start === undefined ||
                availability.type === AvailabilityType.doNotAcceptOrder ||
                customerFormik.isSubmitting
              }
            >
              Submit request
            </Button>
          </Box>
        ) : null}
      </Box>
    </form>
  );
};
