import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ORDERS_COLLECTION_NAME,
  ORDERS_TABLE_NAME,
  PRESALE_STATUS_ENDED,
  PRESALE_STATUS_PUBLISHED,
  USERNAMES_TABLE_NAME,
} from "Constants";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { PresaleInfo } from "Model/PresaleInfo";
import {
  createPresale,
  db,
  deletePresale,
  fetchPresales,
  updatePresale,
} from "Util/firebaseHelper";
import { sellerProfileSlice, updateProfileAsync } from "./sellerProfile";
import { RootState } from "Redux/store";
import _ from "lodash";
import { defaultPresaleItem, PresaleItem } from "Model/PresaleItem";
import { nanoid } from "nanoid";
import { fetchPresaleOrders, presaleOrdersSlice } from "./presaleOrders";

const initialState = {
  data: [] as PresaleInfo[],
  loading: false,
};

export const presalesSlice = createSlice({
  name: "presales",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    createPresale: (state, action: PayloadAction<PresaleInfo>) => {
      state.data.unshift(action.payload);
    },
    editPresale: (state, action: PayloadAction<PresaleInfo>) => {
      const presaleToEditIndex = state.data.findIndex(
        (presale) => presale.presaleId === action.payload.presaleId
      );
      if (presaleToEditIndex !== -1) {
        state.data[presaleToEditIndex] = action.payload;
      }
    },
    deletePresale: (state, action: PayloadAction<string>) => {
      const presaleToDeleteIndex = state.data.findIndex(
        (presale) => presale.presaleId === action.payload
      );
      if (presaleToDeleteIndex !== -1) {
        state.data.splice(presaleToDeleteIndex, 1);
      }
    },
    updatePresales: (state, action: PayloadAction<PresaleInfo[]>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPresalesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPresalesAsync.fulfilled, (state, action) => {
        return { ...state, loading: false };
      })
      .addCase(fetchPresalesAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const fetchPresalesAsync = createAsyncThunk(
  "presales/fetchPresalesAsync",
  async (presaleList: string[], thunkAPI) => {
    const presales = await fetchPresales(presaleList);
    if (presales) {
      thunkAPI.dispatch(presalesSlice.actions.updatePresales(presales));
      thunkAPI.dispatch(fetchPresaleOrders(presales.map((p) => p.presaleId)));
    }
  }
);

export const updatePresaleAsync = createAsyncThunk(
  "presales/updatePresaleAsync",
  async (presaleInfo: PresaleInfo, thunkAPI) => {
    await updatePresale(presaleInfo);
    thunkAPI.dispatch(presalesSlice.actions.editPresale(presaleInfo));
  }
);

export const updatePresaleStatusAsync = createAsyncThunk(
  "presales/updatePresaleStatusAsync",
  async (
    { presaleInfo, uid }: { presaleInfo: PresaleInfo; uid: string },
    thunkAPI
  ) => {
    await updatePresale(presaleInfo);
    thunkAPI.dispatch(presalesSlice.actions.editPresale(presaleInfo));
    // Update profile as well for published presale and ended presale
    const state = thunkAPI.getState() as RootState;
    const sellerProfile = state.sellerProfile.sellerProfile;
    const livePresales = _.cloneDeep(sellerProfile.livePresales);
    if (presaleInfo.status === PRESALE_STATUS_PUBLISHED) {
      livePresales.unshift(presaleInfo.presaleId);
    } else if (presaleInfo.status === PRESALE_STATUS_ENDED) {
      const presaleToDeleteIndex = livePresales.findIndex(
        (id) => id === presaleInfo.presaleId
      );
      if (presaleToDeleteIndex !== -1) {
        livePresales.splice(presaleToDeleteIndex, 1);
      }
    }
    thunkAPI.dispatch(
      updateProfileAsync({
        uid,
        sellerProfile: { ...sellerProfile, livePresales },
      })
    );
  }
);

export const createNewPresaleAsync = createAsyncThunk(
  "presales/createPresaleAsync",
  async (
    { uid, presaleInfo }: { uid: string; presaleInfo: PresaleInfo },
    thunkAPI
  ) => {
    const presaleId = await createPresale({
      ...presaleInfo,
      presaleItems: [{ ...defaultPresaleItem, id: nanoid() }],
    });
    thunkAPI.dispatch(
      presalesSlice.actions.createPresale({ ...presaleInfo, presaleId })
    );
    // Update profile as well for new presale
    const state = thunkAPI.getState() as RootState;
    const sellerProfile = state.sellerProfile.sellerProfile;
    const presales = _.cloneDeep(sellerProfile.presales);
    presales.unshift(presaleId);
    thunkAPI.dispatch(
      updateProfileAsync({
        uid: uid,
        sellerProfile: { ...sellerProfile, presales },
      })
    );
  }
);

export const deletePresaleAsync = createAsyncThunk(
  "presales/deletePresaleAsync",
  async ({ uid, presaleId }: { uid: string; presaleId: string }, thunkAPI) => {
    await deletePresale(presaleId);
    thunkAPI.dispatch(presalesSlice.actions.deletePresale(presaleId));
    // Update profile as well for new presale
    const state = thunkAPI.getState() as RootState;
    const sellerProfile = state.sellerProfile.sellerProfile;
    const presales = _.cloneDeep(sellerProfile.presales);
    const livePresales = _.cloneDeep(sellerProfile.livePresales);
    thunkAPI.dispatch(
      updateProfileAsync({
        uid: uid,
        sellerProfile: {
          ...sellerProfile,
          presales: presales.filter((presale) => presale !== presaleId),
          livePresales: livePresales.filter((presale) => presale !== presaleId),
        },
      })
    );
  }
);

export const addPresaleItemAsync = createAsyncThunk(
  "presales/addPresaleItemAsync",
  async (
    { presaleId, presaleItem }: { presaleId: string; presaleItem: PresaleItem },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const presale = state.presales.data.find((p) => p.presaleId === presaleId);
    if (!presale) {
      throw new Error(`Presale with id ${presaleId} not found.`);
    }
    const updatedPresale = {
      ...presale,
      presaleItems: [...(presale.presaleItems || []), presaleItem],
    };
    await updatePresale(updatedPresale);
    thunkAPI.dispatch(presalesSlice.actions.editPresale(updatedPresale));
  }
);

export const updatePresaleItemAsync = createAsyncThunk(
  "presales/updatePresaleItemAsync",
  async (
    { presaleId, presaleItem }: { presaleId: string; presaleItem: PresaleItem },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const presale = state.presales.data.find((p) => p.presaleId === presaleId);
    if (!presale) {
      throw new Error(`Presale with id ${presaleId} not found.`);
    }
    const itemIndex = presale.presaleItems.findIndex(
      (item) => item.id === presaleItem.id
    );
    if (itemIndex === -1) {
      throw new Error(
        `PresaleItem with id ${presaleItem.id} not found in PresaleInfo with presaleId ${presaleId}.`
      );
    }
    const updatedPresaleItems = [...presale.presaleItems];
    updatedPresaleItems[itemIndex] = presaleItem;
    const updatedPresale = {
      ...presale,
      presaleItems: updatedPresaleItems,
    };

    await updatePresale(updatedPresale);
    thunkAPI.dispatch(presalesSlice.actions.editPresale(updatedPresale));
  }
);

export const deletePresaleItemAsync = createAsyncThunk(
  "presales/deletePresaleItemAsync",
  async (
    { presaleId, presaleItemId }: { presaleId: string; presaleItemId: string },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const presale = state.presales.data.find((p) => p.presaleId === presaleId);
    if (!presale) {
      throw new Error(`Presale with id ${presaleId} not found.`);
    }
    const updatedPresaleItems = presale.presaleItems.filter(
      (item) => item.id !== presaleItemId
    );
    if (updatedPresaleItems.length === presale.presaleItems.length) {
      throw new Error(
        `PresaleItem with id ${presaleItemId} not found in PresaleInfo with presaleId ${presaleId}.`
      );
    }
    const updatedPresale: PresaleInfo = {
      ...presale,
      presaleItems: updatedPresaleItems,
    };

    await updatePresale(updatedPresale);
    thunkAPI.dispatch(presalesSlice.actions.editPresale(updatedPresale));
  }
);
