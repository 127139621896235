import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSession } from "Context/userAuthContext";
import { SITE_URL } from "Constants";
import _ from "lodash";

// ui related
import {
  Button,
  Box,
  Typography,
  CssBaseline,
  Toolbar,
  Link,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Footer,
  AppNav,
  ProfileSettings,
  ContentWrapNarrow,
  CardStyle,
} from "Components/AllComponents";
import { HomeContent } from "./Home/HomeContent";
import { toast } from "react-toastify";

// icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { HubDrawer } from "./HubDrawer";
import { AccountSettings } from "./Account/AccountSettings";
import Onboarding from "../Onboarding/Onboarding";
import Calendar from "../AllOrders/Calendar/Calendar";
import CustomerList from "./Home/Components/CustomerList";

const drawerWidth = 260;
interface Props {
  window?: () => Window;
}

const Hub = (props: Props) => {
  const { user, sellerProfile, allRequests } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("linkSquare") === "failed") {
      toast.error(
        "Linking your Square account was unsuccessful. Please try again.",
        {
          autoClose: 4000,
          onClose: () => navigate("/admin/home"),
        }
      );
    } else if (params.get("linkSquare") === "success") {
      const merchantName = params.get("merchantName");
      openSquareAuthSuccModal();
      // toast.success(`Linked to Square account ${merchantName}`, {
      //   autoClose: 5000, // Stay open for 4 seconds
      //   onClose: () => navigate("/admin/home"),
      // });
    }
  }, [location]);

  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [currentPage, setCurrentPage] = useState(currentPath);

  useEffect(() => {
    setCurrentPage(currentPath);
  }, [currentPath]);

  // Business profile modal
  const [openBusinessProfile, setOpenBusinessProfile] = useState(false);
  const openBusinessProfileModal = () => {
    setOpenBusinessProfile(true);
  };
  const closeBusinessProfileModal = () => {
    setOpenBusinessProfile(false);
  };

  // Account modal
  const [openAccount, setOpenAccount] = useState(false);
  const openAccountModal = () => {
    setOpenAccount(true);
  };
  const closeAccountModal = () => {
    setOpenAccount(false);
  };

  //Square auth modal
  const [openSquareAuthSucc, setOpenSquareAuthSucc] = useState(false);
  const openSquareAuthSuccModal = () => {
    setOpenSquareAuthSucc(true);
  };
  const closeSquareAuthSuccModal = () => {
    setOpenSquareAuthSucc(false);
    navigate("/admin/home");
  };

  const onSelected = (pageName: string) => {
    if (pageName === "account") {
      openAccountModal();
    } else if (pageName === "businessprofile") {
      openBusinessProfileModal();
    } else if (pageName === "/admin/home") {
      setCurrentPage("/admin/home");
    } else if (pageName === "/admin/customers") {
      setCurrentPage("/admin/customers");
    }
  };

  if (_.isEmpty(sellerProfile.username)) {
    return <Onboarding />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* ---------- app bar ---------- */}
      <AppNav
        required={true}
        drawerName="Home settings"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />

      {/* ---------- drawer ---------- */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        {/* ---------- mobile drawer ---------- */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
            height: "100%",
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              height: "100%",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <HubDrawer onSelected={onSelected} />
          </Box>
        </Drawer>
        {/* ---------- desktop drawer ---------- */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
            height: "100%",
          }}
          open
        >
          <Box
            sx={{
              overflow: "auto",
              height: "100%",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <HubDrawer onSelected={onSelected} />
          </Box>
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: "100%",
          backgroundColor: "background.default",
        }}
      >
        {/* ---------- home content ---------- */}
        {currentPage === "/admin/customers" ? (
          <CustomerList />
        ) : (
          <ContentWrapNarrow>
            <HomeContent sellerProfile={sellerProfile} />
          </ContentWrapNarrow>
        )}
      </Box>

      <ProfileSettings
        autoPop={false}
        isModalOpen={openBusinessProfile}
        openModal={openBusinessProfileModal}
        closeModal={closeBusinessProfileModal}
      />
      <AccountSettings
        isModalOpen={openAccount}
        openModal={openAccountModal}
        closeModal={closeAccountModal}
      />
      <Dialog
        open={openSquareAuthSucc}
        fullWidth
        onClose={closeSquareAuthSuccModal}
      >
        <DialogTitle>Square account linked</DialogTitle>
        <DialogContent>
          Your Square account has been successfully linked. It is now available
          as a direct checkout option in your presales.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeSquareAuthSuccModal();
            }}
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Hub;
