import { Timestamp } from "@firebase/firestore";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_COLOR_PALETTE, USERS_TABLE_NAME } from "Constants";
import { doc, onSnapshot } from "firebase/firestore";
import { AvailabilityType } from "Model/Availability";
import { Profile, defaultProfile, defaultSquareState } from "Model/Profile";
import { getNewDateWithoutHours } from "Util/dateUtil";
import { db, publishProfile } from "Util/firebaseHelper";
import { fetchPresalesAsync } from "./presales";

const initialState = {
  sellerProfile: defaultProfile,
  loading: false,
  initialized: false,
};

export const fetchProfileAsync = createAsyncThunk(
  "sellerProfile/fetchProfile",
  async (userId: string, thunkAPI) => {
    if (userId) {
      const docRef = doc(db, USERS_TABLE_NAME, userId);
      onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data) {
            var type =
              (data?.availability?.type === AvailabilityType.acceptBetween ||
                data?.availability?.type === AvailabilityType.acceptUntil) &&
              (data?.availability.end instanceof Timestamp
                ? data?.availability.end.toDate().toISOString()
                : data?.availability?.end) < getNewDateWithoutHours()
                ? AvailabilityType.doNotAcceptOrder
                : data?.availability?.type || AvailabilityType.acceptNow;
            thunkAPI.dispatch(
              sellerProfileSlice.actions.editSellerProfile({
                businessName: data?.businessName,
                sellerName: data?.sellerName || "",
                sellerIntro: data?.sellerIntro || "",
                sellerCity: data?.sellerCity || "",
                sellerState: data?.sellerState || "",
                sellerZip: data?.sellerZip || "",
                fulfillmentMethod: data?.fulfillmentMethod || "Pickup only",
                defaultPickupAddress: data?.defaultPickupAddress || "",
                sellerPhoto: data?.sellerPhoto || "",
                username: data?.username || "",
                links: data?.links || [],
                forms: data?.forms || [],
                availability: {
                  type: type,
                  start:
                    (data?.availability.start instanceof Timestamp
                      ? data?.availability.start.toDate().toISOString()
                      : data?.availability?.start) || getNewDateWithoutHours(),
                  end:
                    (data?.availability.end instanceof Timestamp
                      ? data?.availability.end.toDate().toISOString()
                      : data?.availability?.end) || getNewDateWithoutHours(),
                  lead: data?.availability?.lead || 1,
                  dateBlocked: data?.availability?.dateBlocked || [],
                },
                presales: data?.presales || [],
                livePresales: data?.livePresales || [],
                payments: data?.payments || [],
                uid: data?.uid || userId,
                sellerEmail: data?.sellerEmail || "",
                colorPalette: data?.colorPalette || DEFAULT_COLOR_PALETTE,
                squareState: data?.squareState || defaultSquareState,
                disableCustomerEmailList:
                  data?.disableCustomerEmailList || false,
                imageGallery: data?.imageGallery || [],
                hideImageGallery: data?.hideImageGallery || false,
              })
            );
            if (data?.presales?.length !== 0) {
              thunkAPI.dispatch(fetchPresalesAsync(data.presales));
            }
          } else {
            thunkAPI.dispatch(
              sellerProfileSlice.actions.editSellerProfile(defaultProfile)
            );
          }
        } else {
          thunkAPI.dispatch(
            sellerProfileSlice.actions.editSellerProfile(defaultProfile)
          );
        }
      });
    }
  }
);

export const sellerProfileSlice = createSlice({
  name: "sellerProfile",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    editSellerProfile: (state, action: PayloadAction<Profile>) => {
      state.sellerProfile = action.payload;
      state.loading = false;
      state.initialized = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfileAsync.fulfilled, (state) => {
      state.initialized = true;
    });
    builder.addCase(fetchProfileAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProfileAsync.rejected, (state) => {
      state.loading = false;
      state.initialized = true;
    });
  },
});

export const updateProfileAsync = createAsyncThunk(
  "sellerProfile/updateProfile",
  async (
    { uid, sellerProfile }: { uid: string; sellerProfile: Profile },
    thunkAPI
  ) => {
    console.log("updateProfile", uid, sellerProfile);

    if (uid) {
      thunkAPI.dispatch(
        sellerProfileSlice.actions.editSellerProfile(sellerProfile)
      );
      await publishProfile(sellerProfile, uid);
    }
  }
);
