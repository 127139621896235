import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { DefaultCustomerRequestInfo } from "Model/CustomerRequestInfo";

export const selectedRequestSlice = createSlice({
  name: "selectedRequest",
  initialState: DefaultCustomerRequestInfo,
  reducers: {
    selectRequest: (state, { payload }: PayloadAction<CustomerRequestInfo>) =>
      payload,
  },
});
