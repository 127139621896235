import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { readSellerInfo } from "Util/firebaseHelper";
import { defaultProfile, defaultSquareState, Profile } from "Model/Profile";

// ui related
import { Box, Button } from "@mui/material";
import {
  ContentWrapNarrow,
  CreatedWith,
  Loading,
} from "Components/AllComponents";
import { HomeContent } from "./HomeContent";
import { AvailabilityType } from "Model/Availability";
import { getNewDateWithoutHours } from "Util/dateUtil";
import { Timestamp } from "@firebase/firestore";
import { NotFound } from "Components/NotFound";
import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from "Components/Styles/Styles";
import { DEFAULT_COLOR_PALETTE } from "Constants";

const HomePage = () => {
  // userId is userName
  let { userId } = useParams<"userId">();
  userId = userId?.toLowerCase();
  const [sellerProfile, setSellerProfile] = useState<Profile>(defaultProfile);
  const [userNotExist, setUserNotExist] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (userId) {
        const form = await readSellerInfo(userId);
        let data = form.data();
        if (data) {
          var type =
            (data?.availability?.type === AvailabilityType.acceptBetween ||
              data?.availability?.type === AvailabilityType.acceptUntil) &&
            (data?.availability.end instanceof Timestamp
              ? data?.availability.end.toDate().toISOString()
              : data?.availability?.end) < getNewDateWithoutHours()
              ? AvailabilityType.doNotAcceptOrder
              : data?.availability?.type || AvailabilityType.acceptNow;
          document.title = data?.businessName;
          setSellerProfile({
            businessName: data?.businessName,
            sellerName: data?.sellerName || "",
            sellerIntro: data?.sellerIntro || "",
            sellerCity: data?.sellerCity || "",
            sellerState: data?.sellerState || "",
            sellerZip: data?.sellerZip || "",
            fulfillmentMethod: data?.fulfillmentMethod || "Pickup only",
            defaultPickupAddress: data?.defaultPickupAddress || "",
            sellerPhoto: data?.sellerPhoto || "",
            username: data?.username || "",
            links: data?.links || [],
            forms: data?.forms || [],
            availability: {
              type: type,
              start:
                (data?.availability.start instanceof Timestamp
                  ? data?.availability.start.toDate().toISOString()
                  : data?.availability?.start) || getNewDateWithoutHours(),
              end:
                (data?.availability.end instanceof Timestamp
                  ? data?.availability.end.toDate().toISOString()
                  : data?.availability?.end) || getNewDateWithoutHours(),
              lead: data?.availability?.lead || 1,
              dateBlocked: data?.availability?.dateBlocked || [],
            },
            presales: data?.presales || [],
            livePresales: data?.livePresales || [],
            payments: data?.payments || [],
            uid: data?.uid || "",
            sellerEmail: data?.sellerEmail || "",
            colorPalette: data?.colorPalette || DEFAULT_COLOR_PALETTE,
            squareState: data?.squareState || defaultSquareState,
            disableCustomerEmailList: data?.disableCustomerEmailList || false,
            imageGallery: data?.imageGallery || [],
            hideImageGallery: data?.hideImageGallery || false,
          });
          setUserNotExist(false);
        } else {
          setUserNotExist(true);
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  console.log(userId);

  return loading ? (
    <Loading />
  ) : userNotExist ? (
    <NotFound></NotFound>
  ) : (
    <ThemeProvider theme={createCustomTheme(sellerProfile.colorPalette)}>
      <Box
        sx={{
          pt: 4,
          pl: { md: 4, xs: 2 },
          pr: { md: 4, xs: 2 },
          pb: { md: 4, xs: 2 },
          backgroundColor: "background.default",
          minHeight: "100vh",
        }}
      >
        <ContentWrapNarrow>
          <HomeContent sellerProfile={sellerProfile} />
        </ContentWrapNarrow>
        <CreatedWith color="dark" />
      </Box>
    </ThemeProvider>
  );
};

export default HomePage;
