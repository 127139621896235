import { Box } from "@mui/material";
import { SxProps } from "@mui/material";

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
};

export const CardStyle = ({ sx = [], children }: Props) => {
  return (
    <Box
      sx={[
        { backgroundColor: "white", p: { xs: 2, sm: 3 }, borderRadius: 4 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
