import { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate } from "react-router-dom";
import { publishProfile } from "Util/firebaseHelper";
import _ from "lodash";
import {
  Draggable,
  DragDropContext,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { reorder } from "Util/helpers";
import { BusinessInfo } from "Model/BusinessInfo";
import { readBusinessInfo } from "Util/firebaseHelper";
import { SITE_URL } from "Constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// ui related
import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from "Components/Styles/Styles";
import {
  como,
  black,
  sorbus,
  darkPink,
  denim,
  blueViolet,
  contessa,
  royalHeath,
  danube,
  deepLilac,
} from "Components/Styles/colors";
import {
  Button,
  Box,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Link,
  Popover,
  IconButton,
} from "@mui/material";
import { AddExternalLink, CardStyle, InfoBox } from "Components/AllComponents";
import { Profile } from "Model/Profile";
import { useAppDispatch } from "Redux/hooks";
import { editSellerProfile } from "Redux/actionCreators";
import { AvailabilityType } from "Model/Availability";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";
import { PresaleEntry } from "Pages/CustomerView/CustomerPresale/Components/PresaleEntry";
import { toast } from "react-toastify";
import { ManageGallery } from "Components/BusinessView/ManageGallery";

// icons
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { EditPaymentLinks } from "Pages/BusinessView/Hub/Home/Components/EditPaymentLinks";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import FormatColorFillRoundedIcon from "@mui/icons-material/FormatColorFillRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PhotoLibraryRoundedIcon from "@mui/icons-material/PhotoLibraryRounded";

type UserLinkProps = {
  title: string;
  url: string;
  id: string;
  index: number;
};

const UserLink = (props: UserLinkProps) => {
  const { user, sellerProfile } = useSession();
  const [oldTitle, setOldTitle] = useState(props.title);
  const [title, setTitle] = useState(props.title);
  const [oldUrl, setOldUrl] = useState(props.url);
  const [url, setUrl] = useState(props.url);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setTitle(oldTitle);
    setUrl(oldUrl);
  };

  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided, snapshot) => (
        <div>
          <ListItemButton
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => {
              handleOpenModal();
            }}
          >
            <ListItemIcon>
              <DragIndicatorIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="subtitle1">{oldTitle}</Typography>}
            />
            <EditIcon sx={{ ml: 2 }} />
          </ListItemButton>
          <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
            <DialogTitle>Edit link</DialogTitle>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                let tempLinks = _.cloneDeep(sellerProfile.links);
                tempLinks[props.index].title = title;
                tempLinks[props.index].url = url;
                publishProfile(
                  { ...sellerProfile, links: tempLinks },
                  user?.uid || ""
                );
                dispatch(
                  editSellerProfile({
                    ...sellerProfile,
                    links: tempLinks,
                  })
                );
                setOpenModal(false);
                setOldTitle(title);
                setOldUrl(url);
              }}
            >
              <DialogContent>
                <TextField
                  fullWidth
                  required
                  id="title"
                  label="Title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  required
                  id="url"
                  label="Url"
                  type="url"
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                  onBlur={() => {
                    if (
                      url.indexOf("http://") !== 0 &&
                      url.indexOf("https://") !== 0
                    ) {
                      setUrl("http://" + url);
                    }
                  }}
                />
                <Button
                  startIcon={<DeleteRoundedIcon />}
                  variant="text"
                  onClick={() => {
                    let tempLinks = _.cloneDeep(sellerProfile.links);
                    tempLinks.splice(props.index, 1);
                    publishProfile(
                      { ...sellerProfile, links: tempLinks },
                      user?.uid || ""
                    );
                    dispatch(
                      editSellerProfile({
                        ...sellerProfile,
                        links: tempLinks,
                      })
                    );
                    setOpenModal(false);
                  }}
                  sx={{
                    width: "fit-content",
                    color: "text.secondary",
                    mt: 1,
                  }}
                >
                  Delete link
                </Button>
              </DialogContent>
              <DialogActions>
                {user ? (
                  <div>
                    <Button onClick={handleCloseModal} color="secondary">
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </div>
                ) : null}
              </DialogActions>
            </form>
          </Dialog>
        </div>
      )}
    </Draggable>
  );
};

type HomeProps = {
  sellerProfile: Profile;
};

export const HomeContent = ({ sellerProfile }: HomeProps) => {
  const { user } = useSession();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [businessInfo, setBusinessInfo] = useState<BusinessInfo>();
  const formPublished = sellerProfile.forms && sellerProfile.forms.length > 0;

  const [localLinks, setLocalLinks] = useState(sellerProfile.links);

  const [anchorColorMenu, setAnchorColorMenu] = useState<null | HTMLElement>(
    null
  );
  const handleOpenColorMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorColorMenu(event.currentTarget);
  };
  const handleCloseColorMenu = () => {
    setAnchorColorMenu(null);
  };

  useEffect(() => {
    setLocalLinks(sellerProfile.links);
  }, [sellerProfile.links]);

  useEffect(() => {
    async function fetchData() {
      if (formPublished) {
        const form = await readBusinessInfo(sellerProfile.forms[0]);
        let content = form.data();
        if (content) {
          setBusinessInfo({
            id: content.id,
            businessName: content.businessName,
            formIntro: content.formIntro,
            sellerName: content.sellerName,
            sellerIntro: content.sellerIntro,
            sellerCity: content.sellerCity,
            sellerState: content.sellerState,
            fulfillmentMethod: content.fulfillmentMethod,
            minOrder: content.minOrder,
            priceChoice: content.priceChoice,
            price: content.price,
            sellerBookedUp: content.sellerBookedUp,
            sellerAvailability: content.sellerAvailability,
            orderDetails: content.orderDetails,
            sellerEmail: content.sellerEmail,
            bucketLists: content.bucketLists || [""],
            flavors: content.flavors || [""],
            packagings: content.packagings || [""],
            paymentTypes: content.paymentTypes || [""],
            agreements: content.agreements || "",
            showBucketLists: content.showBucketLists || false,
            showFlavors: content.showFlavors || false,
            showPackagings: content.showPackagings || false,
            showPaymentTypes: content.showPaymentTypes || false,
            sellerPhoto: content.sellerPhoto || "",
            aic: content.aic || false,
            username: content.username || "",
          });
        }
      }
    }
    fetchData();
  }, []);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) {
      // var removedLinks = [...localLinks];
      // removedLinks.splice(source.index, 1);
      // setLocalLinks(removedLinks);
      // publishProfile(
      //   { ...sellerProfile, links: removedLinks },
      //   user?.uid || ""
      // );
      return;
    }
    const newLinks = reorder(localLinks, source.index, destination.index);
    setLocalLinks(newLinks);
    publishProfile({ ...sellerProfile, links: newLinks }, user?.uid || "");
    dispatch(
      editSellerProfile({
        ...sellerProfile,
        links: newLinks,
      })
    );
  };

  const onColorChanged = (color: string) => {
    publishProfile({ ...sellerProfile, colorPalette: color }, user?.uid || "");
    dispatch(
      editSellerProfile({
        ...sellerProfile,
        colorPalette: color,
      })
    );
  };

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ pt: { xs: 2, sm: 1 } }}
      >
        <Link
          variant="subtitle2"
          href={"/" + sellerProfile.username}
          target="_blank"
        >
          {`${SITE_URL}/${sellerProfile.username}`}
        </Link>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(
              `${SITE_URL}/${sellerProfile.username}`
            );
            toast("Link copied.", {
              position: "top-center",
              autoClose: 800,
            });
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Stack>

      <ThemeProvider theme={createCustomTheme(sellerProfile.colorPalette)}>
        <Stack
          spacing={4}
          sx={{
            backgroundColor: "background.default",
            p: { xs: 2, md: 3 },
            border: { xs: "none", md: "1.5px solid #14192d" },
            outline: { xs: "1.5px solid #14192d", md: "none" },
            borderRadius: 4,
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 6px 20px 0px",
          }}
        >
          {/* ------------- profile section ------------- */}
          <Stack spacing={1}>
            <Stack direction="row" alignItems="flex-start" spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={-1.5}
                flex={1}
              >
                <Stack
                  sx={{
                    backgroundColor: "white",
                    p: 0.25,
                    alignItems: "center",
                    textAlign: "center",
                    width: "fit-content",
                    borderRadius: "50%",
                    zIndex: "1",
                    boxShadow: "rgba(0, 0, 0, 0.04) 0px 4px 16px 0px",
                  }}
                >
                  {sellerProfile.sellerPhoto === "" ? (
                    // <Avatar
                    //   size={72}
                    //   name={sellerProfile.sellerName ? sellerProfile.sellerName : ""}
                    //   variant="beam"
                    //   colors={["#F5673A", "#EA8676", "#FFB257", "#FFE2BF", "#FBED46"]}
                    // />
                    <svg
                      viewBox="0 0 36 36"
                      fill="none"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                    >
                      <mask
                        id=":r126:"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="36"
                        height="36"
                      >
                        <rect
                          width="36"
                          height="36"
                          rx="72"
                          fill="#FFFFFF"
                        ></rect>
                      </mask>
                      <g mask="url(#:r126:)">
                        <rect width="36" height="36" fill="#c8c8b9"></rect>
                        <rect
                          x="0"
                          y="0"
                          width="36"
                          height="36"
                          transform="translate(-2 6) rotate(306 18 18) scale(1)"
                          fill="#eeda8d"
                          rx="36"
                        ></rect>
                        <g transform="translate(-2 6) rotate(6 18 18)">
                          <path
                            d="M13,19 a1,0.75 0 0,0 10,0"
                            fill="#000000"
                          ></path>
                          <rect
                            x="13"
                            y="14"
                            width="1.5"
                            height="2"
                            rx="1"
                            stroke="none"
                            fill="#000000"
                          ></rect>
                          <rect
                            x="21"
                            y="14"
                            width="1.5"
                            height="2"
                            rx="1"
                            stroke="none"
                            fill="#000000"
                          ></rect>
                        </g>
                      </g>
                    </svg>
                  ) : (
                    <img
                      src={sellerProfile.sellerPhoto}
                      width={72}
                      height={72}
                      style={{
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Stack>
              </Stack>
              <IconButton color="primary" onClick={handleOpenColorMenu}>
                <FormatColorFillRoundedIcon />
              </IconButton>
              <Popover
                id="menu-colors"
                anchorEl={anchorColorMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorColorMenu)}
                onClose={handleCloseColorMenu}
              >
                <Stack sx={{ p: 1 }}>
                  <Box>
                    <IconButton
                      size="large"
                      sx={{ color: sorbus[700] }}
                      onClick={() => {
                        onColorChanged("sorbus");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      sx={{ color: darkPink[700] }}
                      onClick={() => {
                        onColorChanged("darkPink");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      sx={{ color: denim[700] }}
                      onClick={() => {
                        onColorChanged("denim");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      sx={{ color: blueViolet[700] }}
                      onClick={() => {
                        onColorChanged("blueViolet");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      size="large"
                      sx={{ color: contessa[700] }}
                      onClick={() => {
                        onColorChanged("contessa");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      sx={{ color: royalHeath[700] }}
                      onClick={() => {
                        onColorChanged("royalHeath");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      sx={{ color: danube[700] }}
                      onClick={() => {
                        onColorChanged("danube");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      sx={{ color: deepLilac[700] }}
                      onClick={() => {
                        onColorChanged("deepLilac");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      size="large"
                      sx={{ color: black[700] }}
                      onClick={() => {
                        onColorChanged("black");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      sx={{ color: como[700] }}
                      onClick={() => {
                        onColorChanged("como");
                      }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Stack>
              </Popover>
            </Stack>
            <Typography color="text.primary" variant="h5" sx={{ pt: 0.5 }}>
              {sellerProfile.businessName}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <FmdGoodOutlinedIcon fontSize="small" sx={{ opacity: 0.5 }} />
                <Typography color="text.primary" variant="subtitle2">
                  {sellerProfile.sellerCity ? sellerProfile.sellerCity : "City"}
                  ,{" "}
                  {sellerProfile.sellerState
                    ? sellerProfile.sellerState
                    : "State"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <LocalMallOutlinedIcon fontSize="small" sx={{ opacity: 0.5 }} />
                <Typography color="text.primary" variant="subtitle2">
                  {sellerProfile.fulfillmentMethod}
                </Typography>
              </Stack>
            </Stack>

            {sellerProfile.sellerName || sellerProfile.sellerIntro ? (
              <Stack pt={2}>
                {sellerProfile.sellerName ? (
                  <Typography color="text.primary" variant="subtitle2">
                    Meet {sellerProfile.sellerName}
                  </Typography>
                ) : null}
                {sellerProfile.sellerIntro ? (
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {sellerProfile.sellerIntro}
                  </Typography>
                ) : null}
              </Stack>
            ) : null}
          </Stack>

          {!formPublished ||
          sellerProfile.presales.length === 0 ||
          sellerProfile.payments.length === 0 ? (
            <Stack
              spacing={3}
              divider={<Divider />}
              sx={{
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderColor: "rgba(0,0,0,0.12)",
                pt: 3,
                pb: 3,
              }}
            >
              {/* ------------- custom order form ------------- */}
              {formPublished ? null : (
                <Stack>
                  <Typography color="text.primary" variant="h6">
                    Start taking custom orders
                  </Typography>
                  <Typography color="text.secondary">
                    Create an order form within a minute.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      navigate("/admin/form");
                    }}
                    sx={{ mt: 1, width: "fit-content" }}
                  >
                    Create form with template
                  </Button>
                </Stack>
              )}
              {/* add payment */}
              {sellerProfile.payments.length === 0 ? (
                <Stack>
                  <Typography color="text.primary" variant="h6">
                    Add payment methods
                  </Typography>
                  <Typography color="text.secondary" mb={1}>
                    Add payment mothods and link your Square account to enable
                    direct checkout for your presales.
                  </Typography>
                  <EditPaymentLinks empty />
                </Stack>
              ) : null}
              {/* ------------- 1st presale ------------- */}
              {sellerProfile.presales.length === 0 ? (
                <Stack>
                  <Typography color="text.primary" variant="h6">
                    Create your first presale
                  </Typography>
                  <Typography color="text.secondary">
                    Experience a hassle-free presale by simply adding items.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      navigate("/admin/presales");
                    }}
                    sx={{ mt: 1, width: "fit-content" }}
                  >
                    Get started
                  </Button>
                </Stack>
              ) : null}
            </Stack>
          ) : null}

          <Stack spacing={2.5}>
            {/* ------------- presale ------------- */}
            {sellerProfile.livePresales.map((p, index) => (
              <PresaleEntry
                editing={true}
                key={p}
                presaleId={p}
                username={sellerProfile.username}
              />
            ))}

            {/* ------------- custom order ------------- */}
            {formPublished ? (
              <CardStyle
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.04) 0px 4px 16px 0px",
                }}
              >
                <Stack>
                  <Typography
                    variant="h6"
                    flex={1}
                    sx={{
                      mb: 0.5,
                      fontFamily: "Frank Ruhl Libre",
                      fontSize: "1.4rem",
                    }}
                  >
                    Custom order
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ sm: 1, xs: 2 }}
                  >
                    {sellerProfile.availability.type ===
                    AvailabilityType.doNotAcceptOrder ? (
                      <Typography
                        flex={1}
                        variant="body1"
                        color="text.secondary"
                      >
                        Not accepting orders
                      </Typography>
                    ) : (
                      <Stack flex={1}>
                        <Typography color="text.primary" variant="subtitle2">
                          Starts at ${businessInfo?.price}/dozen
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          {businessInfo?.minOrder} dozen minimum
                        </Typography>
                      </Stack>
                    )}
                  </Stack>

                  <ManageGallery>
                    <InfoBox sx={{ mt: 1 }}>
                      <Stack sx={{ alignItems: "center" }}>
                        <PhotoLibraryRoundedIcon />
                        <Typography variant="subtitle2">
                          Image gallery
                        </Typography>
                      </Stack>
                    </InfoBox>
                  </ManageGallery>

                  <InfoBox sx={{ mt: 2, p: 1 }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                      spacing={1}
                    >
                      <Button
                        variant="text"
                        onClick={() => navigate("/admin/orders")}
                        sx={{
                          width: "100%",
                          backgroundColor: "white",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.6)",
                          },
                        }}
                      >
                        View orders
                      </Button>
                      <Button
                        variant="text"
                        startIcon={<EditRoundedIcon />}
                        onClick={() => navigate("/admin/form")}
                        sx={{
                          width: "100%",
                          backgroundColor: "white",
                          "&:hover": {
                            backgroundColor: "rgba(255,255,255,0.6)",
                          },
                        }}
                      >
                        Edit form
                      </Button>
                    </Stack>
                  </InfoBox>
                </Stack>
              </CardStyle>
            ) : null}

            {/* ------------- email list ------------- */}
            {sellerProfile.disableCustomerEmailList ||
            (!formPublished &&
              sellerProfile.livePresales.length === 0) ? null : (
              <CardStyle
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.04) 0px 4px 16px 0px",
                }}
              >
                <Stack>
                  <Typography
                    variant="h6"
                    flex={1}
                    sx={{ fontFamily: "Frank Ruhl Libre", fontSize: "1.4rem" }}
                  >
                    Join email list
                  </Typography>
                  <InfoBox sx={{ mt: 1, p: 1 }}>
                    <Button
                      onClick={() => navigate("/admin/customers")}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        "&:hover": { backgroundColor: "rgba(255,255,255,0.6)" },
                      }}
                    >
                      View subscribers
                    </Button>
                  </InfoBox>
                </Stack>
              </CardStyle>
            )}
          </Stack>

          {/* ------------- payment links ------------- */}
          {sellerProfile.payments.length > 0 ? <EditPaymentLinks /> : null}

          {/* ------------- custom links ------------- */}
          <List disablePadding>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-list">
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    {localLinks.map((link, index) => (
                      <UserLink
                        key={link.id}
                        id={link.id}
                        title={link.title}
                        url={link.url}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>

            <Box sx={{ width: "100%", textAlign: "center" }}>
              <AddExternalLink />
            </Box>
          </List>
        </Stack>
      </ThemeProvider>
    </Stack>
  );
};
