import { FormikProps } from "formik";

import { Box, Stack, Typography, Divider } from "@mui/material";
import { CardStyle } from "Components/AllComponents";
import { PresaleItem } from "Model/PresaleItem";
import { PresaleInfo } from "Model/PresaleInfo";

type Props = {
  presaleItem: PresaleItem;
  presaleId: string;
  index: number;
  formik: FormikProps<PresaleInfo>;
};

export const EndedPresaleItem = ({ presaleItem }: Props) => {
  return (
    <CardStyle>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Box
          flex={1}
          sx={{
            backgroundColor: "background.default",
            borderRadius: 1,
            overflow: "hidden",
            height: "fit-content",
          }}
        >
          {presaleItem.img ? (
            <img
              style={{
                width: "100%",
                display: "block",
              }}
              src={presaleItem.img}
            />
          ) : (
            <Box
              sx={{
                aspectRatio: "1/1",
                backgroundColor: "background.default",
              }}
            />
          )}
        </Box>
        <Stack flex={2}>
          <Typography variant="h6">
            {presaleItem.title ? presaleItem.title : "No name"}
          </Typography>
          {presaleItem.description && (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ whiteSpace: "pre-line" }}
            >
              {presaleItem.description}
            </Typography>
          )}
          <Typography variant="subtitle2">${presaleItem.price}</Typography>

          <Divider sx={{ mt: 1 }} />
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            Personalized
          </Typography>
          <Typography variant="body1">
            {presaleItem.personalizable ? "Yes" : "No"}
          </Typography>

          {presaleItem.flavors.length > 0 ? (
            <>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                Flavors
              </Typography>
              {presaleItem.flavors.map((flavor) => (
                <Typography variant="body1" key={flavor.flavorName}>
                  {flavor.flavorPrice !== 0
                    ? `${flavor.flavorName} (+$${flavor.flavorPrice})`
                    : flavor.flavorName}
                </Typography>
              ))}
            </>
          ) : null}

          {presaleItem.colors.length > 0 ? (
            <>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                Designs
              </Typography>
              {presaleItem.colors.map((color) => (
                <Typography variant="body1" key={color}>
                  {color}
                </Typography>
              ))}
            </>
          ) : null}
        </Stack>
      </Stack>
    </CardStyle>
  );
};
