import { useNavigate } from "react-router-dom";

import { Typography, Stack, Button } from "@mui/material";
import { CardStyle, ContentWrapNarrow } from "Components/AllComponents";

export function NotFound() {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "background.default", minHeight: "100vh", p: 2 }}
    >
      <Typography variant="h4" sx={{ fontWeight: 500 }}>
        Page not found
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          navigate("/admin/home");
        }}
      >
        Home
      </Button>
    </Stack>
  );
}
