import React, { useEffect, useState } from "react";
import { OrderInfo } from "Model/OrderInfo";
import USStates from "Components/USStates.json";
import { Unit } from "Model/CustomerRequestInfo";
import { FormikProps } from "formik";

import {
  Box,
  TextField,
  Grid,
  Stack,
  Divider,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Profile, defaultProfile } from "Model/Profile";
import { Payment, defaultPayment } from "Model/Payment";

export const NewOrderInfo = ({
  formik,
  sellerProfile,
}: {
  formik: FormikProps<OrderInfo>;
  sellerProfile: Profile;
}) => {
  return (
    <Box>
      <Typography variant="subtitle2">Payment</Typography>
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          required
          type="number"
          id="orderTotal"
          label="Order total"
          value={formik.values.orderTotal}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onWheel={(e: any) => e.target.blur()}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: { min: 0 },
          }}
          error={formik.touched.orderTotal && Boolean(formik.errors.orderTotal)}
          helperText={formik.touched.orderTotal && formik.errors.orderTotal}
        />
        <TextField
          fullWidth
          required
          type="number"
          id="paid"
          label="Paid"
          value={formik.values.paid}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onWheel={(e: any) => e.target.blur()}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: { min: 0 },
          }}
          error={formik.touched.paid && Boolean(formik.errors.paid)}
          helperText={formik.touched.paid && formik.errors.paid}
        />
      </Stack>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Date needed
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          format="MM/dd/yyyy"
          value={new Date(formik.values.customerDate)}
          onChange={(e) => {
            formik.setFieldValue("customerDate", e?.toISOString());
          }}
          slotProps={{
            textField: {
              required: true,
              fullWidth: true,
              margin: "dense",
            },
          }}
          disablePast
        />
      </LocalizationProvider>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Quantity
      </Typography>
      <Stack direction="row" spacing={1} alignItems="flex-start" sx={{ mt: 1 }}>
        <TextField
          fullWidth
          required
          type="number"
          onWheel={(e: any) => e.target.blur()}
          id="count"
          value={formik.values.count}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.count && Boolean(formik.errors.count)}
          helperText={formik.touched.count && formik.errors.count}
        />
        <FormControl sx={{ minWidth: "110px" }}>
          <Select
            id="unit"
            name="unit"
            value={formik.values.unit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <MenuItem value={Unit.Dozen}>Dozen</MenuItem>
            <MenuItem value={Unit.Individual}>Cookies</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Contact
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 1 }}
        alignItems="flex-start"
        sx={{ mt: 2 }}
      >
        <TextField
          fullWidth
          required
          id="firstName"
          label="First name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          fullWidth
          required
          id="lastName"
          label="Last name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 1 }}
        alignItems="flex-start"
        sx={{ mt: 2 }}
      >
        <TextField
          fullWidth
          id="phoneNumber"
          label="Phone number"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
          }
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        />
        <TextField
          fullWidth
          required
          id="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Stack>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Fulfillment
      </Typography>
      <FormControl required fullWidth>
        <RadioGroup
          name="customerFulfillment"
          value={formik.values.customerFulfillment}
          onChange={formik.handleChange}
        >
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              value="Pickup"
              control={<Radio />}
              label="Pickup"
              checked={formik.values.customerFulfillment === "Pickup"}
              defaultChecked
            />
            <FormControlLabel
              value="Shipping"
              control={<Radio />}
              label="Shipping"
              checked={formik.values.customerFulfillment === "Shipping"}
              defaultChecked
            />
          </Stack>
        </RadioGroup>
      </FormControl>
      {/* shipping address */}
      {formik.values.customerFulfillment === "Shipping" ? (
        <div>
          <TextField
            fullWidth
            margin="normal"
            required
            id="shippingAddress1"
            label="Address line 1"
            value={formik.values.shippingAddress1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.shippingAddress1 &&
              Boolean(formik.errors.shippingAddress1)
            }
            helperText={
              formik.touched.shippingAddress1 && formik.errors.shippingAddress1
            }
          />
          <TextField
            fullWidth
            margin="normal"
            id="shippingAddress2"
            label="Address line 2"
            value={formik.values.shippingAddress2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.shippingAddress2 &&
              Boolean(formik.errors.shippingAddress2)
            }
            helperText={
              formik.touched.shippingAddress2 && formik.errors.shippingAddress2
            }
          />
          <TextField
            fullWidth
            margin="normal"
            required
            id="shippingCity"
            label="City"
            value={formik.values.shippingCity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.shippingCity && Boolean(formik.errors.shippingCity)
            }
            helperText={
              formik.touched.shippingCity && formik.errors.shippingCity
            }
          />
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <FormControl
                required
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                error={
                  formik.touched.shippingState &&
                  Boolean(formik.errors.shippingState)
                }
              >
                <InputLabel>State</InputLabel>
                <Select
                  label="State"
                  value={formik.values.shippingState}
                  onChange={(e) => {
                    formik.setFieldValue("shippingState", e.target.value);
                  }}
                >
                  {USStates.map((state) => (
                    <MenuItem value={state.abbreviation} key={state.name}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.shippingState && formik.errors.shippingState}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                required
                id="shippingZip"
                label="Zip code"
                value={formik.values.shippingZip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.shippingZip &&
                  Boolean(formik.errors.shippingZip)
                }
                helperText={
                  formik.touched.shippingZip && formik.errors.shippingZip
                }
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </Box>
  );
};
