import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";
import { DEFAULT_BUCKET_LIST } from "Constants";

// ui related
import { FormControl, Typography, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { InfoBox } from "Components/Containers/InfoBox";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerBucketLists = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  if (businessInfo.showBucketLists) {
    return (
      <FormSection id="bucket-list" sectionName="My bucket list">
        <InfoBox>
          <Typography variant="body1">
            I would love to create these sets!
          </Typography>
        </InfoBox>
        <FormControl fullWidth margin="normal">
          <Select
            value={customerFormik.values.bucketLists}
            onChange={(e) => {
              customerFormik.setFieldValue("bucketLists", e.target.value);
            }}
            sx={{
              "& .MuiOutlinedInput-input.MuiSelect-select": {
                whiteSpace: "normal",
              },
            }}
          >
            <MenuItem value={DEFAULT_BUCKET_LIST}>
              {DEFAULT_BUCKET_LIST}
            </MenuItem>
            {businessInfo.bucketLists.map((bucketList, index) => (
              <MenuItem
                value={bucketList}
                key={index}
                sx={{ whiteSpace: "normal" }}
              >
                {bucketList}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormSection>
    );
  } else {
    return null;
  }
};
