import * as yup from "yup";
import { PresaleItem, presaleItemValidationSchema } from "./PresaleItem";
import {
  PRESALE_ANNOUCEMENT_NONE,
  PRESALE_STATUS_NOTPUBLISHED,
} from "Constants";
import _, { add, pick } from "lodash";
import { Profile, getFullDefaultAddress } from "./Profile";

export const presaleInfoValidationSchema = yup.object({
  presaleName: yup.string().required("This field is required"),
  paymentIds: yup
    .array()
    .of(yup.string())
    .test("at-least-one", "At least one payment is required", function (value) {
      return Array.isArray(value) && value.length > 0;
    }),
  fulfillments: yup
    .array()
    .of(
      yup.object().shape({
        date: yup.string(),
        range: yup.string(),
        address: yup.string(),
      })
    )
    .test(
      "at-least-one",
      "At least one pickup date is required.",
      function (value) {
        return Array.isArray(value) && value.length > 0;
      }
    ),
  presaleItems: yup
    .array()
    .of(presaleItemValidationSchema)
    .test("at-least-one", "At least one item is required", function (value) {
      return Array.isArray(value) && value.length > 0;
    })
    .min(1, "At least one item is required"),
});

export interface Fulfillment {
  date: string;
  range: string;
  address: string;
  uDA: boolean; // useDefaultAddress
}

export function generateNewFulfillment(uDA: boolean): Fulfillment {
  const date = new Date();
  const dateStr = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return {
    date: dateStr,
    range: "",
    address: "",
    uDA: uDA,
  };
}

export const fulfillmentToString = (
  fulfillment: Fulfillment,
  sellerProfile: Profile
) => {
  return (
    fulfillment.date +
    "##" +
    fulfillment.range +
    "##" +
    (fulfillment.uDA
      ? getFullDefaultAddress(sellerProfile)
      : fulfillment.address) +
    "##" +
    fulfillment.uDA
  );
};

export const stringToFulfillment = (string: string): Fulfillment => {
  const [date, range, address, uDA] = string.split("##");
  return { date, range, address, uDA: uDA.toLowerCase() === "true" };
};

export const generateFulfillmentKey = (fulfillment: Fulfillment) => {
  return (
    fulfillment.date +
    "##" +
    fulfillment.range +
    "##" +
    fulfillment.address +
    "##" +
    fulfillment.uDA
  );
};

export const defaultFulfillment: Fulfillment = {
  date: "",
  range: "",
  address: "",
  uDA: true,
};

export const defaultPresaleInfo = (
  uDA: boolean,
  paymentIds: string[]
): PresaleInfo => {
  return {
    presaleId: "",
    presaleName: "",
    presaleDescription: "",
    status: PRESALE_STATUS_NOTPUBLISHED,
    presaleItems: [],
    uid: "",
    fulfillments: [],
    startTime: "",
    endTime: "",
    paymentIds: paymentIds,
    presaleAnnouncement: PRESALE_ANNOUCEMENT_NONE,
  };
};

export interface PresaleInfo {
  presaleId: string;
  presaleName: string;
  presaleDescription: string;
  status: string;
  presaleItems: PresaleItem[];
  uid: string;
  fulfillments: Fulfillment[];
  startTime: string;
  endTime: string;
  paymentIds: string[]; // Allowed payments from user profile
  presaleAnnouncement: string;
}

export const getPresaleName = (presaleName: string) => {
  return _.isEmpty(presaleName) ? "New Presale" : presaleName;
};

export function formatFulfillments(fulfillments: Fulfillment[]): string {
  const dateStrings = fulfillments.map((fulfillment) => {
    const parsedDate = new Date(fulfillment.date);
    const month = parsedDate.toLocaleString("default", { month: "short" });
    const day = parsedDate.getDate().toString().padStart(2, "0");
    return `${month} ${day}`;
  });

  return `Pick up on ${dateStrings.join(", ")}`;
}
