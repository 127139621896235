import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  Stack,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo, Unit } from "Model/CustomerRequestInfo";

export const CustomerQuantity = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  return (
    <FormSection id="quantity" sectionName="Quantity">
      {businessInfo.aic ? (
        <RadioGroup
          name="unit"
          value={customerFormik.values.unit}
          onChange={customerFormik.handleChange}
          sx={{ mb: 1 }}
        >
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              value={Unit.Dozen}
              control={<Radio />}
              label="Dozen"
            />
            <FormControlLabel
              value={Unit.Individual}
              control={<Radio />}
              label="Individual"
            />
          </Stack>
        </RadioGroup>
      ) : null}
      <TextField
        fullWidth
        required
        id="count"
        type="number"
        onWheel={(e: any) => e.target.blur()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {
                customerFormik.values.unit == Unit.Dozen
                  ? "dozen"
                  : "cookies" /* need == instead of === for enum*/
              }
            </InputAdornment>
          ),
          inputProps: {
            step: customerFormik.values.unit == Unit.Dozen ? 0.5 : 1,
            min:
              customerFormik.values.unit == Unit.Dozen
                ? businessInfo.minOrder
                : businessInfo.minOrder * 12,
          },
        }}
        onChange={customerFormik.handleChange}
        onBlur={customerFormik.handleBlur}
        error={
          customerFormik.touched.count && Boolean(customerFormik.errors.count)
        }
        helperText={
          customerFormik.touched.count && customerFormik.errors.count
            ? customerFormik.errors.count
            : "Minimum: " +
              (customerFormik.values.unit == Unit.Dozen
                ? businessInfo.minOrder + " dozen"
                : businessInfo.minOrder * 12 + " cookies")
        }
      />
    </FormSection>
  );
};
