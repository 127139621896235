import { FormikProps } from "formik";
import { Availability, AvailabilityType } from "Model/Availability";
import {
  dueDateStringForm,
  getDateMMDDYYYY,
  getMinMaxDueDate,
} from "Util/dateUtil";

// ui related
import { TextField, Stack, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { InfoBox } from "Components/Containers/InfoBox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const CustomerDateNeeded = ({
  availability,
  customerFormik,
}: {
  availability: Availability;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  const minMaxDue = getMinMaxDueDate(availability);

  const disableBlockedDates = (date: Date) => {
    return (
      availability.dateBlocked.findIndex((d) => d === getDateMMDDYYYY(date)) !==
      -1
    );
  };

  return (
    <FormSection id="date" sectionName={"Date needed"}>
      <Stack spacing={3}>
        {/* <InfoBox>
          <Typography variant="body1">
            {"Based on my availability, " + dueDateStringForm(availability)}
          </Typography>
        </InfoBox> */}
        {availability.type === AvailabilityType.doNotAcceptOrder ? null : (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={minMaxDue.start === undefined}
              format="MM/dd/yyyy"
              value={
                minMaxDue.start === undefined ||
                new Date(customerFormik.values.customerDate) > minMaxDue.start
                  ? new Date(customerFormik.values.customerDate)
                  : minMaxDue.start
              }
              minDate={minMaxDue.start}
              maxDate={minMaxDue.end}
              shouldDisableDate={disableBlockedDates}
              onChange={(e) => {
                customerFormik.setFieldValue("customerDate", e?.toISOString());
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  helperText: "Submitting this form doesn't guarantee a date.",
                },
              }}
            />
          </LocalizationProvider>
        )}
      </Stack>
    </FormSection>
  );
};
