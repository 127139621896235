import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { FormEditor } from "Components/BusinessView/FormEditor";
import { InfoBox } from "Components/Containers/InfoBox";

export const Agreements = ({
  formik,
}: {
  formik: FormikProps<BusinessInfo>;
}) => {
  return (
    <FormSection id="agreements" sectionName="Agreements">
      <FormEditor visible>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 0.5 }}>
          Outline your policies on payments, changes, cancellations,
          intellectual property, and other relevant matters:
        </Typography>
        <TextField
          fullWidth
          multiline
          margin="dense"
          id="agreements"
          required
          value={formik.values.agreements}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.agreements && Boolean(formik.errors.agreements)}
          helperText={formik.touched.agreements && formik.errors.agreements}
        />
      </FormEditor>
      <FormControlLabel
        disabled
        control={<Checkbox required />}
        name="paymentChecked"
        label="I understand"
      />
    </FormSection>
  );
};
