import React, { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography, Stack, Toolbar, AppBar } from "@mui/material";
import { SignInBtn } from "Components/AllComponents";
import Logo from "Assets/logo-full.svg";

const PrivacyPolicy = () => {
  const { user } = useSession();
  const navigate = useNavigate();

  return (
    <Stack
      spacing={3}
      sx={{
        p: { xs: 2, sm: 3 },
        backgroundColor: "background.default",
        minHeight: "100vh",
      }}
    >
      <AppBar>
        <Toolbar variant="dense">
          <a href="/" style={{ width: 24, height: 24, marginRight: 8 }}>
            <img src={Logo} alt="Logo" style={{ width: 24, height: 24 }} />
          </a>
          <Typography variant="subtitle2" color="text.primary" flex={1}>
            Privacy Policy
          </Typography>
          <Stack direction="row" spacing={1}>
            {user ? (
              <Button
                variant="outlined"
                onClick={async () => {
                  navigate("/admin/home");
                }}
              >
                Admin
              </Button>
            ) : (
              <SignInBtn variant="outlined" label="Sign in" username="" />
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      <Stack spacing={2} sx={{ pt: { xs: 2, sm: 3 } }}>
        <Typography variant="h3">Privacy Policy</Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Last updated May 22, 2023
        </Typography>
        <Typography>
          This privacy notice for Flowly LLC ("Company," "we," "us," or "our"),
          describes how and why we might collect, store, use, and/or share
          ("process") your information when you use our services ("Services"),
          such as when you:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - Visit our website at http://www.flowlylink.com, or any website of
            ours that links to this privacy notice
          </Typography>
          <Typography>
            - Engage with us in other related ways, including any sales,
            marketing, or events
          </Typography>
        </Stack>
        <Typography>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          admin@flowlyapp.com.
        </Typography>
        <Typography variant="h5">1. WHAT INFORMATION DO WE COLLECT?</Typography>
        <Typography variant="h6">
          Personal information you disclose to us
        </Typography>
        <Typography>
          In Short: We collect personal information that you provide to us.
        </Typography>
        <Typography>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>- names</Typography>
          <Typography>- phone numbers</Typography>
          <Typography>- email addresses</Typography>
          <Typography>- mailing addresses</Typography>
          <Typography>- usernames</Typography>
          <Typography>- passwords</Typography>
          <Typography>- contact preferences</Typography>
          <Typography>- billing addresses</Typography>
          <Typography>- debit/credit card numbers</Typography>
          <Typography>- contact or authentication data</Typography>
        </Stack>
        <Typography>
          Sensitive Information. We do not process sensitive information.
        </Typography>
        <Typography>
          Social Media Login Data. We may provide you with the option to
          register with us using your existing social media account details,
          like your Facebook, Twitter, or other social media account. If you
          choose to register in this way, we will collect the information
          described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
          below.
        </Typography>
        <Typography>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </Typography>
        <Typography variant="h6">
          Information automatically collected
        </Typography>
        <Typography>
          In Short: Some information - such as your Internet Protocol (IP)
          address and/or browser and device characteristics - is collected
          automatically when you visit our Services.
        </Typography>
        <Typography>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </Typography>
        <Typography>
          Like many businesses, we also collect information through cookies and
          similar technologies. You can find out more about this in our Cookie
          Notice: http://www.flowlylink.com/cookie-policy.
        </Typography>
        <Typography>The information we collect includes:</Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called "crash dumps"), and
            hardware settings)
          </Typography>
          <Typography>
            - Device Data. We collect device data such as information about your
            computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </Typography>
          <Typography>
            - Location Data. We collect location data such as information about
            your device's location, which can be either precise or imprecise.
            How much information we collect depends on the type and settings of
            the device you use to access the Services. For example, we may use
            GPS and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </Typography>
        </Stack>
        <Typography variant="h5">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <Typography>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </Typography>
        <Typography>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - To facilitate account creation and authentication and otherwise
            manage user accounts. We may process your information so you can
            create and log in to your account, as well as keep your account in
            working order.
          </Typography>
          <Typography>
            - To deliver and facilitate delivery of services to the user. We may
            process your information to provide you with the requested service.
          </Typography>
          <Typography>
            - To respond to user inquiries/offer support to users. We may
            process your information to respond to your inquiries and solve any
            potential issues you might have with the requested service.
          </Typography>
          <Typography>
            - To send administrative information to you. We may process your
            information to send you details about our products and services,
            changes to our terms and policies, and other similar information.
          </Typography>
          <Typography>
            - To fulfill and manage your orders. We may process your information
            to fulfill and manage your orders, payments, returns, and exchanges
            made through the Services.
          </Typography>
          <Typography>
            - To enable user-to-user communications. We may process your
            information if you choose to use any of our offerings that allow for
            communication with another user.
          </Typography>
          <Typography>
            - To request feedback. We may process your information when
            necessary to request feedback and to contact you about your use of
            our Services.
          </Typography>
          <Typography>
            - To send you marketing and promotional communications. We may
            process the personal information you send to us for our marketing
            purposes, if this is in accordance with your marketing preferences.
            You can opt out of our marketing emails at any time. For more
            information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.
          </Typography>
          <Typography>
            - To deliver targeted advertising to you. We may process your
            information to develop and display personalized content and
            advertising tailored to your interests, location, and more. For more
            information see our Cookie Notice:
            http://www.flowlylink.com/cookie-policy.
          </Typography>
          <Typography>
            - To post testimonials. We post testimonials on our Services that
            may contain personal information.
          </Typography>
          <Typography>
            - To protect our Services. We may process your information as part
            of our efforts to keep our Services safe and secure, including fraud
            monitoring and prevention.
          </Typography>
          <Typography>
            - To administer prize draws and competitions. We may process your
            information to administer prize draws and competitions.
          </Typography>
          <Typography>
            - To evaluate and improve our Services, products, marketing, and
            your experience. We may process your information when we believe it
            is necessary to identify usage trends, determine the effectiveness
            of our promotional campaigns, and to evaluate and improve our
            Services, products, marketing, and your experience.
          </Typography>
          <Typography>
            - To identify usage trends. We may process information about how vou
            use our Services to better understand how they are being used so we
            can improve them.
          </Typography>
          <Typography>
            - To determine the effectiveness of our marketing and promotional
            campaigns. We may process your information to better understand how
            to provide marketing and promotional campaigns that are most
            relevant to you.
          </Typography>
          <Typography>
            - To comply with our legal obligations. We may process your
            information to comply with our legal obligations, respond to legal
            requests, and exercise, establish, or defend our legal riahts.
          </Typography>
        </Stack>
        <Typography variant="h5">
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography>
          In Short: We may share information in specific situations described in
          this section and/or with the following categories of third parties.
        </Typography>
        <Typography>
          Vendors, Consultants, and Other Third-Party Service Providers. We may
          share your data with third-party vendors, service providers,
          contractors, or agents ("third parties") who perform services for us
          or on our behalf and require access to such information to do that
          work. We have contracts in place with our third parties, which are
          designed to help safeguard your personal information. This means that
          they cannot do anything with your personal information unless we have
          instructed them to do it. They will also not share your personal
          information with any organization apart from us. They also commit to
          protect the data they hold on our behalf and to retain it for the
          period we instruct. The categories of third parties we may share
          personal information with are as follows:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>- Website Hosting Service Providers</Typography>
          <Typography>- Data Storage Service Providers</Typography>
          <Typography>- Data Analytics Services</Typography>
          <Typography>- Payment Processors</Typography>
        </Stack>
        <Typography>
          We also may need to share your personal information in the following
          situations:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing or acquisition of all or a portion of our
            business to another company.
          </Typography>
          <Typography>
            - Other Users. When you share personal information (for example, by
            posting comments, contributions, or other content to the Services)
            or otherwise interact with public areas of the Services, such
            personal information may be viewed by all users and may be publicly
            made available outside the Services in perpetuity. If you interact
            with other users of our Services and register for our Services
            through a social network (such as Facebook), your contacts on the
            social network will see your name, profile photo, and descriptions
            of your activity. Similarly, other users will be able to view
            descriptions of your activity, communicate with you within our
            Services, and view your profile.
          </Typography>
        </Stack>
        <Typography variant="h5">
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>
        <Typography>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </Typography>
        <Typography>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice: http://www.flowlylink.com/cookie-policy.
        </Typography>
        <Typography variant="h5">
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </Typography>
        <Typography>
          In Short: If you choose to register or log in to our Services using a
          social media account, we may have access to certain information about
          you.
        </Typography>
        <Typography>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </Typography>
        <Typography>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </Typography>
        <Typography variant="h5">
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <Typography>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </Typography>
        <Typography>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </Typography>
        <Typography>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </Typography>
        <Typography variant="h5">
          7. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Typography>
        <Typography>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </Typography>
        <Typography>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </Typography>
        <Typography variant="h5">
          8. DO WE COLLECT INFORMATION FROM MINORS?
        </Typography>
        <Typography>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </Typography>
        <Typography>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at admin@flowlyapp.com.
        </Typography>
        <Typography variant="h5">9. WHAT ARE YOUR PRIVACY RIGHTS?</Typography>
        <Typography>
          In Short: You may review, change, or terminate your account at any
          time.
        </Typography>
        <Typography>
          If you are located in the EA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your Member State data protection authority or UK data
          protection authority.
        </Typography>
        <Typography>
          If you are located in Switzerland, you may contact the Federal Data
          Protection and Information Commissioner.
        </Typography>
        <Typography>
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        </Typography>
        <Typography>
          Opting out of marketing and promotional communications: You can
          unsubscribe from our marketing and promotional communications at any
          time by clicking on the unsubscribe link in the emails that we send,
          replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or
          by contacting us using the details provided in the section "HOW CAN
          YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed
          from the marketing lists. However, we may still communicate with you -
          for example, to send you service-related messages that are necessary
          for the administration and use of your account, to respond to service
          requests, or for other non-marketing purposes.
        </Typography>
        <Typography>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </Typography>
        <Typography variant="h6">Account Information</Typography>
        <Typography>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - Log in to your account settings and update your user account.
          </Typography>
          <Typography>
            - Contact us using the contact information provided.
          </Typography>
        </Stack>
        <Typography>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </Typography>
        <Typography>
          Cookies and similar technologies: Most Web browsers are set to accept
          cookies by default. If you prefer, you can usually choose to set your
          browser to remove cookies and to reject cookies. If you choose to
          remove cookies or reject cookies, this could affect certain features
          or services of our Services. You may also opt out of interest-based
          advertising by advertisers on our Services. For further information,
          please see our Cookie Notice: http://www.flowlylink.com/cookie-policy.
        </Typography>
        <Typography>
          If you have questions or comments about your privacy rights, you may
          email us at admin@flowlyapp.com
        </Typography>
        <Typography variant="h5">
          10. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <Typography>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </Typography>
        <Typography variant="h5">
          11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Typography>
        <Typography>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </Typography>
        <Typography>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </Typography>
        <Typography>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </Typography>
        <Typography variant="h6">CCPA Privacy Notice</Typography>
        <Typography>
          The California Code of Regulations defines a "resident" as: (1) every
          individual who is in the State of California for other than a
          temporary or transitory purpose and (2) every individual who is
          domiciled in the State of California who is outside the State of
          California for a temporary or transitory purpose.
        </Typography>
        <Typography>
          All other individuals are defined as "non-residents."
        </Typography>
        <Typography>
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </Typography>
        <Typography variant="subtitle2">
          What categories of personal information do we collect?
        </Typography>
        <Typography>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            A. Identifiers (collected): Contact details, such as real name,
            alias, postal address, telephone or mobile /contact number, unique
            personal identifier, online identifier, Internet Protocol address,
            email address, and account name.
          </Typography>
          <Typography>
            B. Personal information categories listed in the /California
            Customer Records statute (collected): Name, contact information,
            education, employment, employment history, and financial
            information.
          </Typography>
          <Typography>
            C. Protected classification characteristics under California or
            federal law (not collected): Gender and date of birth
          </Typography>
          <Typography>
            D. Commercial information (not collected): Transaction information,
            purchase history, financial details, and payment information
          </Typography>
          <Typography>
            E. Biometric information (not collected): Fingerprints and
            voiceprints
          </Typography>
          <Typography>
            F. Internet or other similar network activity (collected): Browsing
            history, search history, online behavior, interest data, and
            interactions with our and other websites, applications, systems, and
            advertisements
          </Typography>
          <Typography>
            G. Geolocation data (not collected): Device location
          </Typography>
          <Typography>
            H. Audio, electronic, visual, thermal, olfactory, or similar
            information (not collected): Images and audio, video or call
            recordings created in connection with our business activities.
          </Typography>
          <Typography>
            I. Professional or employment-related information (not collected):
            Business contact details in order to provide you our Services at a
            business level or job title, work history, and professional
            qualifications if you apply for a job with us.
          </Typography>
          <Typography>
            J. Education Information (not collected): Student records and
            directory information.
          </Typography>
          <Typography>
            K. Inferences drawn from other personal information (not collected):
            Inferences drawn from any of the collected personal information
            listed above to create a profile or summary about, for example, an
            individual's preferences and I characteristics.
          </Typography>
          <Typography>
            L. Sensitive Personal Information (not collected)
          </Typography>
        </Stack>
        <Typography>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - Category A: As long as the user has an account with us
          </Typography>
          <Typography>
            - Category B: As long as the user has an account with us
          </Typography>
          <Typography>
            - Category F: As long as the user has an account with us
          </Typography>
        </Stack>
        <Typography>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - Receiving help through our customer support channels;
          </Typography>
          <Typography>
            - Participation in customer surveys or contests; and
          </Typography>
          <Typography>
            - Facilitation in the delivery of our Services and to respond to
            your inquiries.
          </Typography>
        </Stack>
        <Typography variant="subtitle2">
          How do we use and share your personal information?
        </Typography>
        <Typography>
          More information about our data collection and sharing practices can
          be found in this privacy notice and our Cookie Notice:
          http://www.flowlylink.com/cookie-policy.
        </Typography>
        <Typography>
          You can opt out from the selling or sharing of your personal
          information by disabling cookies in Cookie Preference Settings and
          clicking on the Do Not Sell or Share My Personal Information link on
          our homepage.
        </Typography>
        <Typography>
          You may contact us by email at admin@flowlyapp.com, or by referring to
          the contact details at the bottom of this document.
        </Typography>
        <Typography>
          If you are using an authorized agent to exercise your right to opt out
          we may deny a request if the authorized agent does not submit proof
          that they have been validly authorized to act on your behalf.
        </Typography>
        <Typography variant="subtitle2">
          Will your information be shared with anyone else?
        </Typography>
        <Typography>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf, following the same strict privacy
          protection obligations mandated by the CCPA.
        </Typography>
        <Typography>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration This is not considered to be "selling"
          of your personal information.
        </Typography>
        <Typography>
          Flowly LLC has disclosed the following categories of personal
          information to third parties for a business or commercial purpose in
          the preceding twelve (12) months: Category F. Internet or other
          electronic network activity information, such as browsing history,
          search history, online behavior, interest data, and interactions with
          our and other websites, applications, systems, and advertisements.
        </Typography>
        <Typography>
          The categories of third parties to whom we disclosed personal
          information for a business or commercial purpose can be found under
          "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?".
        </Typography>
        <Typography>
          Flowly LLC has shared the following categories of personal information
          to third parties in the preceding twelve (12) months. The categories
          of third parties to whom we shared personal information with are: Data
          Analytics Services, User Account Registration & Authentication
          Services.
        </Typography>
        <Typography variant="subtitle2">
          Your rights with respect to your personal data
        </Typography>
        <Typography>
          Right to request deletion of the data - Request to delete
        </Typography>
        <Typography>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </Typography>
        <Typography>Right to be informed - Request to know</Typography>
        <Typography>
          Depending on the circumstances, you have a right to know:
        </Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - whether we collect and use your personal information;
          </Typography>
          <Typography>
            - the categories of personal information that we collect;
          </Typography>
          <Typography>
            - the purposes for which the collected personal information is used;
          </Typography>
          <Typography>
            - whether we sell or share personal information to third parties;
          </Typography>
          <Typography>
            - the categories of personal information that we sold, shared, or
            disclosed for a business purpose;
          </Typography>
          <Typography>
            - the categories of third parties to whom the personal information
            was sold, shared, or disclosed for a business purpose:
          </Typography>
          <Typography>
            - the business or commercial purpose for collecting, selling, or
            sharing personal information; and
          </Typography>
          <Typography>
            - the specific pieces of personal information we collected about
            you.
          </Typography>
        </Stack>
        <Typography>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re- identify individual data to verify a
          consumer request.
        </Typography>
        <Typography>
          Right to Non-Discrimination for the Exercise of a Consumer's Privacy
          Rights
        </Typography>
        <Typography>
          We will not discriminate against you if you exercise your privacy
          rights.
        </Typography>
        <Typography>
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </Typography>
        <Typography>
          We do not process consumer's sensitive personal information.
        </Typography>
        <Typography>Verification process</Typography>
        <Typography>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g., phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </Typography>
        <Typography>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </Typography>
        <Typography>Other privacy rights</Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - You may object to the processing of your personal information.
          </Typography>
          <Typography>
            - You may request correction of your personal data if it is
            incorrect or no longer relevant, or ask to restrict the processing
            of the information.
          </Typography>
          <Typography>
            - You can designate an authorized agent to make a request under the
            CPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
          </Typography>
        </Stack>
        <Typography>
          To exercise these rights, you can contact us by email at
          admin@flowlyapp.com, or by referring to the contact details at the
          bottom of this document. If you have a complaint about how we handle
          your data, we would like to hear from you.
        </Typography>

        <Typography variant="h5">
          12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Typography>
        <Typography>
          In Short: Yes, if you are a resident of Virginia, you may be granted
          specific rights regarding access to and use of your personal
          information.
        </Typography>
        <Typography variant="h6">Virginia CDPA Privacy Notice</Typography>
        <Typography>
          Under the Virginia Consumer Data Protection Act (CDPA):
        </Typography>
        <Typography>
          "Consumer" means a natural person who is a resident of the
          Commonwealth acting only in an individual or household context. It
          does not include a natural person acting in a commercial or employment
          context.
        </Typography>
        <Typography>
          "Personal data" means any information that is linked or reasonably
          linkable to an identified or identifiable natural person. "Personal
          data" does not include de- identified data or publicly available
          information.
        </Typography>
        <Typography>
          "Sale of personal data" means the exchange of personal data for
          monetary consideration.
        </Typography>
        <Typography>
          If this definition "consumer" applies to you, we must adhere to
          certain rights and obligations regarding your personal data.
        </Typography>
        <Typography>Your rights with respect to your personal data</Typography>
        <Stack sx={{ pl: 2 }}>
          <Typography>
            - Right to be informed whether or not we are processing your
            personal data
          </Typography>
          <Typography>- Right to access your personal data</Typography>
          <Typography>
            - Right to correct inaccuracies in your personal data{" "}
          </Typography>
          <Typography>
            - Right to request deletion of your personal data
          </Typography>
          <Typography>
            - Right to obtain a copy of the personal data you previously shared
            with us
          </Typography>
          <Typography>
            - Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
          </Typography>
        </Stack>
        <Typography>
          Flowly LLC has not sold any personal data to third parties for
          business or commercial purposes. Flowly LLC will not sell personal
          data in the future belonging to website visitors, users, and other
          consumers.
        </Typography>
        <Typography>
          Exercise your rights provided under the Virginia CDPA
        </Typography>
        <Typography>
          More information about our data collection and sharing practices can
          be found in this privacy notice and our Cookie Notice:
          http://www.flowlylink.com/cookie-policy.
        </Typography>
        <Typography>
          You may contact us by email at admin@flowlyapp.com.
        </Typography>
        <Typography>
          If you are using an authorized agent to exercise your rights, we may
          deny a request if the authorized agent does not submit proof that they
          have been validly authorized to act on your behalf.
        </Typography>
        <Typography>Verification process</Typography>
        <Typography>
          We may request that you provide additional information reasonably
          necessary to verify you and your consumer's request. If you submit the
          request through an authorized agent, we may need to collect additional
          information to verify your identity before processing your request.
        </Typography>
        <Typography>
          Upon receiving your request, we will respond without undue delay, but
          in all cases, within forty-five (45) days of receipt. The response
          period may be extended once by forty-five (45) additional days when
          reasonably necessary. We will inform you of any such extension within
          the initial 45-day response period, together with the reason for the
          extension
        </Typography>
        <Typography>Right to appeal</Typography>
        <Typography>
          If we decline to take action regarding your request, we will inform
          you of our decision and reasoning behind it. If you wish to appeal our
          decision, please email us at admin@flowlyapp.com. Within sixty (60)
          days of receipt of an appeal, we will inform you in writing of any
          action taken or not taken in response to the appeal, including a
          written explanation of the reasons for the decisions. If your appeal
          if denied, you may contact the Attorney General to submit a complaint.
        </Typography>

        <Typography variant="h5">
          13. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>
        <Typography>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </Typography>
        <Typography>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </Typography>

        <Typography variant="h5">
          14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>
        <Typography>
          If you have questions or comments about this notice, you may email us
          at admin@flowlyapp.com or contact us by post at: Flowly LLC, United
          States, admin@flowlyapp.com.
        </Typography>

        <Typography variant="h5">
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Typography>
        <Typography>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please contact
          admin@flowlyapp.com
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicy;
