import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import { TextField, Stack, Typography } from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { FormEditor } from "Components/BusinessView/FormEditor";
import { InfoBox } from "Components/Containers/InfoBox";

export const OrderDetail = ({
  formik,
}: {
  formik: FormikProps<BusinessInfo>;
}) => {
  return (
    <FormSection id="order-details" sectionName="Request details">
      <Stack spacing={3}>
        <FormEditor visible>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontWeight: 500, mb: 0.5 }}
          >
            Explain how your pricing works:
          </Typography>
          <TextField
            fullWidth
            multiline
            margin="dense"
            id="orderDetails"
            placeholder="E.g. Hand writing and designs with more details and colors will have higher price."
            value={formik.values.orderDetails}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // error={
            //   formik.touched.orderDetails && Boolean(formik.errors.orderDetails)
            // }
            // helperText={
            //   formik.touched.orderDetails && formik.errors.orderDetails
            // }
          />
        </FormEditor>
        {/* {formik.values.orderDetails && (
          <InfoBox>
            <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
              {formik.values.orderDetails}
            </Typography>
          </InfoBox>
        )} */}
        <TextField
          fullWidth
          required={false}
          disabled
          id="event"
          label="Event"
        />
        <TextField fullWidth disabled id="theme" label="Theme" />
        <TextField fullWidth disabled id="colors" label="Colors" />
        <TextField
          fullWidth
          multiline
          disabled
          id="personalization"
          label="Personalization/other requirements"
        />
      </Stack>
    </FormSection>
  );
};
