// ui related
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";

export const JoinEmail = () => {
  return (
    <FormSection id="joinEmail" sectionName="Join email list">
      <FormControlLabel
        disabled
        control={<Checkbox defaultChecked />}
        name="joinEmail"
        label="I would like to receive emails about future presales and events."
      />
    </FormSection>
  );
};
