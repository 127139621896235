// ui related
import {
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";

export const ContactInfo = () => {
  return (
    <FormSection id="contact" sectionName="Contact info">
      <Grid
        container
        columnSpacing={2}
        columns={{ xs: 4, sm: 12, md: 12 }}
        sx={{ mt: -1 }}
      >
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            required={false}
            disabled
            id="firstName"
            label="First name"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            required={false}
            disabled
            id="lastName"
            label="Last name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            margin="normal"
            required={false}
            disabled
            id="phoneNumber"
            label="Phone number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            margin="normal"
            required={false}
            disabled
            id="email"
            type="email"
            label="Email"
          />
        </Grid>
      </Grid>

      <FormControl disabled required fullWidth sx={{ mt: 1 }}>
        <FormLabel>Preferred contact method</FormLabel>
        <RadioGroup>
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={<Radio />}
              label="Email"
              checked={false}
            />
            <FormControlLabel
              control={<Radio />}
              label="Text"
              checked={false}
            />
          </Stack>
        </RadioGroup>
      </FormControl>
    </FormSection>
  );
};
