// ui related
import { LoadingButton } from "@mui/lab";
import { FormSection } from "Components/BusinessView/FormSection";

export const DesignInspirations = () => {
  return (
    <FormSection id="inspirations" sectionName="Design inspirations">
      <LoadingButton variant="outlined" component="label" disabled>
        Attach images
        <input hidden accept="image/*" multiple type="file" />
      </LoadingButton>
    </FormSection>
  );
};
