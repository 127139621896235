import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ui related
import {
  Box,
  Button,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Divider,
  ToggleButton,
} from "@mui/material";
import { HelpChip } from "Components/BusinessView/HelpChip";

// icons
import MenuIcon from "@mui/icons-material/Menu";
import AlignHorizontalRightRoundedIcon from "@mui/icons-material/AlignHorizontalRightRounded";

type Props = {
  required: boolean;
  drawerName: string;
  handleDrawerToggle: () => void;
  drawerState: boolean;
};

export const AppNav = ({
  required,
  drawerName,
  handleDrawerToggle,
  drawerState,
}: Props) => {
  const navigate = useNavigate();
  const getTabName = (name: string) => {
    switch (name) {
      // name needs to be same as drawername
      case "Home":
        return "/admin/home";
      case "Requests":
        return "/admin/requests";
      case "Orders":
        return "/admin/orders";
      case "Calendars":
        return "/admin/calendar";
      case "Presales":
        return "/admin/presales";
      default:
        return "/admin/requests";
    }
  };
  const [activeTab, setActiveTab] = React.useState(getTabName(drawerName));
  const handleClickTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    navigate(newValue);
  };

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar variant="dense">
        {/* mobile drawer button */}
        {required ? (
          <Box sx={{ alignItems: "center" }}>
            <Button
              // startIcon={<AlignHorizontalRightRoundedIcon />}
              variant={drawerState ? "contained" : "outlined"}
              onClick={handleDrawerToggle}
              color="secondary"
              sx={{
                pl: 2,
                display: { xs: "flex", md: "none" },
                width: "fit-content",
              }}
            >
              {drawerName}
            </Button>
          </Box>
        ) : null}

        {/* desktop tabs */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Tabs value={activeTab} onChange={handleClickTab}>
            <Tab
              label="Home"
              value="/admin/home"
              sx={{
                textTransform: "none",
                fontWeight: 600,
              }}
            />
            <Tab
              label="Custom orders"
              value="/admin/orders"
              sx={{
                textTransform: "none",
                fontWeight: 600,
              }}
            />
            <Tab
              label="Presales"
              value="/admin/presales"
              sx={{
                textTransform: "none",
                fontWeight: 600,
              }}
            />
          </Tabs>
        </Box>

        {/* mobile page name */}
        <Typography
          flexGrow={1}
          variant="button"
          sx={{
            textTransform: "none",
            opacity: { xs: required ? 0 : 1, md: 0 },
          }}
        >
          {drawerName}
        </Typography>

        {/* mobile menu icon */}
        <Box sx={{ ml: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton onClick={handleOpenNavMenu} color="inherit">
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/admin/home");
                handleCloseNavMenu();
              }}
            >
              <Typography>Home</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/admin/orders");
                handleCloseNavMenu();
              }}
            >
              <Typography>Custom orders</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/admin/presales");
                handleCloseNavMenu();
              }}
            >
              <Typography>Presales</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
