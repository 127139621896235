import React, { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography, Stack, Toolbar, AppBar } from "@mui/material";
import { SignInBtn } from "Components/AllComponents";
import Logo from "Assets/logo-full.svg";

const CookiePolicy = () => {
  const { user } = useSession();
  const navigate = useNavigate();

  return (
    <Stack
      spacing={3}
      sx={{
        p: { xs: 2, sm: 3 },
        backgroundColor: "background.default",
        minHeight: "100vh",
      }}
    >
      <AppBar>
        <Toolbar variant="dense">
          <a href="/" style={{ width: 24, height: 24, marginRight: 8 }}>
            <img src={Logo} alt="Logo" style={{ width: 24, height: 24 }} />
          </a>
          <Typography variant="subtitle2" color="text.primary" flex={1}>
            Cookie Policy
          </Typography>
          <Stack direction="row" spacing={1}>
            {user ? (
              <Button
                variant="outlined"
                onClick={async () => {
                  navigate("/admin/home");
                }}
              >
                Admin
              </Button>
            ) : (
              <SignInBtn variant="outlined" label="Sign in" username="" />
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      <Stack spacing={2} sx={{ pt: { xs: 2, sm: 3 } }}>
        <Typography variant="h3">Cookie Policy</Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Last updated May 22, 2023
        </Typography>
        <Typography>
          This Cookie Policy explains how Flowly LLC ("Company," "we," "us," and
          "our") uses cookies and similar technologies to recognize you when you
          visit our website at http://www.flowlylink.com ("Website"). It
          explains what these technologies are and why we use them, as well as
          your rights to control our use of them.
        </Typography>
        <Typography>
          In some cases we may use cookies to collect personal information, or
          that becomes personal information if we combine it with other
          information.
        </Typography>

        <Typography variant="h5">What are cookies?</Typography>
        <Typography>
          Cookies are small data files that are placed on your computer or
          mobile device when you visit a website. Cookies are widely used by
          website owners in order to make their websites work, or to work more
          efficiently, as well as to provide reporting information.
        </Typography>
        <Typography>
          Cookies set by the website owner (in this case, Flowly LLC) are called
          "first-party cookies." Cookies set by parties other than the website
          owner are called "third-party cookies." Third-party cookies enable
          third-party features or functionality to be provided on or through the
          website (e.g., advertising, interactive content, and analytics). The
          parties that set these third-party cookies can recognize your computer
          both when it visits the website in question and also when it visits
          certain other websites.
        </Typography>

        <Typography variant="h5">Why do we use cookies?</Typography>
        <Typography>
          We use first- and third-party cookies for several reasons. Some
          cookies are required for technical reasons in order for our Website to
          operate, and we refer to these as "essential" or "strictly necessary"
          cookies. Other cookies also enable us to track and target the
          interests of our users to enhance the experience on our Online
          Properties. Third parties serve cookies through our Website for
          advertising, analytics, and other purposes. This is described in more
          detail below.
        </Typography>

        <Typography variant="h5">How can I control cookies?</Typography>
        <Typography>
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie rights by setting your preferences in the
          Cookie Consent Manager. The Cookie Consent Manager allows you to
          select which categories of cookies you accept or reject. Essential
          cookies cannot be rejected as they are strictly necessary to provide
          you with services.
        </Typography>
        <Typography>
          The Cookie Consent Manager can be found in the notification banner and
          on our website. If you choose to reject cookies, you may still use our
          website though your access to some functionality and areas of our
          website may be restricted. You may also set or amend our web browser
          controls to accept or refuse cookies.
        </Typography>
        <Typography>
          The specific types of first- and third-party cookies served through
          our Website and the purposes they perform are described in the table
          below (please note that the specific cookies served mav vary dependina
          on the specific Online Properties you visit).
        </Typography>

        <Typography variant="h5">
          What about other tracking technologies, like web beacons?
        </Typography>
        <Typography>
          Cookies are not the only way to recognize or track visitors to a
          website. We may use other, similar technologies from time to time,
          like web beacons (sometimes called "tracking pixels" or "clear gifs").
          These are tiny graphics files that contain a unique identifier that
          enables us to recognize when someone has visited our Website or opened
          an email including them. This allows us, for example, to monitor the
          traffic patterns of users from one page within a website to another,
          to deliver or communicate with cookies, to understand whether you have
          come to the website from an online advertisement displayed on a
          third-party website, to improve site performance, and to measure the
          success of email marketing campaigns. In many instances, these
          technologies are reliant on cookies to function properly, and so
          declining cookies will impair their functioning.
        </Typography>

        <Typography variant="h5">
          Do you use Flash cookies or Local Shared Objects?
        </Typography>
        <Typography>
          Websites may also use so-called "Flash Cookies" (also known as Local
          Shared Objects or "LSOs") to, among other things, collect and store
          information about your use of our services, fraud prevention, and for
          other site operations.
        </Typography>
        <Typography>
          If you do not want Flash Cookies stored on your computer, you can
          adjust the settings of your Flash player to block Flash Cookies
          storage using the tools contained in the Website Storage Settings
          Panel. You can also control Flash Cookies by going to the Global
          Storage Settings Panel and following the instructions (which may
          include instructions that explain, for example, how to delete existing
          Flash Cookies (referred to "information" on the Macromedia site), how
          to prevent Flash LSOs from being placed on your computer without your
          being asked, and (for Flash Player 8 and later) how to block Flash
          Cookies that are not being delivered by the operator of the page you
          are on at the time).
        </Typography>
        <Typography>
          Please note that setting the Flash Player to restrict or limit
          acceptance of Flash Cookies may reduce or impede the functionality of
          some Flash applications, including, potentially, Flash applications
          used in connection with our services or online content.
        </Typography>

        <Typography variant="h5">Do you serve targeted advertising?</Typography>
        <Typography>
          Third parties may serve cookies on your computer or mobile device to
          serve advertising through our Website. These companies may use
          information about your visits to this and other websites in order to
          provide relevant advertisements about goods and services that you may
          be interested in. They may also employ technology that is used to
          measure the effectiveness of advertisements. They can accomplish this
          by using cookies or web beacons to collect information about your
          visits to this and other sites in order to provide relevant
          advertisements about goods and services of potential interest to you.
          The information collected through this process does not enable us or
          them to identify your name, contact details, or other details that
          directly identify you unless you choose to provide these.
        </Typography>

        <Typography variant="h5">
          How often will you update this Cookie Policy?
        </Typography>
        <Typography>
          We may update this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal, or requlatory reasons. Please therefore revisit
          this Cookie Policy regularly to stav informed about our use of cookies
          and related technologies.
        </Typography>
        <Typography>
          The date at the top of this Cookie Policy indicates when it was last
          updated
        </Typography>

        <Typography variant="h5">
          Where can I get further information?
        </Typography>
        <Typography>
          If you have any questions about our use of cookies or other
          technologies, please email us at admin@flowlyapp.com.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CookiePolicy;
