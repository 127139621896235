import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultPresaleInfo, PresaleInfo } from "Model/PresaleInfo";

export const selectedPresaleSlice = createSlice({
  name: "selectedPresale",
  initialState: defaultPresaleInfo(false, []),
  reducers: {
    selectPresale: (state, { payload }: PayloadAction<PresaleInfo>) => payload,
  },
});
