import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Chip,
  TextField,
  Link,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { CardStyle, ContentWrapNarrow } from "Components/AllComponents";
import { ContactInfo } from "Components/BusinessView/CustomOrder/ContactInfo";
import { OverviewGrid } from "Components/BusinessView/CustomOrder/OverviewGrid";
import { SubAppBar } from "Components/CustomerView/SubAppBar";
import { Header } from "Components/Header";
import { LabeledText } from "Components/LabeledText";

import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { ShipTo } from "Components/BusinessView/CustomOrder/ShipTo";
import { useLocation } from "react-router-dom";
import { Unit } from "Model/CustomerRequestInfo";
import { decodeRequestInfo } from "Util/encoding";
import { readBusinessInfo } from "Util/firebaseHelper";
import { OrderInfo } from "Model/OrderInfo";
import { toast } from "react-toastify";
import {
  ACCEPT_QUOTE_FUNCTION,
  QUOTE_FUNCTION,
  REQUEST_STATUS_ACCEPTED,
  REQUEST_STATUS_ORDERED,
  REQUEST_STATUS_QUOTED,
} from "Constants";
import { formatDateFromString } from "Util/dateUtil";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const RQODetails = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const query = new URLSearchParams(useLocation().search);
  const info = query.get("info");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [requestInfo, setRequestInfo] = useState<OrderInfo | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [sellerInfo, setSellerInfo] = useState({
    agreements: "",
    sellerName: "",
    sellerPhoto: "",
    username: "",
    businessName: "",
    sellerEmail: "",
  });
  const [verifying, setVerifying] = useState(false);
  const [accepting, setAccepting] = useState(false);

  const [acceptedQuote, setAcceptedQuote] = useState(false);

  const handlePhoneNumberSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setVerifying(true);
    if (info) {
      const decodedRequestInfo = decodeRequestInfo(info, phoneNumber);
      if (decodedRequestInfo) {
        try {
          if (decodedRequestInfo.formId) {
            const form = await readBusinessInfo(decodedRequestInfo.formId);
            if (form === null) {
            } else {
              let content = form.data();
              if (content) {
                setSellerInfo({
                  username: content.username,
                  sellerPhoto: content.sellerPhoto,
                  businessName: content.businessName,
                  sellerName: content.sellerName,
                  agreements: content.agreements || "",
                  sellerEmail: content.sellerEmail,
                });
              }
            }
          }

          const response = await fetch(
            `${QUOTE_FUNCTION}?formId=${decodedRequestInfo.formId}&requestId=${decodedRequestInfo.requestId}&phoneNumber=${phoneNumber}`
          );
          if (!response.ok) {
            setError(
              "The phone number entered does not match this quote. Please try a different number."
            );
          } else {
            const data = await response.json();
            setRequestInfo(data);
            setError(null);
          }
        } catch (err) {
          console.error("Error fetching quote:", err);
          setError("Failed to fetch the quote.");
        }
      }
    }
    setVerifying(false);
  };

  const [openContactSeller, setOpenContactSeller] = useState(false);
  const handleOpenContactSeller = () => {
    setOpenContactSeller(true);
  };
  const handleCloseContactSeller = () => {
    setOpenContactSeller(false);
  };

  if (!requestInfo) {
    return (
      // <form onSubmit={handlePhoneSubmit}>
      //   <label>
      //     Enter Phone Number:
      //     <input
      //       type="phone"
      //       value={phone}
      //       onChange={(e) => setPhone(e.target.value)}
      //       required
      //     />
      //   </label>
      //   <button type="submit">Submit</button>
      //   {error && <p>{error}</p>}
      // </form>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "background.default", minHeight: "100vh", p: 2 }}
      >
        <ContentWrapNarrow>
          <CardStyle sx={{ width: "400px" }}>
            <form onSubmit={handlePhoneNumberSubmit}>
              <Typography color="text.primary" variant="h5">
                Verify your phone number
              </Typography>
              <Typography color="text.primary" variant="body1" sx={{ mt: 1 }}>
                Please first verify your phone number to view quote details.
              </Typography>
              <TextField
                label="Customer phone number"
                type="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <LoadingButton
                variant="contained"
                loading={verifying}
                type="submit"
                sx={{ mt: 1 }}
              >
                Verify
              </LoadingButton>
              {error && <p>{error}</p>}
            </form>
          </CardStyle>
        </ContentWrapNarrow>
      </Stack>
    );
  }
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "background.default",
        p: { xs: 2, sm: 3 },
      }}
    >
      <SubAppBar
        sellerName={sellerInfo.sellerName}
        sellerPhoto={sellerInfo.sellerPhoto}
        username={sellerInfo.username}
        businessName={sellerInfo.businessName}
      />

      <ContentWrapNarrow>
        <Stack spacing={4} sx={{ pt: { xs: 6, sm: 7 } }}>
          <Stack direction="row" alignItems="center">
            <Header
              heading="Custom order"
              meta={"Requested on " + formatDateFromString(requestInfo.created)}
            />
            {requestInfo.status === REQUEST_STATUS_QUOTED && !acceptedQuote ? (
              <Chip
                size="medium"
                variant="outlined"
                label="Quote under review"
                color="default"
              />
            ) : requestInfo.status === REQUEST_STATUS_ACCEPTED ||
              acceptedQuote ? (
              <Chip
                size="medium"
                variant="filled"
                icon={<TaskAltRoundedIcon />}
                label="Quote accepted"
                color="success"
              />
            ) : (
              <Chip
                size="medium"
                variant="filled"
                label="Order in progress"
                color="success"
              />
            )}
          </Stack>
          <Stack spacing={2} divider={<Divider />}>
            <Box>
              <OverviewGrid
                name={requestInfo.firstName + " " + requestInfo.lastName}
                qty={
                  requestInfo.count +
                  (requestInfo.unit === Unit.Dozen ? " dozen" : " cookies")
                }
                date={formatDateFromString(requestInfo.customerDate)}
                fulfillment={requestInfo.customerFulfillment}
              />
            </Box>
            <Stack>
              <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
                Details
              </Typography>
              <Stack spacing={2}>
                {requestInfo.event ? (
                  <LabeledText label="Event" text={requestInfo.event} />
                ) : null}
                {requestInfo.theme ? (
                  <LabeledText label="Theme" text={requestInfo.theme} />
                ) : null}
                {requestInfo.colors ? (
                  <LabeledText label="Colors" text={requestInfo.colors} />
                ) : null}
                {requestInfo.personalization ? (
                  <LabeledText
                    label="Personalization/other requirements"
                    text={requestInfo.personalization}
                    keepTextFormatting
                  />
                ) : null}
                {requestInfo.flavors?.length > 0 ? (
                  <LabeledText label="Flavor" text={requestInfo.flavors[0]} />
                ) : null}
                {requestInfo.packagings?.length > 0 ? (
                  <LabeledText
                    label="Packaging"
                    text={requestInfo.packagings[0]}
                  />
                ) : null}
                {requestInfo.questions ? (
                  <LabeledText
                    label="Questions/comments"
                    text={requestInfo.questions}
                    keepTextFormatting
                  />
                ) : null}
              </Stack>
            </Stack>
            <ContactInfo
              phone={requestInfo.phoneNumber}
              email={requestInfo.email}
              preferredContact={requestInfo.preferredContact}
            />
            {requestInfo.customerFulfillment === "Pickup" ? null : (
              <ShipTo
                shippingAddress1={requestInfo.shippingAddress1}
                shippingAddress2={requestInfo.shippingAddress2}
                shippingCity={requestInfo.shippingCity}
                shippingState={requestInfo.shippingState}
                shippingZip={requestInfo.shippingZip}
              />
            )}
            <Stack>
              <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
                Agreements
              </Typography>
              <Typography variant="body1">{sellerInfo.agreements}</Typography>
            </Stack>
          </Stack>

          <CardStyle
            sx={{
              textAlign: "center",
            }}
          >
            <Stack spacing={2}>
              <Stack>
                <Typography variant="h5">Quote</Typography>
                {requestInfo?.status === "" ? (
                  <Typography variant="body2" color="text.secondary">
                    The seller has been notified of your request and will
                    contact you soon with a quote.
                  </Typography>
                ) : null}
                {requestInfo?.status === REQUEST_STATUS_QUOTED ||
                requestInfo?.status === REQUEST_STATUS_ACCEPTED ? (
                  <Typography variant="body2" color="text.secondary">
                    Created on {formatDateFromString(requestInfo.updated)}
                  </Typography>
                ) : null}
              </Stack>
              <Typography variant="h3">${requestInfo.orderTotal}</Typography>
              {requestInfo.notes === "" ? null : (
                <Typography sx={{ whiteSpace: "pre-line", mt: 1 }}>
                  {requestInfo.notes}
                </Typography>
              )}
              {requestInfo.status === REQUEST_STATUS_QUOTED &&
              !acceptedQuote ? (
                <LoadingButton
                  variant="contained"
                  loading={accepting}
                  sx={{ width: "fit-content", alignSelf: "center" }}
                  onClick={async () => {
                    setAccepting(true);
                    const response = await fetch(
                      `${ACCEPT_QUOTE_FUNCTION}?formId=${requestInfo.formId}&requestId=${requestInfo.requestId}&phoneNumber=${phoneNumber}`
                    );
                    if (response.status !== 200) {
                      setError(
                        "Failed to accept the quote with your phone number"
                      );
                      toast("Failed to accept the quote", {
                        position: "top-center",
                        autoClose: 1600,
                      });
                    } else {
                      setAcceptedQuote(true);
                      toast("Quote accepted successfully.", {
                        position: "top-center",
                        autoClose: 1600,
                      });
                    }
                    setAccepting(false);
                  }}
                >
                  Accept quote
                </LoadingButton>
              ) : requestInfo.status === REQUEST_STATUS_ACCEPTED ||
                acceptedQuote ? (
                <Stack spacing={1}>
                  <Button
                    variant="outlined"
                    disabled
                    startIcon={<TaskAltRoundedIcon />}
                    sx={{ width: "fit-content", alignSelf: "center" }}
                  >
                    Quote accepted
                  </Button>
                  <Typography variant="body2" color="text.secondary">
                    The seller has been notified and will follow up on the next
                    steps.
                  </Typography>
                </Stack>
              ) : null}

              {requestInfo.preferredContact === "Email" ? (
                <Typography>
                  Have questions?{" "}
                  <Link onClick={handleOpenContactSeller}>Email seller</Link>
                </Typography>
              ) : null}

              <Dialog
                fullWidth
                // fullScreen={fullScreen}
                open={openContactSeller}
                onClose={handleCloseContactSeller}
              >
                <DialogTitle>Email seller</DialogTitle>
                <DialogContent dividers>
                  <Typography>
                    Please send an email to{" "}
                    <Link
                      onClick={() => {
                        navigator.clipboard.writeText(sellerInfo.sellerEmail);
                        toast(`Email copied.`, {
                          position: "top-center",
                          autoClose: 800,
                        });
                      }}
                      sx={{ fontWeight: 600 }}
                    >
                      {sellerInfo.sellerEmail}
                    </Link>
                    . Make sure to include your name and the date needed to help
                    the seller identify your quote.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseContactSeller} color="secondary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </CardStyle>
        </Stack>
      </ContentWrapNarrow>
    </Box>
  );
};
