import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Typography, Stack } from "@mui/material";
import { CardStyle, ContentWrapNarrow } from "Components/AllComponents";

const Confirmation = () => {
  const state = useLocation().state as { businessName: string; email: string };

  useEffect(() => {
    document.title = state.businessName ?? "";
  }, []);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "background.default", minHeight: "100vh", p: 2 }}
    >
      <ContentWrapNarrow>
        <CardStyle>
          <Typography color="text.primary" variant="h5">
            I'll reach out soon!
          </Typography>
          <Typography color="text.primary" variant="body1" sx={{ mt: 1 }}>
            Thanks for your request. A copy of your request has been sent to{" "}
            <b>{state.email}</b>. If you can't find it, please check your spam
            folder.
          </Typography>
        </CardStyle>
      </ContentWrapNarrow>
    </Stack>
  );
};

export default Confirmation;
