import React, { useState } from "react";
import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { Profile } from "Model/Profile";
import { useSession } from "Context/userAuthContext";
import _, { set } from "lodash";
import { resizeFile } from "Util/helpers";
import { publishProfile, uploadImageGallery } from "Util/firebaseHelper";
import { nanoid } from "nanoid";
import { editSellerProfile } from "Redux/actionCreators";
import { useAppDispatch } from "Redux/hooks";
import { updateProfileAsync } from "Redux/Reducers/sellerProfile";

type Props = {
  children: React.ReactNode;
};

export const ManageGallery = ({ children }: Props) => {
  const { sellerProfile, user } = useSession();
  const totalImagesAllowed = 20;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openGallery, setopenGallery] = useState(false);
  const handleOpenGallery = () => {
    setopenGallery(true);
  };
  const handleCloseGallery = () => {
    setopenGallery(false);
  };

  const [uploadingImage, setUploadingImage] = useState(false);
  const [tempImageGallery, setTempImageGallery] = useState(
    sellerProfile.imageGallery
  );
  const dispatch = useAppDispatch();
  const [hideImageGallery, setHideImageGallery] = useState(
    sellerProfile.hideImageGallery
  );

  return (
    <div>
      <Stack direction="row" alignItems="center">
        <Box
          onClick={handleOpenGallery}
          sx={{
            width: "100%",
            "&:hover": { cursor: "pointer", opacity: 0.85 },
          }}
        >
          {children}
        </Box>
      </Stack>

      <Dialog
        open={openGallery}
        onClose={handleCloseGallery}
        fullScreen={fullScreen}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          Image gallery
          <Stack
            flexDirection="row"
            columnGap={1}
            alignItems="center"
            sx={{ mt: 1 }}
          >
            <LoadingButton
              variant="contained"
              component="label"
              loading={uploadingImage}
              disabled={totalImagesAllowed - tempImageGallery.length === 0}
            >
              Upload images
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  if (e.target.files) {
                    const currentImageCount = tempImageGallery.length;
                    let fileToUpload = e.target.files.length;
                    console.log("fileCounts", fileToUpload, currentImageCount);
                    if (fileToUpload + currentImageCount > totalImagesAllowed) {
                      fileToUpload = totalImagesAllowed - currentImageCount;
                      alert(`Will upload the first ${fileToUpload} files`);
                    }
                    setUploadingImage(true);
                    let imageGallery = _.cloneDeep(sellerProfile.imageGallery);
                    for (var i = 0; i < fileToUpload; i++) {
                      const image = e.target.files[i];
                      const resizedImage = await resizeFile(image, 800, 800);
                      var url = await uploadImageGallery(
                        resizedImage as File,
                        sellerProfile.uid,
                        nanoid(),
                        image.name
                      );
                      imageGallery.push(url);
                      console.log("uploaded image gallery photo", url);
                    }
                    setTempImageGallery(imageGallery);
                    if (user) {
                      dispatch(
                        updateProfileAsync({
                          uid: user?.uid,
                          sellerProfile: {
                            ...sellerProfile,
                            imageGallery: imageGallery,
                          },
                        })
                      );
                    }
                    setUploadingImage(false);
                  }
                }}
              />
            </LoadingButton>
            <Typography color="text.secondary">
              {totalImagesAllowed > tempImageGallery.length
                ? `Add up to ${
                    totalImagesAllowed - tempImageGallery.length
                  } more ${
                    totalImagesAllowed - tempImageGallery.length === 1
                      ? "image"
                      : "images"
                  }.`
                : "You have reached the limit of 20 images."}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <ImageList
            cols={fullScreen ? 3 : 6}
            rowHeight={fullScreen ? 164 : 200}
            gap={8}
            sx={{ mb: 0 }}
          >
            {tempImageGallery.map((item, i) => (
              <ImageListItem key={item}>
                <img
                  src={item}
                  srcSet={item}
                  loading="lazy"
                  style={{
                    objectFit: "contain",
                    height: 164,
                    borderRadius: "8px",
                  }}
                />
                <ImageListItemBar
                  sx={{
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                      onClick={() => {
                        setUploadingImage(true);
                        let imageGallery = _.cloneDeep(
                          sellerProfile.imageGallery
                        );
                        imageGallery.splice(i, 1);
                        setTempImageGallery(imageGallery);
                        if (user) {
                          dispatch(
                            updateProfileAsync({
                              uid: user?.uid,
                              sellerProfile: {
                                ...sellerProfile,
                                imageGallery: imageGallery,
                              },
                            })
                          );
                        }
                        setUploadingImage(false);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </DialogContent>
        <DialogActions>
          <Box flex={1}>
            <FormControlLabel
              sx={{ marginLeft: "8px" }}
              control={
                <Switch
                  checked={!hideImageGallery}
                  onChange={(e) => {
                    console.log("e", e.target.checked);
                    setHideImageGallery(!e.target.checked);
                    if (user) {
                      dispatch(
                        updateProfileAsync({
                          uid: user?.uid,
                          sellerProfile: {
                            ...sellerProfile,
                            hideImageGallery: !e.target.checked,
                          },
                        })
                      );
                    }
                  }}
                />
              }
              label="Display gallery"
            />
          </Box>
          <Button onClick={handleCloseGallery} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
