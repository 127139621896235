import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import { Typography, TextField, Stack } from "@mui/material";
import { InfoBox } from "Components/Containers/InfoBox";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerOrderDetail = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  return (
    <FormSection id="order-details" sectionName="Request details">
      {businessInfo.orderDetails && (
        <InfoBox sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
            {businessInfo.orderDetails}
          </Typography>
        </InfoBox>
      )}
      <Stack spacing={3}>
        <TextField
          fullWidth
          required
          id="event"
          label="Event"
          value={customerFormik.values.event}
          onChange={customerFormik.handleChange}
          onBlur={customerFormik.handleBlur}
          error={
            customerFormik.touched.event && Boolean(customerFormik.errors.event)
          }
          helperText={
            customerFormik.touched.event && customerFormik.errors.event
          }
        />
        <TextField
          fullWidth
          id="theme"
          label="Theme"
          value={customerFormik.values.theme}
          onChange={customerFormik.handleChange}
          onBlur={customerFormik.handleBlur}
        />
        <TextField
          fullWidth
          id="colors"
          label="Colors"
          value={customerFormik.values.colors}
          onChange={customerFormik.handleChange}
          onBlur={customerFormik.handleBlur}
        />
        <TextField
          fullWidth
          multiline
          id="personalization"
          label="Personalization/other requirements"
          value={customerFormik.values.personalization}
          onChange={customerFormik.handleChange}
          onBlur={customerFormik.handleBlur}
        />
      </Stack>
    </FormSection>
  );
};
