import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// ui related
import {
  Typography,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Divider,
  Box,
} from "@mui/material";

//icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import Person2RoundedIcon from "@mui/icons-material/PersonRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import InterestsRoundedIcon from "@mui/icons-material/InterestsRounded";
import DesignServicesRoundedIcon from "@mui/icons-material/DesignServicesRounded";
import { HelpChip } from "Components/BusinessView/HelpChip";

type DrawerProps = {
  onSelected: (pageName: string) => void;
};

export const HubDrawer = ({ onSelected }: DrawerProps) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(currentPath);

  useEffect(() => {
    setSelectedPage(currentPath);
  }, [currentPath]);

  return (
    <List component="nav" disablePadding sx={{ height: "100%" }}>
      <ListItemButton
        selected={selectedPage.endsWith("/admin/home")}
        onClick={() => {
          setSelectedPage("/admin/home");
          navigate("/admin/home");
          if (onSelected) {
            onSelected("/admin/home");
          }
        }}
      >
        <ListItemIcon>
          <DesignServicesRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography flex={1} variant="body2" sx={{ fontWeight: 600 }}>
              Editor
            </Typography>
          }
        />
      </ListItemButton>
      <ListItemButton
        selected={selectedPage === "/admin/customers"}
        onClick={() => {
          setSelectedPage("/admin/customers");
          navigate("/admin/customers");
          if (onSelected) {
            onSelected("/admin/customers");
          }
        }}
      >
        <ListItemIcon>
          <PeopleAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography flex={1} variant="body2" sx={{ fontWeight: 600 }}>
              Customers
            </Typography>
          }
        />
      </ListItemButton>

      <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        <ListItemButton
          onClick={() => {
            if (onSelected) {
              onSelected("businessprofile");
            }
          }}
        >
          <ListItemIcon>
            <StoreRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography flex={1} variant="body2" sx={{ fontWeight: 600 }}>
                Business profile
              </Typography>
            }
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            //setSelectedPage("account");
            if (onSelected) {
              onSelected("account");
            }
          }}
        >
          <ListItemIcon>
            <AccountCircleRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography flex={1} variant="body2" sx={{ fontWeight: 600 }}>
                Account
              </Typography>
            }
          />
        </ListItemButton>
        <HelpChip />
      </Box>
    </List>
  );
};
