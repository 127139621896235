import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";

import BlockIcon from "@mui/icons-material/Block";
import { useAppDispatch } from "Redux/hooks";
import { updateProfileAsync } from "Redux/Reducers/sellerProfile";
import { useSession } from "Context/userAuthContext";
import _ from "lodash";

type CalCellProps = {
  dateFullString: string;
  date: number;
  currentMonth?: boolean;
  today?: boolean;
  children: React.ReactNode;
};

export const CalCell = ({
  dateFullString,
  date,
  currentMonth,
  today,
  children,
}: CalCellProps) => {
  const { user, sellerProfile } = useSession();
  const dispatch = useAppDispatch();
  const [blocked, setBlocked] = useState(
    new Date(dateFullString) < new Date() ||
      sellerProfile.availability.dateBlocked.findIndex(
        (d) => d === dateFullString
      ) !== -1
  );

  useEffect(() => {
    setBlocked(
      new Date(dateFullString) < new Date() ||
        sellerProfile.availability.dateBlocked.findIndex(
          (d) => d === dateFullString
        ) !== -1
    );
  }, [sellerProfile.availability.dateBlocked]);

  const toggleBlocked = () => {
    setBlocked(!blocked);
    let dateBlockedCopy = _.clone(sellerProfile.availability.dateBlocked);
    const currentDate = new Date();
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(currentDate.getDate() - 2);

    // only keep last 2 days
    dateBlockedCopy = dateBlockedCopy.filter((d) => {
      const blockedDate = new Date(d);
      return blockedDate >= twoDaysAgo;
    });

    if (blocked) {
      toast(`${dateFullString} was unblocked from your availability.`, {
        position: "top-center",
        autoClose: 1600,
      });
      dateBlockedCopy = dateBlockedCopy.filter((d) => d !== dateFullString);
    } else {
      toast(`${dateFullString} was blocked from your availability.`, {
        position: "top-center",
        autoClose: 1600,
      });
      dateBlockedCopy.push(dateFullString);
    }
    if (user) {
      dispatch(
        updateProfileAsync({
          uid: user?.uid,
          sellerProfile: {
            ...sellerProfile,
            availability: {
              ...sellerProfile.availability,
              dateBlocked: dateBlockedCopy,
            },
          },
        })
      );
    }
  };

  return (
    <Grid
      item
      xs={12 / 7}
      sx={{
        "&:hover .blockedIndicator": {
          transition: "opacity .5s ease",
          opacity: 1,
        },
        "&:hover": {
          transition: "opacity .5s ease",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 10px 0px",
          border: ".5px solid rgba(0, 0, 0, 0)",
          zIndex: 10,
          borderRadius: 1,
        },
        border: ".5px solid rgba(0, 0, 0, 0.12)",
        minHeight: "100px",
        p: 1,
        backgroundColor: currentMonth ? "white" : "background.default",
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
        <Box flex={1}>
          {today ? (
            <Box
              sx={{
                backgroundColor: "primary.main",
                width: "24px",
                height: "24px",
                textAlign: "center",
                borderRadius: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  transform: "translateY(11%)",
                  color: "white",
                  fontWeight: 500,
                }}
              >
                {date}
              </Typography>
            </Box>
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: "text.primary",
                opacity: currentMonth ? 1 : 0.5,
              }}
            >
              {date}
            </Typography>
          )}
        </Box>
        <Stack alignItems="center" flexDirection="row" columnGap={0.5}>
          <Box className="blockedIndicator" sx={{ opacity: 0 }}>
            {blocked ? null : (
              <Button
                size="small"
                color="secondary"
                onClick={toggleBlocked}
                disabled={new Date(dateFullString) < new Date()}
                sx={{
                  height: "25.75px",
                  transition: "opacity .5s ease",
                  opacity: new Date(dateFullString) < new Date() ? 0 : 0.3,
                  "&:hover": {
                    opacity: new Date(dateFullString) < new Date() ? 0 : 1,
                  },
                }}
              >
                {blocked ? "Unblock" : "Block this day"}
              </Button>
            )}
          </Box>
          <IconButton
            size="small"
            disabled={new Date(dateFullString) < new Date()}
            onClick={toggleBlocked}
            sx={{
              height: "25.75px",
              transition: "opacity .5s ease",
              opacity: new Date(dateFullString) < new Date() ? 0.4 : 0.8,
              display: blocked ? "block" : "none",
              "&:hover .showLabel": { opacity: 1 },
            }}
          >
            <BlockIcon fontSize="inherit" color="secondary" />
          </IconButton>
        </Stack>
      </Stack>
      <Stack spacing={1}>{children}</Stack>
    </Grid>
  );
};
