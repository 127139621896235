import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ORDERS_COLLECTION_NAME, ORDERS_TABLE_NAME } from "Constants";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { OrderInfo } from "Model/OrderInfo";
import { db, deleteOrder } from "Util/firebaseHelper";

const initialState: OrderInfo[] = [];

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    create: (state, action: PayloadAction<OrderInfo>) => {
      state.push(action.payload);
    },
    edit: (state, action: PayloadAction<OrderInfo>) => {
      const orderToEditIndex = state.findIndex(
        (order) => order.orderId === action.payload.orderId
      );
      if (orderToEditIndex !== -1) {
        state[orderToEditIndex] = action.payload;
      }
    },
    updateOrders: (state, action: PayloadAction<OrderInfo[]>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        deleteOrderAsync.fulfilled,
        (state, action: PayloadAction<{ orderId: string }>) => {
          const { orderId } = action.payload;
          state.filter((o) => o.orderId !== orderId);
        }
      )
      .addCase(deleteOrderAsync.rejected, (state, action) => {
        console.log(action.error.message ?? "Failed to delete request");
      });
  },
});

export const fetchOrdersAsync = createAsyncThunk(
  "orders/fetchOrdersAsync",
  async (userId: string, thunkAPI) => {
    const ordersRef = query(
      collection(db, ORDERS_TABLE_NAME, userId, ORDERS_COLLECTION_NAME),
      orderBy("created", "desc")
    );
    onSnapshot(ordersRef, (querySnapshot) => {
      const orders = querySnapshot.empty
        ? []
        : querySnapshot.docs.map((doc) => {
            let order = doc.data() as OrderInfo;

            if (doc.data().customerDate instanceof Timestamp) {
              order.customerDate = doc
                .data()
                .customerDate.toDate()
                .toISOString();
            }
            if (doc.data().created instanceof Timestamp) {
              order.created = doc.data().created.toDate().toISOString();
            }
            if (doc.data().orderCreated instanceof Timestamp) {
              order.orderCreated = doc
                .data()
                .orderCreated.toDate()
                .toISOString();
            }
            if (doc.data().requestCreated instanceof Timestamp) {
              order.requestCreated = doc
                .data()
                .requestCreated.toDate()
                .toISOString();
            }
            if (doc.data().updated instanceof Timestamp) {
              order.updated = doc.data().updated.toDate().toISOString();
            }
            // if (!doc.data().paid) {
            //   order.paid = 0;
            // }
            // if (!doc.data().orderTotal) {
            //   order.orderTotal = 0;
            // }

            return order;
          });
      thunkAPI.dispatch(ordersSlice.actions.updateOrders(orders));
    });
  }
);

// Async thunk to add a presale order to Firebase
export const deleteOrderAsync = createAsyncThunk(
  "orders/deleteOrderAsync",
  async ({ uid, orderId }: { uid: string; orderId: string }) => {
    await deleteOrder(uid, orderId);
    return { orderId };
  }
);
