import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { businessInfoSelector, sellerProfileSelector } from "Redux/selectors";
import { useFormik } from "formik";
import { defaultOrderInfo, orderInfoValidationSchema } from "Model/OrderInfo";
import { submitNewOrderTransaction } from "Util/firebaseHelper";

import {
  Button,
  Box,
  Typography,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  Stack,
} from "@mui/material";
import { NewOrderInfo } from "Pages/BusinessView/AllOrders/Components/NewOrderInfo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSession } from "Context/userAuthContext";
import { InfoBox } from "Components/AllComponents";

import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";

type Props = {
  onClickAction: () => void;
};

export const ManuallyCreateOrder = ({ onClickAction }: Props) => {
  const { user, sellerProfile } = useSession();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const businessInfo = useAppSelector(businessInfoSelector);

  const [openNewOrder, setOpenNewOrder] = useState(false);

  const handleOpenNewOrder = () => {
    setOpenNewOrder(true);
  };

  const handleCloseNewOrder = () => {
    setOpenNewOrder(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...defaultOrderInfo,
      event: "Manually created order",
      paymentChecked: true,
    },
    validationSchema: orderInfoValidationSchema(
      sellerProfile.payments.length > 0
    ),
    onSubmit: async (values, { resetForm }) => {
      if (user?.uid) {
        submitNewOrderTransaction(
          user.uid,
          businessInfo.businessInfo.id,
          values,
          false,
          sellerProfile
        );
        setOpenNewOrder(false);
        resetForm();
      }
    },
  });

  return (
    <div>
      <MenuItem
        onClick={() => {
          handleOpenNewOrder();
          onClickAction();
        }}
      >
        <Typography textAlign="center">Create order</Typography>
      </MenuItem>
      <Dialog
        open={openNewOrder}
        onClose={handleCloseNewOrder}
        fullScreen={fullScreen}
        fullWidth={true}
      >
        <DialogTitle>New order</DialogTitle>
        <DialogContent dividers>
          <InfoBox sx={{ mb: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <ForwardToInboxRoundedIcon />
              <Typography>
                Once the order is created, a confirmation email will be sent to
                your customer.
              </Typography>
            </Stack>
          </InfoBox>
          <NewOrderInfo formik={formik} sellerProfile={sellerProfile} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenNewOrder(false);
              formik.resetForm();
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              console.log("formmmm1");
              formik.submitForm();
            }}
            variant="contained"
          >
            Create order
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
