import { Typography, LinearProgress, Stack } from "@mui/material";
import { InfoBox } from "Components/Containers/InfoBox";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

type Props = {
  total: number;
  paid: number;
};

export const TrackPayment = ({ total, paid }: Props) => {
  return (
    <InfoBox>
      <Typography variant="body2" color="text.secondary">
        Order total
      </Typography>
      <Stack direction="row" alignItems="baseline" sx={{ mb: 0.5 }}>
        <Typography variant="h6" flex={1}>
          ${total}
        </Typography>
        {total - paid > 0 ? (
          <Typography variant="body2" color="text.secondary">
            Paid: ${paid} |{" "}
            <span style={{ fontWeight: 600 }}>Balance: ${total - paid}</span>
          </Typography>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Fully paid
            </Typography>
            <CheckCircleRoundedIcon color="success" fontSize="small" />
          </Stack>
        )}
      </Stack>
      <LinearProgress
        color="primary"
        variant="determinate"
        value={(paid / total) * 100}
        sx={{ height: 6, borderRadius: 2 }}
      />
    </InfoBox>
  );
};
