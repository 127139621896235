import { useState } from "react";
import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";
import { resizeFile } from "Util/helpers";
import _ from "lodash";
import {
  uploadDesignInspiration,
  deleteDesignInspiration,
} from "Util/firebaseHelper";
import { nanoid } from "nanoid";

// ui related
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

// icon
import DeleteIcon from "@mui/icons-material/Delete";

export const CustomerDesignInspirations = ({
  businessInfo,
  customerFormik,
  sample,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
  sample: boolean;
}) => {
  const [uploadingImage, setUploadingImage] = useState(false);
  const [userUploadedPhotos, setUserUploadedPhotos] = useState<string[]>([]);

  return (
    <FormSection id="inspirations" sectionName="Design inspirations">
      <LoadingButton
        variant="outlined"
        component="label"
        loading={uploadingImage}
      >
        Upload images
        <input
          hidden
          accept="image/*"
          multiple
          type="file"
          onChange={async (e) => {
            // e.preventDefault();
            setUploadingImage(true);
            customerFormik.setSubmitting(true);
            if (e.target.files && e.target.files.length > 0) {
              let tempUserUplaodedPhotos = _.cloneDeep(userUploadedPhotos);
              let tempDesignInspirations = _.cloneDeep(
                customerFormik.values.designInspirations
              );
              for (var i = 0; i < e.target.files.length; i++) {
                const image = e.target.files[i];
                const resizedImage = await resizeFile(image, 800, 800);
                if (!sample) {
                  var url = await uploadDesignInspiration(
                    resizedImage as File,
                    businessInfo.id,
                    nanoid(),
                    image.name
                  );
                  tempDesignInspirations.push(url);
                  console.log("uploaded design inspiration photo", url);
                }
                tempUserUplaodedPhotos.push(URL.createObjectURL(image));
              }
              customerFormik.setFieldValue(
                "designInspirations",
                tempDesignInspirations
              );
              setUserUploadedPhotos(tempUserUplaodedPhotos);
            }
            setUploadingImage(false);
            customerFormik.setSubmitting(false);
          }}
        />
      </LoadingButton>
      <ImageList cols={3} rowHeight={164} gap={8} sx={{ mb: 0 }}>
        {userUploadedPhotos.map((item, i) => (
          <ImageListItem key={item}>
            <img
              src={item}
              srcSet={item}
              loading="lazy"
              style={{ objectFit: "cover", height: 164, borderRadius: "8px" }}
            />
            <ImageListItemBar
              sx={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                  onClick={() => {
                    let tempUserUplaodedPhotos =
                      _.cloneDeep(userUploadedPhotos);
                    let tempDesignInspirations = _.cloneDeep(
                      customerFormik.values.designInspirations
                    );
                    if (!sample) {
                      deleteDesignInspiration(
                        customerFormik.values.designInspirations[i]
                      );
                    }
                    tempDesignInspirations.splice(i, 1);
                    tempUserUplaodedPhotos.splice(i, 1);
                    customerFormik.setFieldValue(
                      "designInspirations",
                      tempDesignInspirations
                    );
                    setUserUploadedPhotos(tempUserUplaodedPhotos);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </FormSection>
  );
};
