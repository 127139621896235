import { useSession } from "Context/userAuthContext";
import { useEffect, useState } from "react";
import _, { set } from "lodash";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectedPresaleSelector } from "Redux/selectors";
import {
  PresaleOrder,
  calculateOrdersTotalPrice,
  calculateTotalItemsSold,
  aggregateOrders,
} from "Model/PresaleOrder";
import { stringToFulfillment } from "Model/PresaleInfo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { sendNotificationEmail } from "Util/firebaseHelper";
import {
  PRESALE_STATUS_NOTPUBLISHED,
  PRESALE_STATUS_PUBLISHED,
} from "Constants";
import moment, { Moment } from "moment";

// ui related
import {
  Button,
  Typography,
  Stack,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  FormGroup,
  Chip,
  IconButton,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Alert,
  Select,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import { CardStyle, InfoBox } from "Components/AllComponents";
import { PresaleOrderItems } from "./PresaleOrderItems";
import { modifyPresaleOrder } from "Redux/Reducers/presaleOrders";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// icons
import { PresaleOrderInfo } from "./PresaleOrderInfo";
import { LabeledText } from "Components/LabeledText";

export const PresaleOrdersByDate = ({
  defaultPickupAddress,
  presaleId,
  fulfillmentStr,
  presaleOrders,
}: {
  defaultPickupAddress: string;
  presaleId: string;
  fulfillmentStr: string;
  presaleOrders: PresaleOrder[];
}) => {
  const { sellerProfile } = useSession();
  const [sortBy, setSortBy] = useState<string>("title");
  const aggregatedOrders = aggregateOrders(presaleOrders, sortBy);
  const presale = useAppSelector(selectedPresaleSelector);
  const [openItems, setOpenItems] = useState(false);
  const handleOpenItems = () => {
    setOpenItems(true);
  };
  const handleCloseItems = () => {
    setOpenItems(false);
  };

  const [openEmail, setOpenEmail] = useState(false);
  const handleOpenEmail = () => {
    setOpenEmail(true);
    setEmailTopic("pickupInstruction");
    setEmailHeading("Your order is ready. See you soon!");
    setEmailPrimary(
      "The items in your order will be available for pickup on " +
        fulfillment.date +
        " " +
        fulfillment.range
    );
    setEmailBody("Address: " + fulfillment.address);
  };
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };
  const [emailTopic, setEmailTopic] = useState("pickupInstruction");

  const [anchorElTemplateMenu, setAnchorElTemplateMenu] =
    useState<null | HTMLElement>(null);
  const openTemplateMenu = Boolean(anchorElTemplateMenu);
  const handleClickTemplateMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElTemplateMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElTemplateMenu(null);
  };

  const fulfillment = stringToFulfillment(fulfillmentStr);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();
  // Initialize the state
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  // Handle checkbox changes
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailHeading, setEmailHeading] = useState("");
  const [emailPrimary, setEmailPrimary] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [ccSeller, setCcSeller] = useState(false);
  useEffect(() => {
    // Initialize checkedItems based on presaleOrders
    const initialCheckedItems =
      emailTopic === "pickupInstruction"
        ? presaleOrders.reduce((acc: Record<string, boolean>, order) => {
            acc[order.orderId] = order.paid && !order.emailSent;
            return acc;
          }, {})
        : emailTopic === "paymentReminder"
        ? presaleOrders.reduce((acc: Record<string, boolean>, order) => {
            acc[order.orderId] = !order.paid && !order.emailSentPayment;
            return acc;
          }, {})
        : presaleOrders.reduce((acc: Record<string, boolean>, order) => {
            acc[order.orderId] = true;
            return acc;
          }, {});
    setCheckedItems(initialCheckedItems);
  }, [presaleOrders, emailTopic]);

  const shouldShowSelectAllButton = () => {
    if (emailTopic === "pickupInstruction") {
      // If emailTopic is pickup instruction, only consider orders that have not been sent an email and have been paid
      const candidates = presaleOrders.filter(
        (order) => !order.emailSent && order.paid
      );
      // Return true if there is a candidate that is not selected
      return candidates.some((order) => !checkedItems[order.orderId]);
    } else if (emailTopic === "paymentReminder") {
      const candidates = presaleOrders.filter(
        (order) => !order.emailSentPayment && !order.paid
      );
      return candidates.some((order) => !checkedItems[order.orderId]);
    } else {
      // If emailTopic is other, all orders are candidates
      // Return true if there is an order that is not selected
      return presaleOrders.some((order) => !checkedItems[order.orderId]);
    }
  };

  const shouldShowDeselectAllButton = () => {
    if (emailTopic === "pickupInstruction") {
      const candidates = presaleOrders.filter(
        (order) => !order.emailSent && order.paid
      );
      if (candidates.length === 0) {
        return false;
      }
      return candidates.every((order) => checkedItems[order.orderId]);
    } else if (emailTopic === "paymentReminder") {
      const candidates = presaleOrders.filter(
        (order) => !order.emailSentPayment && !order.paid
      );
      if (candidates.length === 0) {
        return false;
      }
      return candidates.every((order) => checkedItems[order.orderId]);
    } else {
      if (presaleOrders.length === 0) {
        return false;
      }
      return presaleOrders.every((order) => checkedItems[order.orderId]);
    }
  };

  return (
    <Stack>
      <CardStyle>
        <Stack spacing={2}>
          {/* ------- overview ------- */}
          <Stack spacing={2}>
            <Stack flexDirection="row">
              <Stack spacing={0.5} flex={1}>
                <Typography variant="h6">
                  {fulfillment.date + " " + fulfillment.range}
                </Typography>
                <Typography variant="body1">{fulfillment.address}</Typography>
              </Stack>
              {presale.status !== PRESALE_STATUS_NOTPUBLISHED ? (
                <IconButton
                  sx={{ height: "fit-content" }}
                  onClick={handleOpenEmail}
                >
                  <EmailOutlinedIcon />
                </IconButton>
              ) : null}
            </Stack>
            <Stack>
              <Grid container>
                <Grid item xs={4}>
                  <LabeledText
                    label="Orders"
                    text={presaleOrders.length.toString()}
                    bold
                  />
                </Grid>
                <Grid item xs={4}>
                  <Stack>
                    <Typography variant="body2" color="text.secondary">
                      Items
                    </Typography>
                    <Typography variant="subtitle2">
                      {calculateTotalItemsSold(presaleOrders)} (
                      <Link
                        sx={{ textDecoration: "none" }}
                        onClick={handleOpenItems}
                      >
                        details
                      </Link>
                      )
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <LabeledText
                    label="Revenue"
                    text={"$" + calculateOrdersTotalPrice(presaleOrders)}
                    bold
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <Divider />

          {/* ------- orders ------- */}
          <List disablePadding dense>
            {presaleOrders.map((o, index) => (
              <PresaleOrderInfo order={o} key={o.orderId} />
            ))}
          </List>

          {/* ------- items ------- */}
          <Dialog
            open={openItems}
            onClose={handleCloseItems}
            fullWidth
            fullScreen={fullScreen}
          >
            <DialogTitle>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Stack>
                  Items
                  <Typography variant="body2" color="text.secondary">
                    Pickup on {fulfillment.date + " " + fulfillment.range}
                  </Typography>
                </Stack>
                <Stack
                  flex={1}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  columnGap={1}
                >
                  <Typography variant="body2">Sort by</Typography>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={sortBy}
                    exclusive
                    defaultValue="title"
                    onChange={(
                      event: React.MouseEvent<HTMLElement>,
                      newSort
                    ) => {
                      setSortBy(newSort);
                    }}
                  >
                    <ToggleButton value="title">Item</ToggleButton>
                    <ToggleButton value="flavor">Flavor</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent dividers>
              <Stack spacing={2} divider={<Divider />}>
                {aggregatedOrders.map((o, index) =>
                  o.count === 0 ? null : (
                    <PresaleOrderItems itemInfo={o} key={o.aggregatedOrderId} />
                  )
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseItems}>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* ------- email customers ------- */}
          <Dialog
            open={openEmail}
            onClose={(event, reason) => {
              if (
                // reason !== "backdropClick" &&
                !emailBody.trim() &&
                !emailPrimary.trim() &&
                !emailHeading.trim()
              ) {
                handleCloseEmail();
              }
            }}
            disableEscapeKeyDown
            fullWidth
            fullScreen={fullScreen}
          >
            <DialogTitle>
              Email customers
              <Typography variant="body2" color="text.secondary">
                Pickup on {fulfillment.date + " " + fulfillment.range}
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <FormControl size="small" fullWidth>
                <FormLabel sx={{ mb: 1 }}>Topic</FormLabel>
                <Select
                  value={emailTopic}
                  defaultValue="pickupInstruction"
                  onChange={(e) => {
                    setEmailTopic(e.target.value);
                    setCcSeller(true);
                    if (e.target.value === "pickupInstruction") {
                      setEmailHeading("Your order is ready. See you soon!");
                      setEmailPrimary(
                        "The items in your order will be available for pickup on " +
                          fulfillment.date +
                          " " +
                          fulfillment.range
                      );
                      setEmailBody("Address: " + fulfillment.address);
                    } else if (e.target.value === "paymentReminder") {
                      setEmailHeading("Friendly reminder: payment due");
                      setEmailPrimary(
                        "Payment for " +
                          presale.presaleName +
                          " is due by " +
                          moment(fulfillment.date)
                            .subtract(7, "days")
                            .format("MM/DD/YYYY") +
                          "."
                      );
                      setEmailBody(
                        "Please note that failure to make payment by this date will result in the cancellation of your order. Please follow below instruction to complete your payment."
                      );
                    } else {
                      setEmailHeading("");
                      setEmailPrimary("");
                      setEmailBody("");
                    }
                  }}
                >
                  <MenuItem value="pickupInstruction">
                    Pickup instruction
                  </MenuItem>
                  <MenuItem value="paymentReminder">Payment reminder</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              <Stack spacing={1} sx={{ mt: 2, mb: 2 }}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  columnGap={1}
                  flex={1}
                >
                  <Typography color="text.secondary" flex={1}>
                    Draft
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={countdown > 0} // Disable the button during the countdown
                    onClick={async () => {
                      setSendingEmail(true);
                      await sendNotificationEmail(
                        presaleOrders[0],
                        sellerProfile,
                        emailTopic === "pickupInstruction"
                          ? "[TEST] Your order is ready"
                          : emailTopic === "paymentReminder"
                          ? "[TEST] Presale payment reminder"
                          : "[TEST] Presale order information",
                        emailHeading,
                        emailPrimary,
                        emailBody.replace(/\n/g, "<br>"),
                        true,
                        emailTopic,
                        ccSeller
                      );
                      setSendingEmail(false);
                      toast("Email sent.", {
                        position: "top-center",
                        autoClose: 800,
                      });
                      // Start the countdown
                      setCountdown(20);
                      const countdownInterval = setInterval(() => {
                        setCountdown((prevCountdown) => prevCountdown - 1);
                      }, 1000);

                      // Stop the countdown after 20 seconds
                      setTimeout(() => {
                        clearInterval(countdownInterval);
                        setCountdown(0);
                      }, 20000);
                    }}
                  >
                    {countdown > 0
                      ? `Resend (${countdown}s)`
                      : "Send test email"}
                  </Button>
                </Stack>
                <TextField
                  variant="filled"
                  label="Heading"
                  id="emailHeading"
                  fullWidth
                  multiline
                  sx={{
                    "& .MuiInputBase-input": {
                      fontWeight: 600,
                      fontSize: "20px",
                    },
                  }}
                  value={emailHeading}
                  onChange={(e) => {
                    setEmailHeading(e.target.value);
                  }}
                />
                <TextField
                  variant="filled"
                  label="Primary message"
                  id="emailPrimary"
                  fullWidth
                  multiline
                  sx={{
                    whiteSpace: "pre-wrap",
                    "& .MuiInputBase-input": {
                      fontWeight: 500,
                    },
                  }}
                  value={emailPrimary}
                  onChange={(e) => {
                    setEmailPrimary(e.target.value);
                  }}
                />
                <TextField
                  variant="filled"
                  label="Body"
                  fullWidth
                  multiline
                  required
                  id="emailBody"
                  error={!emailBody.trim()}
                  helperText={
                    !emailBody.trim()
                      ? "Email body cannot be empty"
                      : emailTopic === "paymentReminder"
                      ? "The email will contain remaining balance and instructions for the selected payment method."
                      : ""
                  }
                  sx={{ whiteSpace: "pre-wrap" }}
                  value={emailBody}
                  onChange={(e) => {
                    setEmailBody(e.target.value);
                  }}
                />
              </Stack>
              <FormGroup>
                <Stack flexDirection="row" alignItems="center">
                  <FormLabel>Recipients</FormLabel>
                  {shouldShowSelectAllButton() ? (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        const newCheckedItems = { ...checkedItems };

                        if (emailTopic === "pickupInstruction") {
                          const candidates = presaleOrders.filter(
                            (order) => !order.emailSent && order.paid
                          );
                          candidates.forEach((order) => {
                            newCheckedItems[order.orderId] = true;
                          });
                        } else if (emailTopic === "paymentReminder") {
                          const candidates = presaleOrders.filter(
                            (order) => !order.emailSentPayment && !order.paid
                          );
                          candidates.forEach((order) => {
                            newCheckedItems[order.orderId] = true;
                          });
                        } else {
                          presaleOrders.forEach((order) => {
                            newCheckedItems[order.orderId] = true;
                          });
                        }

                        setCheckedItems(newCheckedItems);
                      }}
                    >
                      Select all
                    </Button>
                  ) : null}
                  {shouldShowDeselectAllButton() ? (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        const newCheckedItems = { ...checkedItems };

                        Object.keys(newCheckedItems).forEach((key) => {
                          newCheckedItems[key] = false;
                        });

                        setCheckedItems(newCheckedItems);
                      }}
                    >
                      Deselect all
                    </Button>
                  ) : null}
                </Stack>
              </FormGroup>
              <Stack>
                <FormGroup>
                  {presaleOrders.map((o, index) => (
                    <FormControlLabel
                      key={o.orderId}
                      control={
                        <Stack flexDirection="row" alignItems="center">
                          <Checkbox
                            name={o.orderId}
                            onChange={handleCheckboxChange}
                            checked={checkedItems[o.orderId] || false}
                            disabled={
                              (emailTopic === "pickupInstruction" && !o.paid) ||
                              (emailTopic === "paymentReminder" && o.paid)
                            }
                          />
                          {!o.paid ? (
                            <Chip
                              label="Unpaid"
                              size="small"
                              color="warning"
                              sx={{ mr: 1 }}
                            />
                          ) : null}
                          {(emailTopic === "pickupInstruction" &&
                            o.emailSent) ||
                          (emailTopic === "paymentReminder" &&
                            o.emailSentPayment &&
                            !o.paid) ? (
                            <Chip label="Sent" size="small" sx={{ mr: 1 }} />
                          ) : null}
                        </Stack>
                      }
                      label={`${o.firstName} ${o.lastName} (${o.email})`}
                    />
                  ))}
                </FormGroup>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Box flex={1}>
                <Button onClick={handleCloseEmail} disabled={sendingEmail}>
                  Cancel
                </Button>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ccSeller}
                    sx={{ ml: -1.35 }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCcSeller(event.target.checked);
                    }}
                  />
                }
                name="sendCopy"
                label="CC myself"
              />
              <Button
                disabled={
                  sendingEmail ||
                  Object.values(checkedItems).every((checked) => !checked)
                }
                variant="contained"
                onClick={async () => {
                  if (!emailBody.trim()) {
                    console.log("empty email content");
                    return;
                  }
                  setSendingEmail(true);
                  const promises = Object.keys(checkedItems).map(
                    async (orderId) => {
                      if (checkedItems[orderId]) {
                        const order = presaleOrders.find(
                          (order) => order.orderId === orderId
                        );
                        if (order) {
                          if (emailTopic === "pickupInstruction") {
                            await dispatch(
                              modifyPresaleOrder({
                                ...order,
                                emailSent: true,
                              })
                            );
                          }
                          if (emailTopic === "paymentReminder") {
                            await dispatch(
                              modifyPresaleOrder({
                                ...order,
                                emailSentPayment: true,
                              })
                            );
                          }
                          await sendNotificationEmail(
                            order,
                            sellerProfile,
                            emailTopic === "pickupInstruction"
                              ? "Your order is ready"
                              : emailTopic === "paymentReminder"
                              ? "Presale payment reminder"
                              : "Presale order information",
                            emailHeading,
                            emailPrimary,
                            emailBody.replace(/\n/g, "<br>"),
                            false,
                            emailTopic,
                            ccSeller
                          );
                        }
                      }
                    }
                  );
                  await Promise.all(promises);
                  setSendingEmail(false);
                  setEmailHeading("");
                  setEmailPrimary("");
                  setEmailBody("");
                  setOpenEmail(false);
                }}
              >
                {sendingEmail ? "Sending" : "Send"}
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </CardStyle>
    </Stack>
  );
};
