import { FormikErrors, FormikProps } from "formik";
import { useState } from "react";

import {
  Box,
  Button,
  Stack,
  FormControlLabel,
  TextField,
  InputAdornment,
  Typography,
  Popover,
  Checkbox,
  Divider,
} from "@mui/material";
import { CardStyle } from "Components/AllComponents";
import { PresaleItem } from "Model/PresaleItem";
import { useAppDispatch } from "Redux/hooks";
import {
  deletePresaleItemAsync,
  updatePresaleItemAsync,
} from "Redux/Reducers/presales";
import { LoadingButton } from "@mui/lab";
import { resizeFile } from "Util/helpers";
import { useSession } from "Context/userAuthContext";
import { uploadPresaleItemImage } from "Util/firebaseHelper";
import { PresaleInfo } from "Model/PresaleInfo";

import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Personalized } from "./ItemSettings/Personalized";
import { Flavors } from "./ItemSettings/Flavors";
import { Designs } from "./ItemSettings/Designs";
import { toast } from "react-toastify";
import { CustomSetting } from "./ItemSettings/CustomSetting";

type Props = {
  presaleItem: PresaleItem;
  presaleId: string;
  index: number;
  formik: FormikProps<PresaleInfo>;
};

export const PresaleItemView = ({
  presaleItem,
  presaleId,
  index,
  formik,
}: Props) => {
  const { user } = useSession();
  const dispatch = useAppDispatch();
  // const formikItem = formik.values.presaleItems[index];
  const updatePresaleItem = () => {
    dispatch(
      updatePresaleItemAsync({
        presaleId,
        presaleItem: formik.values.presaleItems[index],
      })
    );
  };
  const [uploadingImage, setUploadingImage] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeleteItem = Boolean(anchorEl);

  return (
    <CardStyle>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Box flex={1}>
          {presaleItem.img ? (
            <LoadingButton
              loading={uploadingImage}
              color="secondary"
              component="label"
              sx={{
                position: "relative",
                width: "100%",
                p: 0,
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  width: "100%",
                  display: "block",
                }}
                src={presaleItem.img}
              />
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={async (e) => {
                  // e.preventDefault();
                  setUploadingImage(true);
                  if (user && e.target.files && e.target.files.length > 0) {
                    const image = e.target.files[0];
                    const resizedImage = await resizeFile(image, 800, 800);
                    var url = await uploadPresaleItemImage(
                      resizedImage as File,
                      user.uid,
                      presaleId,
                      presaleItem.id
                    );
                    formik.setFieldValue(`presaleItems[${index}].img`, url);
                    dispatch(
                      updatePresaleItemAsync({
                        presaleId,
                        presaleItem: {
                          ...formik.values.presaleItems[index],
                          img: url,
                        },
                      })
                    );
                  }
                  setUploadingImage(false);
                }}
              />
            </LoadingButton>
          ) : (
            <Box
              sx={{
                aspectRatio: "1/1",
                borderRadius: 1,
                backgroundColor: "background.default",
              }}
            >
              <LoadingButton
                loading={uploadingImage}
                color="secondary"
                component="label"
                sx={{
                  position: "relative",
                  aspectRatio: "1/1",
                  width: "100%",
                  backgroundColor: "background.default",
                  overflow: "hidden",
                }}
              >
                Upload image
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={async (e) => {
                    // e.preventDefault();
                    setUploadingImage(true);
                    if (user && e.target.files && e.target.files.length > 0) {
                      const image = e.target.files[0];
                      const resizedImage = await resizeFile(image, 800, 800);
                      var url = await uploadPresaleItemImage(
                        resizedImage as File,
                        user.uid,
                        presaleId,
                        presaleItem.id
                      );
                      formik.setFieldValue(`presaleItems[${index}].img`, url);
                      dispatch(
                        updatePresaleItemAsync({
                          presaleId,
                          presaleItem: {
                            ...formik.values.presaleItems[index],
                            img: url,
                          },
                        })
                      );
                    }
                    setUploadingImage(false);
                  }}
                />
              </LoadingButton>
            </Box>
          )}

          <FormControlLabel
            control={
              <Checkbox
                id={`presaleItems[${index}].soldOut`}
                name={`presaleItems[${index}].soldOut`}
                size="small"
                checked={formik.values.presaleItems[index]?.soldOut}
                onChange={async (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  formik.setFieldValue(
                    `presaleItems[${index}].soldOut`,
                    event.target.checked
                  );
                  dispatch(
                    updatePresaleItemAsync({
                      presaleId,
                      presaleItem: {
                        ...formik.values.presaleItems[index],
                        soldOut: event.target.checked,
                      },
                    })
                  );
                  if (!formik.values.presaleItems[index]?.soldOut) {
                    toast("Item sold out.", {
                      position: "top-center",
                      autoClose: 800,
                    });
                  }
                }}
              />
            }
            label="Sold out"
            labelPlacement="end"
            sx={{ mt: 1 }}
          />
        </Box>

        <Stack
          flex={{ xs: 1, sm: 2 }}
          spacing={2}
          sx={{ maxWidth: { xs: "600px", sm: "357.34px" } }}
        >
          <TextField
            fullWidth
            multiline
            required
            id={`presaleItems[${index}].title`}
            name={`presaleItems[${index}].title`}
            label="Item"
            value={formik.values.presaleItems[index]?.title}
            onChange={formik.handleChange}
            onBlur={updatePresaleItem}
            error={Boolean(
              (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
                ?.title
            )}
            helperText={
              (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
                ?.title
            }
          />
          <TextField
            fullWidth
            required
            type="number"
            id={`presaleItems[${index}].price`}
            name={`presaleItems[${index}].price`}
            label="Price"
            onWheel={(e: any) => e.target.blur()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputProps: { min: 1 },
            }}
            value={formik.values.presaleItems[index]?.price}
            onChange={formik.handleChange}
            onBlur={updatePresaleItem}
            error={Boolean(
              (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
                ?.price
            )}
            helperText={
              (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
                ?.price
            }
          />
          <TextField
            fullWidth
            multiline
            margin="normal"
            id={`presaleItems[${index}].description`}
            name={`presaleItems[${index}].description`}
            label="Description"
            value={formik.values.presaleItems[index]?.description}
            onChange={formik.handleChange}
            onBlur={updatePresaleItem}
          />
        </Stack>
      </Stack>

      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack spacing={2}>
        <Personalized
          index={index}
          presaleId={presaleId}
          presaleItem={presaleItem}
          formik={formik}
        />
        <Flavors
          index={index}
          presaleId={presaleId}
          presaleItem={presaleItem}
          formik={formik}
        />
        <Designs
          index={index}
          presaleId={presaleId}
          presaleItem={presaleItem}
          formik={formik}
        />
        {/* <Stack flexDirection="row">Add customization</Stack>
        <CustomSetting
          index={index}
          presaleId={presaleId}
          presaleItem={presaleItem}
          formik={formik}
        /> */}
        <Button
          variant="text"
          startIcon={<DeleteRoundedIcon />}
          sx={{
            width: "fit-content",
            color: "text.secondary",
            alignSelf: "flex-end",
          }}
          onClick={handleClickPopover}
        >
          Delete item
        </Button>
      </Stack>

      <Popover
        id="deleteItem"
        open={openDeleteItem}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ mb: 1, maxWidth: "400px" }}>
            This action will delete the item. Would you like to proceed?
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button color="secondary" onClick={handleClosePopover}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                dispatch(
                  deletePresaleItemAsync({
                    presaleId,
                    presaleItemId: presaleItem.id,
                  })
                );
              }}
            >
              Yes, delete
            </Button>
          </Stack>
        </Box>
      </Popover>
    </CardStyle>
  );
};
