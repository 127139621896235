import { Typography, Box } from "@mui/material";

type FormSectionProps = {
  sectionName: string;
  id: string;
  children: React.ReactNode;
};

export const FormSection = ({
  sectionName,
  id,
  children,
}: FormSectionProps) => {
  return (
    <Box
      sx={{
        mt: 2,
        backgroundColor: "white",
        p: { xs: 2, sm: 3 },
        borderRadius: 4,
      }}
      id={id}
    >
      <Typography variant="h6">{sectionName}</Typography>
      <Box sx={{ mt: 1 }}>{children}</Box>
    </Box>
  );
};
