import "./loading.css";

export const Loading = () => {
  return (
    <div className="loader">
      <ul className="loading">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};
