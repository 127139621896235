import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";
import { InfoBox } from "Components/Containers/InfoBox";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerAgreements = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  return (
    <FormSection id="agreements" sectionName="Agreements">
      <InfoBox sx={{ mb: 1 }}>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {businessInfo.agreements !== ""
            ? businessInfo.agreements
            : "Agreements..."}
        </Typography>
      </InfoBox>

      <FormControl
        fullWidth
        required
        error={
          customerFormik.touched.paymentChecked &&
          Boolean(customerFormik.errors.paymentChecked)
        }
      >
        <FormControlLabel
          control={<Checkbox required />}
          name="paymentChecked"
          label="I understand"
          onChange={customerFormik.handleChange}
        />
        <FormHelperText>
          {customerFormik.touched.paymentChecked &&
            customerFormik.errors.paymentChecked}
        </FormHelperText>
      </FormControl>
    </FormSection>
  );
};
