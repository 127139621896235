import React, { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { publishProfile, uploadImage } from "Util/firebaseHelper";
import _ from "lodash";

import {
  Button,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Link,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { InfoBox } from "Components/AllComponents";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch } from "Redux/hooks";
import { editSellerProfile } from "Redux/actionCreators";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export const AddExternalLink = () => {
  const { user, sellerProfile } = useSession();
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setTitle("");
    setUrl("");
    setOpenModal(false);
  };

  return (
    <div>
      <ListItemButton
        onClick={() => {
          handleOpenModal();
        }}
        sx={{ "& .MuiListItemIcon-root": { minWidth: "32px" } }}
      >
        <ListItemIcon>
          <AddRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="subtitle1">Add external link</Typography>
          }
        />
      </ListItemButton>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          New link
          {user ? null : (
            <InfoBox sx={{ mt: 1 }}>
              <Typography variant="body1">
                Please sign in to save changes.
              </Typography>
            </InfoBox>
          )}
        </DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            let tempLinks = _.cloneDeep(sellerProfile.links);
            tempLinks.push({ title, url, id: sellerProfile.links.length + "" });
            publishProfile(
              { ...sellerProfile, links: tempLinks },
              user?.uid || ""
            );
            dispatch(editSellerProfile({ ...sellerProfile, links: tempLinks }));
            setOpenModal(false);
            setTitle("");
            setUrl("");
          }}
        >
          <DialogContent>
            <TextField
              fullWidth
              required
              id="title"
              label="Title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              id="url"
              label="Url"
              type="url"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              onBlur={() => {
                if (
                  url.indexOf("http://") !== 0 &&
                  url.indexOf("https://") !== 0
                ) {
                  setUrl("https://" + url);
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseModal}
              disabled={!user}
              color="secondary"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={!user}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
