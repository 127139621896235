import { Availability, AvailabilityType } from "Model/Availability";
import dayjs from "dayjs";

export function formatDateFromString(newDate: string) {
  return formatDate(new Date(newDate));
}
export function formatDate(newDate: Date) {
  if (newDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const d = new Date(newDate);
    const year = d.getFullYear();
    const date = d.getDate();
    const monthIndex = d.getMonth();
    const monthName = months[d.getMonth()];
    const dayName = days[d.getDay()];
    const formatted = `${monthName} ${date}, ${year}`;
    return formatted.toString();
  }
  return "";
}

export function getMonthFromString(newDate: string) {
  return getMonth(new Date(newDate));
}

export function getMonth(newDate: Date) {
  if (newDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(newDate);
    const monthName = months[d.getMonth()];
    const formatted = `${monthName}`;
    return formatted.toString();
  }
  return "";
}

export function getDateFromString(newDate: string) {
  return getDate(new Date(newDate));
}

export function getDate(newDate: Date) {
  if (newDate) {
    const d = new Date(newDate);
    const date = d.getDate();
    const formatted = `${date}`;
    return formatted.toString();
  }
  return "";
}

export function getCurrentTime() {
  const date = new Date();
  return date.toDateString() + ", " + date.toTimeString();
}

export const getMinMaxDueDate = (availability: Availability) => {
  var l = availability.lead;
  if (availability.lead === undefined) {
    l = 0;
  }
  var due = new Date();
  due.setDate(due.getDate() + availability.lead);
  due.setHours(0, 0, 0, 0);
  var min = new Date(availability.start);
  min.setHours(0, 0, 0, 0);
  var max = new Date(availability.end);
  max.setHours(0, 0, 0, 0);
  switch (availability.type) {
    case AvailabilityType.acceptNow:
      return { start: due, end: undefined };
    case AvailabilityType.acceptAfter:
      if (due < min) {
        return { start: min, end: undefined };
      } else {
        return { start: due, end: undefined };
      }
    case AvailabilityType.acceptUntil:
      if (due > max) {
        return { start: undefined, end: undefined };
      } else {
        return { start: due, end: max };
      }
    case AvailabilityType.acceptBetween:
      if (due > max) {
        return { start: undefined, end: undefined };
      } else if (due < min) {
        return {
          start: min,
          end: max,
        };
      } else {
        return { start: due, end: max };
      }
    case AvailabilityType.doNotAcceptOrder:
      return { start: min, end: max };
  }
};

export const dueDateStringForm = (availability: Availability) => {
  var l = availability.lead;
  if (availability.lead === undefined) {
    l = 0;
  }
  var due = new Date();
  due.setDate(due.getDate() + availability.lead);
  due.setHours(0, 0, 0, 0);
  var min = new Date(availability.start);
  min.setHours(0, 0, 0, 0);
  var max = new Date(availability.end);
  max.setHours(0, 0, 0, 0);
  switch (availability.type) {
    case AvailabilityType.acceptNow:
      return ` the earliest date you can pick is ${dayjs(due).format(
        "MM/DD/YYYY"
      )}.`;
    case AvailabilityType.acceptAfter:
      if (due < min) {
        return ` the earliest date you can pick is ${dayjs(
          availability.start
        ).format("MM/DD/YYYY")}.`;
      } else {
        return ` the earliest date you can pick is ${dayjs(due).format(
          "MM/DD/YYYY"
        )}.`;
      }
    case AvailabilityType.acceptUntil:
      if (due > max) {
        return " there won't be available dates.";
      } else {
        return ` you can choose a date between ${dayjs(due).format(
          "MM/DD/YYYY"
        )} and ${dayjs(availability.end).format("MM/DD/YYYY")}.`;
      }
    case AvailabilityType.doNotAcceptOrder:
      return " I'm not taking new orders.";
    case AvailabilityType.acceptBetween:
      if (due > max) {
        return " there won't be available dates.";
      } else if (due < min) {
        return ` you can choose a date between ${dayjs(
          availability.start
        ).format("MM/DD/YYYY")} and ${dayjs(availability.end).format(
          "MM/DD/YYYY"
        )}.`;
      } else {
        return ` you can choose a date between ${dayjs(due).format(
          "MM/DD/YYYY"
        )} and ${dayjs(availability.end).format("MM/DD/YYYY")}.`;
      }
  }
};

export const getNewDateWithoutHours = (): string => {
  var date = new Date();
  date.setDate(date.getDate());
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
};

export const getNewDateWithoutHoursDate = (): Date => {
  var date = new Date();
  date.setDate(date.getDate());
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getNewDateMMDDYYYY = (): string => {
  const date = new Date();
  const dateStr = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return dateStr;
};

export const getDateMMDDYYYY = (date: Date): string => {
  const dateStr = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return dateStr;
};

export function getNewDateHumanMode() {
  const date = new Date();
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${month} ${day}, ${year}`;
}

export function getDateStringHumanMode(dateStr: string) {
  const date = new Date(dateStr);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${month} ${day}, ${year}`;
}

export function getDateHumanMode(date: Date) {
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${month} ${day}, ${year}`;
}
