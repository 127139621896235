import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  FormControlLabel,
  Switch,
  Slider,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Webcam from "react-webcam";
import { HammerItem } from "./Projector/HammerItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CardStyle } from "Components/AllComponents";
import { Topic } from "@mui/icons-material";
import { NotFound } from "Components/NotFound";

// Camera needs https
// https://stackoverflow.com/questions/73688554/react-camera-component-does-not-work-on-mobile-device

const Projector = () => {
  const location = useLocation();
  const texts = location.state;

  const navigate = useNavigate();
  const [fontLoaded, setFontLoaded] = useState(false);

  const [cameraOn, setCameraOn] = useState(false);

  const [textOpacity, setTextOpacity] = useState<number>(30);
  const handleOpacityChange = (event: Event, newValue: number | number[]) => {
    setTextOpacity(newValue as number);
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleFontUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fontUrl = reader.result as string;
        loadFont(fontUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const loadFont = (fontUrl: string) => {
    const style = document.createElement("style");
    style.appendChild(
      document.createTextNode(`
        @font-face {
          font-family: 'CustomFont';
          src: url(${fontUrl});
        }
      `)
    );
    document.head.appendChild(style);
    setFontLoaded(true);
  };

  const handleToggleCamera = () => {
    setCameraOn((prevCameraOn) => !prevCameraOn);
  };

  // const texts = ["Nancy", "Steve", "Julia", "Jay"];
  const [currentTextId, setCurrentTextId] = useState<number>(0);

  return texts === null ? (
    <NotFound></NotFound>
  ) : (
    <div
      style={{
        height: screenSize.height,
        width: screenSize.width,
        overflow: "hidden",
      }}
    >
      <AppBar color="transparent">
        <Toolbar variant="dense" sx={{ width: screenSize.width }}>
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {smallScreen && (
              <FormControlLabel
                control={
                  <Switch
                    checked={cameraOn}
                    disabled={!fontLoaded}
                    onChange={handleToggleCamera}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Camera"
              />
            )}
            {smallScreen && fontLoaded && texts.length > 1 && (
              <Stack direction="row" alignItems="center">
                <IconButton
                  onClick={() => {
                    setCurrentTextId(currentTextId - 1);
                  }}
                  disabled={currentTextId + 1 === 1}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
                <Typography>
                  {currentTextId + 1} of {texts.length}
                </Typography>
                <IconButton
                  onClick={() => {
                    setCurrentTextId(currentTextId + 1);
                  }}
                  disabled={currentTextId + 1 === texts.length}
                >
                  <ArrowForwardIosRoundedIcon />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <IconButton
            color="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {smallScreen && fontLoaded ? (
        <Box
          sx={{
            mt: 6,
            backgroundColor: "background.default",
            height: "100%",
            width: screenSize.width,
            overflow: "hidden",
            whiteSpace: "nowrap",
            position: "fixed",
          }}
        >
          {cameraOn && (
            <Webcam
              audio={false}
              videoConstraints={{ facingMode: { exact: "environment" } }}
              mirrored={false}
              onUserMediaError={(error) => {
                console.error("Error accessing camera:", error);
                setCameraOn(false);
              }}
              style={{ height: "inherit" }}
            />
          )}
          <HammerItem>
            <p
              style={{
                fontFamily: "CustomFont",
                fontSize: "80px",
                opacity: cameraOn ? textOpacity / 100 : 1,
                lineHeight: "150%",
              }}
            >
              {texts[currentTextId]}
            </p>
          </HammerItem>
        </Box>
      ) : (
        <Stack
          sx={{
            mt: 6,
            pl: 3,
            pr: 3,
            backgroundColor: "background.default",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {smallScreen ? (
            <CardStyle>
              <Typography variant="h6">
                Let's begin with uploading the font you want to project
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                File type: ttf / otf
              </Typography>
              <input type="file" onChange={handleFontUpload} />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Sometimes you get a zip file when downloading from the font
                site, please unzip to get the font file.
              </Typography>
            </CardStyle>
          ) : (
            <Typography variant="h6">
              To use this feature, please open Flowlylink on a mobile device.
            </Typography>
          )}
        </Stack>
      )}

      {smallScreen && cameraOn && (
        <AppBar color="transparent" sx={{ top: "auto", bottom: 0 }}>
          <Toolbar variant="dense">
            <Slider
              valueLabelDisplay="off"
              value={textOpacity}
              onChange={handleOpacityChange}
            />
          </Toolbar>
        </AppBar>
      )}
    </div>
  );
};

export default Projector;
