import { useSession } from "Context/userAuthContext";

// ui related
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";

export const Fulfillment = () => {
  const { sellerProfile } = useSession();
  const fulfillmentMethod = sellerProfile.fulfillmentMethod;

  if (fulfillmentMethod === "Pickup or shipping") {
    return (
      <FormSection id="fulfillment" sectionName="Fulfillment">
        <FormControl required={false} disabled fullWidth>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="customerFulfillment"
          >
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                value="Pickup"
                control={<Radio />}
                label="Pickup"
                checked={false}
              />
              <FormControlLabel
                value="Shipping"
                control={<Radio />}
                label="Shipping"
                checked={false}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </FormSection>
    );
  } else {
    return null;
  }
};
