import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, IconButton, ListItemIcon } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import {
  PresaleOrder,
  PresaleOrderItem,
  PresaleOrderItemCustomization,
  aggregateOrders,
  calculateTotalItemsSoldForPresale,
} from "Model/PresaleOrder";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useAppSelector } from "Redux/hooks";
import { selectedPresaleSelector } from "Redux/selectors";
import { Content } from "pdfmake/interfaces";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface MoreMenuProps {
  ordersForPresale: Record<string, PresaleOrder[]>;
}

export const MoreMenu: React.FC<MoreMenuProps> = ({ ordersForPresale }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedPresale = useAppSelector(selectedPresaleSelector);
  const aggregatedOrders = aggregateOrders(
    Object.values(ordersForPresale).reduce(
      (acc, orders) => [...acc, ...orders],
      []
    ),
    "title"
  );
  const convertFulifllment = (fulfillment: string) => {
    const parts = fulfillment.split("##");
    const firstPart = parts[0];
    const secondPart = " " + parts[1] || "";
    return firstPart + secondPart;
  };

  const generateCustomersPDF = () => {
    const documentDefinition = {
      content: [
        {
          text: `${selectedPresale.presaleName}\n\n`,
          bold: true,
          alignment: "center",
          fontSize: 18,
        } as Content,

        ...Object.entries(ordersForPresale).flatMap(([fulfillment, orders]) => {
          return [
            {
              text: `${convertFulifllment(fulfillment)}\n\n`,
              fontSize: 14,
            } as Content,
            ...orders.flatMap((order) => {
              let index = 1;
              return [
                {
                  columns: [
                    {
                      text: `${order.firstName + " " + order.lastName}`,
                      bold: true,
                      fontSize: 12,
                      alignment: "left",
                    },
                    // {
                    //   canvas: [
                    //     {
                    //       type: "rect",
                    //       x: 0,
                    //       y: 0,
                    //       w: 10,
                    //       h: 10,
                    //       lineWidth: 0.5,
                    //     },
                    //   ],
                    //   alignment: "right",
                    // },
                    {
                      text: "Ready [  ]   Picked up [  ]\n\n",
                      fontSize: 12,
                      alignment: "right",
                    },
                    // {
                    //   canvas: [
                    //     {
                    //       type: "rect",
                    //       x: 0,
                    //       y: 0,
                    //       w: 10,
                    //       h: 10,
                    //       lineWidth: 0.5,
                    //     },
                    //   ],
                    //   alignment: "right",
                    // },
                  ],
                } as Content,
                ...Object.values(order.items).flatMap(
                  (item: PresaleOrderItem) =>
                    Object.values(item.customization).flatMap(
                      (customization: PresaleOrderItemCustomization) => [
                        {
                          text: `${index++}. ${item.presaleItemName}${
                            customization.flavor
                              ? ` - ${customization.flavor}`
                              : ""
                          }${
                            customization.color
                              ? ` - ${customization.color}`
                              : ""
                          }${
                            customization.personalization
                              ? ` - ${customization.personalization}`
                              : ""
                          }\n\n`,
                        },
                      ]
                    )
                ),
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 5,
                      x2: 595.28 - 2 * 40,
                      y2: 5,
                      lineWidth: 0.5,
                    },
                  ],
                } as Content,
                { text: "\n" },
              ];
            }),
          ];
        }),
      ],
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  const generateItemsPDF = () => {
    let index = 1;
    let firstLine = true;
    const documentDefinition = {
      content: [
        {
          text: `${selectedPresale.presaleName}\n\n`,
          bold: true,
          alignment: "center",
          fontSize: 18,
        } as Content,
        {
          text: `Total quantity: ${calculateTotalItemsSoldForPresale(
            ordersForPresale
          )}\n`,
          alignment: "center",
          fontSize: 14,
        } as Content,
        ...aggregatedOrders.flatMap((order) =>
          Object.entries(order.colorMap).flatMap(
            ([color, personalizations]) => {
              index = 1;
              const result = [
                firstLine
                  ? ""
                  : ({
                      canvas: [
                        {
                          type: "line",
                          x1: 0,
                          y1: 5,
                          x2: 595.28 - 2 * 40,
                          y2: 5,
                          lineWidth: 0.5,
                        },
                      ],
                    } as Content),
                { text: "\n" },
                ...personalizations.flatMap((personalization) => [
                  {
                    text: `${index++}. ${order.title}${
                      personalization.flavor
                        ? ` - ${personalization.flavor}`
                        : ""
                    }${color ? ` - ${color}` : ""}${
                      personalization.personalization
                        ? ` - ${personalization.personalization}`
                        : ""
                    }\n\n`,
                  },
                ]),
              ];
              firstLine = false;
              return result;
            }
          )
        ),
      ],
    };
    pdfMake.createPdf(documentDefinition).open();
  };

  return (
    <>
      <IconButton sx={{ ml: 1 }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            generateCustomersPDF();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          Print (sorted by customer)
        </MenuItem>
        <MenuItem
          onClick={() => {
            generateItemsPDF();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          Print (items only)
        </MenuItem>
      </Menu>
    </>
  );
};
