import { Box } from "@mui/material";
import { SxProps } from "@mui/material";

type InfoBoxProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

export const InfoBox = ({ sx = [], children }: InfoBoxProps) => {
  return (
    <Box
      sx={[
        { backgroundColor: "#f7f7f7", p: 2, borderRadius: 2 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
