import { Unit } from "./CustomerRequestInfo";
import * as yup from "yup";
import { Timestamp } from "@firebase/firestore";
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const orderInfoValidationSchema = (validatePayment: boolean) =>
  yup.object({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    email: yup.string().email().required("This field is required"),
    phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    // .required("This field is required"),
    event: yup.string().required("This field is required"),
    count: yup.number().required("This field is required").min(0),
    shippingAddress1: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    shippingCity: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    shippingState: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    shippingZip: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    paymentChecked: yup.boolean().oneOf([true], "This field is required"),
    orderTotal: yup.number().required("This field is required").min(0),
    paid: yup
      .number()
      .required("This field is required")
      .min(0)
      .test(
        "paid",
        "Paid amount should not be larger than order total",
        function (value) {
          const orderTotal = this.resolve(yup.ref("orderTotal")) as number;
          if (typeof value !== "undefined") {
            return value <= orderTotal;
          }
          return true;
        }
      ),
  });

export const defaultOrderInfo: OrderInfo = {
  formId: "",
  requestId: "",
  orderCreated: new Date().toISOString(),
  requestCreated: new Date().toISOString(),
  orderTotal: 0,
  paid: 0,
  orderId: "",
  status: "",
  identifier: "",
  notes: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  preferredContact: "Email",
  customerDate: new Date().toISOString(),
  customerFulfillment: "Pickup",
  shippingAddress1: "",
  shippingAddress2: "",
  shippingCity: "",
  shippingState: "",
  shippingZip: "",
  event: "",
  theme: "",
  colors: "",
  personalization: "",
  count: 1,
  questions: "",
  paymentChecked: false,
  bucketLists: "",
  flavors: [] as string[],
  packagings: [] as string[],
  designInspirations: [] as string[],
  created: new Date().toISOString(),
  unit: Unit.Dozen,
  updated: new Date().toISOString(),
};

export interface OrderInfo {
  formId: string;
  requestId: string;
  orderCreated: string;
  orderTotal: number;
  paid: number;
  requestCreated: string;
  orderId: string;
  status: string;
  identifier: string;
  notes: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  preferredContact: string;
  customerDate: string;
  customerFulfillment: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
  event: string;
  theme: string;
  colors: string;
  personalization: string;
  count: number;
  questions: string;
  paymentChecked: boolean;
  bucketLists: string;
  flavors: string[];
  packagings: string[];
  designInspirations: string[];
  created: string;
  unit: Unit;
  updated: string;
}
