import { useState } from "react";
import { useSession } from "Context/userAuthContext";
import { PresaleOrder } from "Model/PresaleOrder";

import { useAppDispatch } from "Redux/hooks";
import {
  deletePresaleOrder,
  modifyPresaleOrder,
} from "Redux/Reducers/presaleOrders";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Button,
  Typography,
  Stack,
  Grid,
  Chip,
  Checkbox,
  Popover,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  ListItem,
  ListItemButton,
  ListItemText,
  LinearProgress,
  Alert,
} from "@mui/material";
import { getDateStringHumanMode } from "Util/dateUtil";
import {
  PAYMENT_TYPE_INSTRUCTION,
  PAYMENT_TYPE_LINK,
  PAYMENT_TYPE_CREDIT_CARD,
} from "Constants";

import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { LabeledText } from "Components/LabeledText";
import { InfoBox } from "Components/AllComponents";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import { Header } from "Components/Header";
import { toast } from "react-toastify";
import { sendCancellationEmail } from "Util/firebaseHelper";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type OrderProps = {
  order: PresaleOrder;
};

export const PresaleOrderInfo = ({ order }: OrderProps) => {
  const { sellerProfile } = useSession();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openOrder, setOpenOrder] = useState(false);
  const handleOpenOrder = () => {
    setOpenOrder(true);
  };
  const handleCloseOrder = () => {
    setOpenOrder(false);
  };

  const [anchorElOpenPayment, setAnchorElOpenPayment] =
    useState<HTMLButtonElement | null>(null);
  const handleClickOpenPayment = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElOpenPayment(event.currentTarget);
  };
  const handleClosePayment = () => {
    setAnchorElOpenPayment(null);
  };
  const openPayment = Boolean(anchorElOpenPayment);
  const idOpenPayment = openPayment ? "simple-popover" : undefined;

  const [anchorElDeleteOrder, setAnchorElDeleteOrder] =
    useState<HTMLButtonElement | null>(null);
  const [orderToDelete, setOrderToDelete] = useState("");
  const handleClickDeleteOrderPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElDeleteOrder(event.currentTarget);
  };
  const handleCloseDeleteOrderPopover = () => {
    setAnchorElDeleteOrder(null);
  };
  const openDeleteCustomer = Boolean(anchorElDeleteOrder);

  return (
    <div>
      <ListItemButton disableGutters onClick={handleOpenOrder}>
        <ListItemText
          primary={order.firstName + " " + order.lastName}
          secondary={order.questions}
          secondaryTypographyProps={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: { xs: "164px", sm: "300px", md: "400px" },
          }}
        />
        {order.paid ? null : (
          <Chip label="Unpaid" size="small" color="warning" sx={{ mr: 1 }} />
        )}
        <Typography variant="body1">${order.totalPrice}</Typography>
      </ListItemButton>
      <Dialog
        open={openOrder}
        onClose={handleCloseOrder}
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogContent dividers>
          <Stack spacing={2}>
            <Header
              heading="Order details"
              meta={"Submitted on " + order.submittedDate}
            />
            <Stack>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LabeledText
                    label="Customer"
                    text={order.firstName + " " + order.lastName}
                    bold
                  />
                </Grid>
                <Grid item xs={6}>
                  <LabeledText
                    label="Pickup date"
                    text={getDateStringHumanMode(order.fulfillment.date)}
                    bold
                  />
                </Grid>
                <Grid item xs={6}>
                  <LabeledText
                    label="Payment method"
                    text={order.payment.method}
                    bold
                    aria-describedby={idOpenPayment}
                    onClickInfo={
                      order.paid &&
                      order.payment.type === PAYMENT_TYPE_CREDIT_CARD
                        ? false
                        : handleClickOpenPayment
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    spacing={
                      order.payment.type === PAYMENT_TYPE_CREDIT_CARD
                        ? 0
                        : -0.75
                    }
                  >
                    <Typography variant="body2" color="text.secondary">
                      Paid
                    </Typography>
                    {order.payment.type === PAYMENT_TYPE_CREDIT_CARD &&
                    order.paid ? (
                      <CheckRoundedIcon />
                    ) : order.payment.type === PAYMENT_TYPE_CREDIT_CARD &&
                      !order.paid ? (
                      <CloseRoundedIcon />
                    ) : (
                      <Checkbox
                        size="small"
                        sx={{ width: "fit-content" }}
                        checked={order.paid}
                        onChange={(e) => {
                          dispatch(
                            modifyPresaleOrder({
                              ...order,
                              paid: e.target.checked,
                            })
                          );
                        }}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Popover
                id={idOpenPayment}
                open={openPayment}
                anchorEl={anchorElOpenPayment}
                onClose={handleClosePayment}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  {order.payment.type === PAYMENT_TYPE_INSTRUCTION ? (
                    order.payment.instruction
                  ) : (
                    <Link
                      onClick={() => {
                        window.open(order.payment.link, "_blank");
                        handleClosePayment();
                      }}
                    >
                      {order.payment.link}
                    </Link>
                  )}
                </Typography>
              </Popover>

              <Divider sx={{ mt: 1, mb: 2 }} />
              <Stack spacing={2} divider={<Divider />}>
                {Object.values(order.items)
                  .sort((a, b) =>
                    a.presaleItemName?.localeCompare(b.presaleItemName)
                  )
                  .map((orderItem) =>
                    orderItem.count === 0 ? null : (
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="flex-start"
                        key={orderItem.presaleItemId}
                      >
                        <Box
                          sx={{
                            width: "64px",
                            height: "64px",
                            borderRadius: 1,
                            backgroundColor: "background.default",
                          }}
                        >
                          {orderItem.img ? (
                            <img
                              src={orderItem.img}
                              style={{
                                width: "64px",
                                height: "64px",
                                display: "block",
                                borderRadius: "4px",
                                objectFit: "cover",
                              }}
                            />
                          ) : null}
                        </Box>
                        <Stack
                          spacing={1}
                          flex={1}
                          justifyContent="center"
                          sx={{ minHeight: "64px" }}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="flex-start"
                          >
                            <Typography variant="subtitle2" flex={1}>
                              {orderItem.presaleItemName}
                            </Typography>
                            {orderItem.customization.filter(
                              (c) =>
                                c.personalization !== "" ||
                                c.flavor !== "" ||
                                c.color !== ""
                            ).length > 0 ? null : (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="flex-start"
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  ${orderItem.price} x {orderItem.count}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.primary"
                                  sx={{ fontWeight: 500 }}
                                >
                                  ${orderItem.price * orderItem.count}
                                </Typography>
                              </Stack>
                            )}
                          </Stack>
                          {orderItem.customization.filter(
                            (c) =>
                              c.personalization !== "" ||
                              c.flavor !== "" ||
                              c.color !== ""
                          ).length > 0 && (
                            <Stack spacing={1} divider={<Divider />} flex={1}>
                              {orderItem.customization.map((c, index) =>
                                c.personalization !== "" ||
                                c.flavor !== "" ||
                                c.color !== "" ? (
                                  <Stack
                                    key={orderItem.presaleItemId + index}
                                    direction="row"
                                    spacing={1}
                                    alignItems="flex-start"
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ minWidth: "12px" }}
                                    >
                                      {index + 1}.
                                    </Typography>
                                    <Stack flex={1}>
                                      {c.flavor ? (
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Flavor:{" "}
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                            sx={{ fontWeight: 500 }}
                                          >
                                            {c.flavor}
                                          </Typography>
                                        </Typography>
                                      ) : null}
                                      {c.color ? (
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Design:{" "}
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                            sx={{ fontWeight: 500 }}
                                          >
                                            {c.color}
                                          </Typography>
                                        </Typography>
                                      ) : null}
                                      {c.personalization ? (
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Personalization:{" "}
                                          <Link
                                            key={index}
                                            variant="body2"
                                            color="text.primary"
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                c.personalization
                                              );
                                              toast("Text copied.", {
                                                position: "top-center",
                                                autoClose: 800,
                                              });
                                            }}
                                            sx={{ fontWeight: 500 }}
                                          >
                                            {c.personalization}
                                          </Link>
                                        </Typography>
                                      ) : null}
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                        sx={{ fontWeight: 500 }}
                                      >
                                        ${c.price * c.count}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                ) : null
                              )}
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    )
                  )}
              </Stack>
              <Divider sx={{ mt: 2, mb: 1 }} />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                columnGap={1}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Total
                </Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  ${order.totalPrice}
                </Typography>
              </Stack>
            </Stack>

            <Stack>
              <Typography variant="h6" flex={1} sx={{ mb: 1 }}>
                Contact
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  onClick={() => {
                    navigator.clipboard.writeText(order.phoneNumber);
                    toast(`Phone number copied.`, {
                      position: "top-center",
                      autoClose: 800,
                    });
                  }}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <LabeledText
                    icon={<PhoneAndroidOutlinedIcon />}
                    label="Phone number"
                    text={order.phoneNumber}
                    bold
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  onClick={() => {
                    navigator.clipboard.writeText(order.email);
                    toast(`Email copied.`, {
                      position: "top-center",
                      autoClose: 800,
                    });
                  }}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <LabeledText
                    icon={<AlternateEmailOutlinedIcon />}
                    label="Email"
                    text={order.email}
                    bold
                  />
                </Grid>
              </Grid>
            </Stack>

            {order.questions ? (
              <Stack spacing={0.5} sx={{ pt: 1 }}>
                <Typography variant="h6" flex={1}>
                  Questions/comments
                </Typography>
                <Typography>{order.questions}</Typography>
              </Stack>
            ) : null}

            <Box sx={{ pt: 1 }}>
              <Button
                startIcon={<DeleteRoundedIcon />}
                sx={{ width: "fit-content", color: "text.secondary" }}
                onClick={(e) => {
                  handleClickDeleteOrderPopover(e);
                  setOrderToDelete(order.orderId);
                }}
              >
                Delete order
              </Button>
            </Box>

            <Popover
              id={orderToDelete}
              open={openDeleteCustomer}
              anchorEl={anchorElDeleteOrder}
              onClose={handleCloseDeleteOrderPopover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{ mb: 1, maxWidth: "400px" }}>
                  This action will delete the order. A cancellation email will
                  be sent to your customer once the order is deleted. Would you
                  like to proceed?
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    color="secondary"
                    onClick={handleCloseDeleteOrderPopover}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={async () => {
                      dispatch(
                        deletePresaleOrder({
                          presaleId: order.presaleId,
                          orderId: orderToDelete,
                        })
                      );
                      handleCloseDeleteOrderPopover();
                      handleCloseOrder();
                      await sendCancellationEmail(
                        order,
                        sellerProfile,
                        "Presale order canceled"
                      );
                      toast("Order deleted.", {
                        position: "top-center",
                        autoClose: 800,
                      });
                    }}
                  >
                    Yes, delete
                  </Button>
                </Stack>
              </Box>
            </Popover>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOrder} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
