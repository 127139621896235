import { useEffect } from "react";
import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";
import { useAppSelector } from "Redux/hooks";
import { businessInfoSelector } from "Redux/selectors";
import _ from "lodash";

// ui related
import {
  Radio,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  Button,
  OutlinedInput,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { FormEditor } from "Components/BusinessView/FormEditor";

// icons
import DeleteIcon from "@mui/icons-material/Delete";

export const Flavor = ({ formik }: { formik: FormikProps<BusinessInfo> }) => {
  const getErrors = (errors: any) => {
    return Array.isArray(errors)
      ? errors.filter(
          (email: string, i: number, arr: any) => arr.indexOf(email) === i
        )
      : errors;
  };

  if (formik.values.showFlavors) {
    return (
      <FormSection id="flavor" sectionName="Flavor">
        <FormEditor visible>
          <List disablePadding>
            {formik.values.flavors.map((flavor, index) => (
              <ListItem key={index} disableGutters>
                <FormControl variant="outlined" fullWidth size="small">
                  <OutlinedInput
                    required
                    multiline
                    placeholder="Option"
                    value={formik.values.flavors[index]}
                    onChange={(e) => {
                      let tempFlavors = _.cloneDeep(formik.values.flavors);
                      tempFlavors[index] = e.target.value;
                      formik.setFieldValue("flavors", tempFlavors);
                    }}
                    error={
                      formik.values.flavors[index] === "" &&
                      formik.touched.flavors &&
                      Boolean(formik.errors.flavors)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            let tempFlavors = _.cloneDeep(
                              formik.values.flavors
                            );
                            tempFlavors.splice(index, 1);
                            formik.setFieldValue("flavors", tempFlavors);
                          }}
                          disabled={formik.values.flavors.length === 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.values.flavors[index] === "" &&
                    formik.touched.flavors &&
                    Boolean(formik.errors.flavors) && (
                      <FormHelperText error>
                        {formik.values.flavors[index] === "" &&
                          formik.touched.flavors &&
                          getErrors(formik.errors.flavors as any)}
                      </FormHelperText>
                    )}
                </FormControl>
              </ListItem>
            ))}
          </List>
          <Button
            variant="text"
            onClick={() => {
              let tempFlavors = formik.values.flavors;
              formik.setFieldValue("flavors", tempFlavors.concat([""]));
            }}
            sx={{ width: "fit-content" }}
          >
            Add option
          </Button>
        </FormEditor>
      </FormSection>
    );
  } else {
    return null;
  }
};
