import { Badge, Box } from "@mui/material";
import { PickersDayProps, PickersDay } from "@mui/x-date-pickers";
import { useSession } from "Context/userAuthContext";
import { useAppSelector } from "Redux/hooks";
import { ordersByCustomerDateSelector } from "Redux/selectors";
import { getDateMMDDYYYY, getNewDateMMDDYYYY } from "Util/dateUtil";

export const CalMobileDay = (props: PickersDayProps<Date>) => {
  const { user, sellerProfile } = useSession();
  const { day, outsideCurrentMonth, ...other } = props;

  const dateString = getDateMMDDYYYY(day);
  const blocked =
    day < new Date() ||
    sellerProfile.availability.dateBlocked.findIndex(
      (d) => d === dateString
    ) !== -1;

  const ordersByCustomerDate = useAppSelector(ordersByCustomerDateSelector);
  const hasOrder = ordersByCustomerDate.get(dateString)?.length;
  return outsideCurrentMonth ? (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  ) : (
    <Box sx={{ position: "relative" }}>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          borderRadius: 1,
          backgroundColor: blocked ? "background.default" : "transparent",
          color: blocked ? "text.secondary" : "text.primary",
        }}
      />
      {hasOrder ? (
        <Box
          sx={{
            position: "absolute",
            height: 0,
            width: 0,
            border: "3px solid",
            borderRadius: 4,
            borderColor: "primary.dark",
            right: "42%",
            top: "75%",
          }}
        />
      ) : null}
    </Box>
  );
};
