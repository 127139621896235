import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "Context/userAuthContext";
import { Unit } from "Model/CustomerRequestInfo";
import { formatDateFromString } from "Util/dateUtil";
import {
  submitNewOrderTransaction,
  submitNewQuoteTransaction,
} from "Util/firebaseHelper";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  businessInfoSelector,
  selectedRequestSelector,
  selectOrderById,
} from "Redux/selectors";
import useMediaQuery from "@mui/material/useMediaQuery";

// ui related
import {
  Box,
  CssBaseline,
  Button,
  Typography,
  Drawer,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Alert,
  Popover,
  Chip,
  Divider,
} from "@mui/material";
import {
  AppNav,
  SubNav,
  InfoBox,
  CardStyle,
  ContentWrapNarrow,
} from "Components/AllComponents";
import { NewOrderInfo } from "Pages/BusinessView/AllOrders/Components/NewOrderInfo";
import { RequestsDrawer } from "./RequestsDrawer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";
import { useTheme } from "@mui/material/styles";
import { OverviewGrid } from "Components/BusinessView/CustomOrder/OverviewGrid";
import { ContactInfo } from "Components/BusinessView/CustomOrder/ContactInfo";
import { deleteRequestAsync } from "Redux/Reducers/requests";
import { LabeledText } from "Components/LabeledText";
import { Header } from "Components/Header";
import { ShipTo } from "Components/BusinessView/CustomOrder/ShipTo";
import { NewQuoteInfo } from "./NewQuoteInfo";
import {
  DEFAULT_BUCKET_LIST,
  QUOTE_STATUS_PAGE_URL,
  REQUEST_STATUS_ACCEPTED,
  REQUEST_STATUS_ORDERED,
  REQUEST_STATUS_QUOTED,
  SITE_URL,
} from "Constants";
import { useFormik } from "formik";
import { defaultOrderInfo, orderInfoValidationSchema } from "Model/OrderInfo";
import { convertRequestToOrder } from "Util/helpers";
import { toast } from "react-toastify";

// icons
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import Looks3OutlinedIcon from "@mui/icons-material/Looks3Outlined";
import { encodeRequestInfo } from "Util/encoding";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const drawerWidth = 260;
interface Props {
  window?: () => Window;
}

const AllRequests = (props: Props) => {
  const navigate = useNavigate();
  const businessInfo = useAppSelector(businessInfoSelector);
  const { user, sellerProfile, allRequests } = useSession();
  const moment = require("moment");
  const dispatch = useAppDispatch();
  const selectedRequest = useAppSelector(selectedRequestSelector);

  const formPublished = sellerProfile.forms && sellerProfile.forms.length > 0;

  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const currentOrder = useAppSelector((state) =>
    selectOrderById(state, selectedRequest.orderId)
  );

  //design inspirations
  const Carousel = require("react-responsive-carousel").Carousel;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: selectedRequest
      ? convertRequestToOrder(businessInfo.businessInfo.id, selectedRequest)
      : defaultOrderInfo,
    validationSchema: orderInfoValidationSchema(
      sellerProfile.payments.length > 0
    ),
    onSubmit: async (values, { resetForm }) => {
      if (user?.uid) {
        if (currentAction === "quote") {
          submitNewQuoteTransaction(
            user.uid,
            businessInfo.businessInfo.id,
            values,
            selectedRequest.quoteId === ""
              ? 1
              : parseInt(selectedRequest.quoteId, 10) + 1,
            selectedRequest.orderId,
            sellerProfile,
            `${SITE_URL}/${QUOTE_STATUS_PAGE_URL}?info=${encodeRequestInfo(
              businessInfo.businessInfo.id,
              selectedRequest.requestId,
              selectedRequest.phoneNumber
            )}`
          );
        } else {
          submitNewOrderTransaction(
            user.uid,
            businessInfo.businessInfo.id,
            values,
            true,
            sellerProfile
          );
        }
        setOpenNewQuote(false);
        setOpenNewOrder(false);
      }
    },
  });

  const [openNewOrder, setOpenNewOrder] = useState(false);
  const handleOpenNewOrder = () => {
    setOpenNewOrder(true);
  };
  const handleCloseNewOrder = () => {
    setOpenNewOrder(false);
  };

  const [openNewQuote, setOpenNewQuote] = useState(false);
  const handleOpenNewQuote = () => {
    // update seller to customer notes
    formik.setFieldValue("notes", selectedRequest.notes);
    setOpenNewQuote(true);
  };
  const handleCloseNewQuote = () => {
    setOpenNewQuote(false);
  };

  // request quote and order is 1 to 1 to 1
  // order has it's own status of canceled or ...
  // as long as there is quote in request, we can show create "order" button
  const [currentAction, setCurrentAction] = useState(
    selectedRequest.status === undefined || selectedRequest.status === ""
      ? "quote"
      : "order"
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeleteRequest = Boolean(anchorEl);

  const [anchorElRequote, setAnchorElRequote] =
    useState<HTMLButtonElement | null>(null);
  const handleRequotePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRequote(event.currentTarget);
  };
  const handleCloseRequotePopover = () => {
    setAnchorElRequote(null);
  };
  const openRequoteRequest = Boolean(anchorElRequote);

  return !formPublished ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      <AppNav
        required={allRequests.length !== 0}
        drawerName="All requests"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />
      <SubNav
        returnTo="/admin/orders"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
        backBtn="Back"
      />
      <Box component="main" sx={{ p: { xs: 2, sm: 3 }, mt: 6, width: "100%" }}>
        <CardStyle>
          <Stack alignItems="center" spacing={2}>
            <Typography variant="subtitle2">
              Create a custom order form to receive requests.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/admin/form");
              }}
            >
              Create form
            </Button>
          </Stack>
        </CardStyle>
      </Box>
    </Box>
  ) : allRequests.length === 0 ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      <AppNav
        required={allRequests.length !== 0}
        drawerName="All requests"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />
      <Box component="main" sx={{ p: { xs: 2, sm: 3 }, mt: 6, width: "100%" }}>
        <CardStyle>
          <Typography variant="subtitle2">
            You don't have any requests at the moment. You will receive an email
            once a new request is submitted.
          </Typography>
        </CardStyle>
      </Box>
    </Box>
  ) : selectedRequest.requestId === "" ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* ---------- app bar (no request) ---------- */}
      <AppNav
        required={allRequests.length !== 0}
        drawerName="All requests"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />

      {/* ---------- drawer (no request) ---------- */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        {/* ---------- mobile drawer (no request) ---------- */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
        {/* ---------- desktop drawer (no request) ---------- */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
          open
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
      </Box>

      {/* ---------- request content (no request) ---------- */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: {
            md: `calc(100% - ${(<RequestsDrawer />)}px)`,
          },
          minHeight: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <ContentWrapNarrow>
          <CardStyle>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="flex-start"
            >
              <Stack flexGrow={1}>
                <Typography variant="h5">
                  {!user ? "Please sign in" : "Request doesn't exist"}
                </Typography>
              </Stack>
            </Stack>
          </CardStyle>
        </ContentWrapNarrow>
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* ---------- app bar ---------- */}
      <SubNav
        drawerName="All requests"
        returnTo="/admin/orders"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
        backBtn="Back"
      />

      {/* ---------- drawer ---------- */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        {/* ---------- mobile drawer ---------- */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
        {/* ---------- desktop drawer ---------- */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
          open
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
      </Box>

      {/* ---------- request content ---------- */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: {
            md: `calc(100% - ${(<RequestsDrawer />)}px)`,
          },
          minHeight: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <ContentWrapNarrow>
          <Stack spacing={2}>
            <CardStyle sx={{ flex: 1 }}>
              {moment(selectedRequest.customerDate).isBefore(
                moment(),
                "day"
              ) ? (
                <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                  This request will be automatically deleted on{" "}
                  {formatDateFromString(
                    moment(selectedRequest.customerDate).add(30, "day")
                  )}{" "}
                  (30 days after the due date).
                </Alert>
              ) : null}

              <Header
                heading={"Request #" + selectedRequest.requestId}
                meta={
                  "Requested on " +
                  formatDateFromString(selectedRequest.created)
                }
              >
                {!!selectedRequest.orderId ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate(`/admin/orders/${selectedRequest.orderId}`);
                    }}
                  >
                    View order
                  </Button>
                ) : !selectedRequest.status ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCurrentAction("quote");
                      handleOpenNewQuote();
                    }}
                  >
                    Create quote
                  </Button>
                ) : (
                  <Button
                    variant={
                      selectedRequest.status === REQUEST_STATUS_ACCEPTED
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setCurrentAction("order");
                      handleOpenNewOrder();
                    }}
                  >
                    Create order
                  </Button>
                )}
              </Header>
              <Dialog
                open={openNewQuote}
                onClose={handleCloseNewQuote}
                fullScreen={fullScreen}
                fullWidth={true}
              >
                <DialogTitle>New quote</DialogTitle>
                <DialogContent dividers>
                  <NewQuoteInfo formik={formik} sellerProfile={sellerProfile} />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setOpenNewQuote(false);
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  >
                    Create quote
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openNewOrder}
                onClose={handleCloseNewOrder}
                fullScreen={fullScreen}
                fullWidth={true}
              >
                <DialogTitle>New order</DialogTitle>
                <DialogContent dividers>
                  <InfoBox sx={{ mb: 2 }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <ForwardToInboxRoundedIcon />
                      <Typography>
                        Once the order is created, a confirmation email will be
                        sent to your customer.
                      </Typography>
                    </Stack>
                  </InfoBox>
                  <NewOrderInfo formik={formik} sellerProfile={sellerProfile} />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenNewOrder(false);
                      formik.resetForm();
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      console.log("formmmm1");
                      formik.submitForm();
                    }}
                    variant="contained"
                  >
                    Create order
                  </Button>
                </DialogActions>
              </Dialog>

              {selectedRequest.status === "" ||
              // if we got order id but no quote id,
              // that means it is legacy one where we don't have quote step
              (!!selectedRequest.orderId && !selectedRequest.quoteId) ? null : (
                <InfoBox sx={{ mt: 2 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <Stack
                      direction={{ sm: "row", xs: "column" }}
                      alignItems="center"
                      columnGap={0.5}
                      sx={{ opacity: !selectedRequest.status ? 0.3 : 1 }}
                    >
                      {selectedRequest.status === REQUEST_STATUS_QUOTED ||
                      selectedRequest.status === REQUEST_STATUS_ACCEPTED ||
                      selectedRequest.status === REQUEST_STATUS_ORDERED ? (
                        <CheckBoxRoundedIcon color="success" />
                      ) : (
                        <LooksOneOutlinedIcon />
                      )}
                      <Typography variant="subtitle2">Quoted</Typography>
                    </Stack>
                    <Box
                      sx={{
                        height: "1.5px",
                        backgroundColor: "text.primary",
                        opacity: 0.3,
                      }}
                      flex={1}
                    />
                    <Stack
                      direction={{ sm: "row", xs: "column" }}
                      alignItems="center"
                      columnGap={0.5}
                      sx={{
                        opacity: !selectedRequest.status ? 0.3 : 1,
                      }}
                    >
                      {selectedRequest.status === REQUEST_STATUS_ACCEPTED ||
                      selectedRequest.status === REQUEST_STATUS_ORDERED ? (
                        <CheckBoxRoundedIcon color="success" />
                      ) : (
                        <LooksTwoOutlinedIcon color="success" />
                      )}
                      <Typography variant="subtitle2">Accepted</Typography>
                    </Stack>
                    <Box
                      sx={{
                        height: "1.5px",
                        backgroundColor: "text.primary",
                        opacity: 0.3,
                      }}
                      flex={1}
                    />
                    <Stack
                      direction={{ sm: "row", xs: "column" }}
                      alignItems="center"
                      columnGap={0.5}
                      sx={{
                        opacity:
                          !selectedRequest.status ||
                          selectedRequest.status === REQUEST_STATUS_QUOTED
                            ? 0.3
                            : 1,
                      }}
                    >
                      {selectedRequest.status === REQUEST_STATUS_ORDERED ? (
                        <CheckBoxRoundedIcon color="success" />
                      ) : (
                        <Looks3OutlinedIcon
                          color={
                            selectedRequest.status === REQUEST_STATUS_QUOTED
                              ? "secondary"
                              : "success"
                          }
                        />
                      )}
                      <Typography variant="subtitle2">Ordered</Typography>
                      {currentOrder &&
                      currentOrder.orderTotal != selectedRequest.orderTotal ? (
                        <Typography variant="subtitle2" color="warning.main">
                          (${currentOrder?.orderTotal})
                        </Typography>
                      ) : null}
                    </Stack>
                  </Stack>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Stack spacing={1}>
                    <Stack>
                      <Typography variant="body2" color="text.secondary">
                        Your quote
                      </Typography>
                      <Stack direction="row" alignItems="center" columnGap={1}>
                        <Typography variant="h5">
                          ${selectedRequest.orderTotal}
                        </Typography>
                        <Button
                          size="small"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            setCurrentAction("quote");
                            if (!!selectedRequest.orderId) {
                              handleRequotePopover(e);
                            } else {
                              handleOpenNewQuote();
                            }
                          }}
                        >
                          Re-quote
                        </Button>
                        <Popover
                          id="requote"
                          open={openRequoteRequest}
                          anchorEl={anchorElRequote}
                          onClose={handleCloseRequotePopover}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box sx={{ p: 2 }}>
                            <Typography sx={{ mb: 1, maxWidth: "400px" }}>
                              An order has already been created for this
                              request. Re-quoting will delete the existing
                              order.
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              <Button
                                variant="outlined"
                                onClick={handleCloseRequotePopover}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  if (user) {
                                    handleCloseRequotePopover();
                                    handleOpenNewQuote();
                                  }
                                }}
                              >
                                Re-quote
                              </Button>
                            </Stack>
                          </Box>
                        </Popover>
                      </Stack>
                    </Stack>
                    {selectedRequest.notes === "" ? null : (
                      <Typography sx={{ whiteSpace: "pre-line" }}>
                        <Typography component="span" variant="subtitle2">
                          Note to customer:{" "}
                        </Typography>
                        {selectedRequest.notes}
                      </Typography>
                    )}
                    {selectedRequest.status === REQUEST_STATUS_ORDERED ||
                    selectedRequest.status ===
                      REQUEST_STATUS_ACCEPTED ? null : (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://${SITE_URL}/${QUOTE_STATUS_PAGE_URL}?info=${encodeRequestInfo(
                              businessInfo.businessInfo.id,
                              selectedRequest.requestId,
                              selectedRequest.phoneNumber
                            )}`
                          );
                          toast("Link copied.", {
                            position: "top-center",
                            autoClose: 800,
                          });
                        }}
                      >
                        Copy quote link
                      </Button>
                    )}
                  </Stack>
                </InfoBox>
              )}

              <Box sx={{ mt: 2 }}>
                <OverviewGrid
                  name={
                    selectedRequest.firstName + " " + selectedRequest.lastName
                  }
                  qty={`${selectedRequest.count}
              ${selectedRequest.unit == Unit.Dozen ? " dozen" : " cookies"}`}
                  date={formatDateFromString(selectedRequest.customerDate)}
                  fulfillment={selectedRequest.customerFulfillment}
                />
              </Box>
            </CardStyle>

            <CardStyle>
              <ContactInfo
                phone={selectedRequest.phoneNumber}
                email={selectedRequest.email}
                preferredContact={selectedRequest.preferredContact}
              />
            </CardStyle>

            {selectedRequest.customerFulfillment === "Shipping" ? (
              <CardStyle>
                <ShipTo
                  shippingAddress1={selectedRequest.shippingAddress1}
                  shippingAddress2={selectedRequest.shippingAddress2}
                  shippingCity={selectedRequest.shippingCity}
                  shippingState={selectedRequest.shippingState}
                  shippingZip={selectedRequest.shippingZip}
                />
              </CardStyle>
            ) : null}

            <CardStyle sx={{ flex: 1, mt: 3 }}>
              <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
                Details
              </Typography>
              <Stack spacing={2}>
                {selectedRequest.bucketLists === undefined ||
                selectedRequest.bucketLists === DEFAULT_BUCKET_LIST ? null : (
                  <LabeledText
                    label="Bucket list item"
                    text={selectedRequest.bucketLists}
                  />
                )}
                {selectedRequest.event ? (
                  <LabeledText label="Event" text={selectedRequest.event} />
                ) : null}
                {selectedRequest.theme ? (
                  <LabeledText label="Theme" text={selectedRequest.theme} />
                ) : null}
                {selectedRequest.colors ? (
                  <LabeledText label="Colors" text={selectedRequest.colors} />
                ) : null}
                {selectedRequest.personalization ? (
                  <LabeledText
                    label="Personalization/other requirements"
                    text={selectedRequest.personalization}
                    keepTextFormatting
                  />
                ) : null}
                {selectedRequest.flavors?.length > 0 ? (
                  <LabeledText
                    label="Flavor"
                    text={selectedRequest.flavors[0]}
                  />
                ) : null}
                {selectedRequest.packagings?.length > 0 ? (
                  <LabeledText
                    label="Packaging"
                    text={selectedRequest.packagings[0]}
                  />
                ) : null}
                {selectedRequest.questions ? (
                  <LabeledText
                    label="Questions/comments"
                    text={selectedRequest.questions}
                    keepTextFormatting
                  />
                ) : null}
              </Stack>
            </CardStyle>

            {selectedRequest.designInspirations?.length ? (
              <CardStyle>
                <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
                  Design inspirations
                </Typography>
                <Carousel
                  showThumbs={false}
                  autoPlay={false}
                  infiniteLoop={true}
                  dynamicHeight={true}
                  showStatus={selectedRequest.designInspirations.length >= 2}
                >
                  {selectedRequest.designInspirations.map(
                    (image, index): any => (
                      <img key={index} src={image} />
                    )
                  )}
                </Carousel>
              </CardStyle>
            ) : null}

            <Box sx={{ pt: 1 }}>
              <Button
                variant="text"
                onClick={handleClickPopover}
                startIcon={<DeleteRoundedIcon />}
                sx={{
                  width: "fit-content",
                  color: "text.secondary",
                }}
              >
                Delete request
              </Button>
              <Popover
                id="deletePresale"
                open={openDeleteRequest}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography sx={{ mb: 1, maxWidth: "400px" }}>
                    This action will delete the request. Would you like to
                    proceed?
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button onClick={handleClosePopover} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        if (user) {
                          handleClosePopover();
                          dispatch(
                            deleteRequestAsync({
                              formId: businessInfo.businessInfo.id,
                              requestId: selectedRequest.requestId,
                            })
                          );
                          navigate("/admin/requests");
                        }
                      }}
                    >
                      Yes, delete
                    </Button>
                  </Stack>
                </Box>
              </Popover>
            </Box>
          </Stack>
        </ContentWrapNarrow>
      </Box>
    </Box>
  );
};

export default AllRequests;
