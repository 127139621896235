import { Button, Box } from "@mui/material";

import GoogleButton from "Assets/icon-google.png";

export const GoogleSignInButton = ({ ...props }) => {
  return (
    <Box>
      <Button
        sx={{ backgroundColor: "white", p: 0, borderWidth: 0, width: "200px" }}
        {...props}
      >
        <img src={GoogleButton} style={{ width: "inherit" }} />
      </Button>
    </Box>
  );
};
