import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";
import USStates from "Components/USStates.json";

// ui related
import {
  TextField,
  Select,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerShipping = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  if (
    businessInfo.fulfillmentMethod === "Pickup or shipping" &&
    customerFormik.values.customerFulfillment === "Shipping"
  ) {
    return (
      <FormSection id="address" sectionName="Shipping address">
        <TextField
          fullWidth
          margin="normal"
          required
          id="shippingAddress1"
          label="Address line 1"
          onChange={customerFormik.handleChange}
          onBlur={customerFormik.handleBlur}
          error={
            customerFormik.touched.shippingAddress1 &&
            Boolean(customerFormik.errors.shippingAddress1)
          }
          helperText={
            customerFormik.touched.shippingAddress1 &&
            customerFormik.errors.shippingAddress1
          }
          sx={{ mt: 0 }}
        />
        <TextField
          fullWidth
          margin="normal"
          id="shippingAddress2"
          label="Address line 2"
          onChange={customerFormik.handleChange}
          onBlur={customerFormik.handleBlur}
        />
        <TextField
          fullWidth
          margin="normal"
          required
          id="shippingCity"
          label="City"
          onChange={customerFormik.handleChange}
          onBlur={customerFormik.handleBlur}
          error={
            customerFormik.touched.shippingCity &&
            Boolean(customerFormik.errors.shippingCity)
          }
          helperText={
            customerFormik.touched.shippingCity &&
            customerFormik.errors.shippingCity
          }
        />
        <Grid container columnSpacing={2} columns={{ xs: 4, sm: 12, md: 12 }}>
          <Grid item xs={6} sm={6} md={6}>
            <FormControl
              required
              fullWidth
              sx={{ mt: 2, mb: 1 }}
              error={
                customerFormik.touched.shippingState &&
                Boolean(customerFormik.errors.shippingState)
              }
            >
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                value={customerFormik.values.shippingState}
                onChange={(e) => {
                  customerFormik.setFieldValue("shippingState", e.target.value);
                }}
              >
                {USStates.map((state) => (
                  <MenuItem value={state.abbreviation} key={state.name}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {customerFormik.touched.shippingState &&
                  customerFormik.errors.shippingState}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              margin="normal"
              required
              id="shippingZip"
              label="Zip code"
              onChange={customerFormik.handleChange}
              onBlur={customerFormik.handleBlur}
              error={
                customerFormik.touched.shippingZip &&
                Boolean(customerFormik.errors.shippingZip)
              }
              helperText={
                customerFormik.touched.shippingZip &&
                customerFormik.errors.shippingZip
              }
            />
          </Grid>
        </Grid>
      </FormSection>
    );
  } else {
    return null;
  }
};
