import { OrderInfo } from "Model/OrderInfo";
import { Draggable } from "react-beautiful-dnd";
import { ViewOrder } from "../Components/ViewOrder";

// ui related
import { Box } from "@mui/material";

type OrderItemProps = {
  index: number;
  order: OrderInfo;
};

export const OrderItem = ({ index, order }: OrderItemProps) => {
  return (
    <Draggable key={order.orderId} draggableId={order.orderId} index={index}>
      {(provided, snapshot) => (
        <Box
          key={order.orderId}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ViewOrder view="kanban" orderInfo={order} />
        </Box>
      )}
    </Draggable>
  );
};
