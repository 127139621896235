import { Box } from "@mui/material";

type Props = {
  children: React.ReactNode;
};

export const ContentWrapNarrow = ({ children }: Props) => {
  // wraps content with max midth
  return (
    <Box sx={{ maxWidth: "600px", ml: "auto", mr: "auto" }}>{children}</Box>
  );
};
