import { deleteEmailFromList } from "Util/firebaseHelper";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const UnsubscribeEmail = () => {
  ///unsubscribe?id=xxxx
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data") || "";
  const [email, setEmail] = useState("");

  useEffect(() => {
    try {
      const data = JSON.parse(atob(encodedData));
      if (data.email && data.id) {
        setEmail(data.email);
        deleteEmailFromList(data.email, data.id);
      }
    } catch (e) {}
  });

  return (
    <div>
      <h1>Email Unsubscribed</h1>
      <p>Your email {email} has been successfully unsubscribed.</p>
    </div>
  );
};
