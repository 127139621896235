import React, { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate, useParams } from "react-router-dom";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  Box,
  Button,
  Typography,
  Stack,
  Toolbar,
  AppBar,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { LabeledText } from "Components/LabeledText";
import { InfoBox, SignInBtn } from "Components/AllComponents";
import Logo from "Assets/logo-full.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { inspirations, InspirationItem } from "Model/Inspiration";
import Thumb from "Assets/thumbnail.png";

const Discover = () => {
  const { topic } = useParams<{ topic: string }>();

  const [selectedTopic, setSelectedTopic] = useState(
    topic || inspirations[0]?.topic || ""
  );
  const [pins, setPins] = useState<InspirationItem[]>([]);

  useEffect(() => {
    const lowerCaseTopic = topic?.toLowerCase();
    const selectedInspiration = inspirations.find(
      (inspiration) => inspiration.topic.toLowerCase() === lowerCaseTopic
    );
    if (selectedInspiration) {
      setSelectedTopic(selectedInspiration.topic);
      setPins(selectedInspiration.items);
    } else {
      setSelectedTopic(inspirations[0]?.topic || "");
      setPins(inspirations[0]?.items || []);
    }
  }, [topic]);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedTopic(event.target.value);
    const selectedInspiration = inspirations.find(
      (inspiration) => inspiration.topic === event.target.value
    );
    if (selectedInspiration) {
      setPins(selectedInspiration.items);
    }
  };
  // Helper function to build a proper URL
  const buildProperUrl = (url: string): string => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }
    return `http://${url}`;
  };
  const { user } = useSession();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [currentPin, setCurrentPin] = useState({} as InspirationItem);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack
      sx={{
        p: { xs: 2, sm: 3 },
        backgroundColor: "background.default",
        minHeight: "100vh",
      }}
    >
      <AppBar>
        <Toolbar variant="dense">
          <Typography variant="subtitle2" color="text.primary" flex={1}>
            Inspirations
          </Typography>
          <Stack direction="row" spacing={1}>
            {user ? (
              <Stack direction="row" spacing={1}>
                {/* <Button
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  onClick={() => {}}
                >
                  Share to inspire
                </Button> */}
                <Button
                  variant="outlined"
                  onClick={async () => {
                    navigate("/admin/presales");
                  }}
                >
                  My presales
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={1}>
                <SignInBtn
                  variant="outlined"
                  label="Flowlylink sign in"
                  username=""
                />
              </Stack>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      <Stack sx={{ mt: 6 }} />

      {!user ? (
        <InfoBox sx={{ mb: 2, backgroundColor: "white" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <a href="/" style={{ width: 160 }}>
              <img
                src={Thumb}
                width="100%"
                alt="img"
                style={{ borderRadius: "4px" }}
              />
            </a>
            <Stack>
              <Typography sx={{ mb: 1 }}>
                Streamline your custom orders and presales with Flowlylink.
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate("/");
                }}
                sx={{ width: "fit-content" }}
              >
                Learn more
              </Button>
            </Stack>
          </Stack>
        </InfoBox>
      ) : null}

      <Typography variant="h5" sx={{ mb: 3 }}>
        Get inspired for your next{" "}
        <Select
          value={selectedTopic}
          onChange={handleChange}
          displayEmpty
          sx={{
            ml: 1,
            mr: 1,
            fontFamily: "Frank Ruhl Libre",
            fontWeight: 600,
            fontSize: "1.7rem",
            "& .MuiInputBase-input": {
              padding: 1,
            },
          }}
        >
          {inspirations.map((topic) => (
            <MenuItem key={topic.topic} value={topic.topic}>
              {topic.topic}
            </MenuItem>
          ))}
        </Select>
        presale:
      </Typography>

      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3, 1200: 4, 1500: 5 }}
      >
        <Masonry gutter="12px">
          {pins.map((item) => (
            <Stack
              onClick={() => {
                setCurrentPin(item);
                if (
                  item.link !== "" &&
                  !item.link.startsWith("https://flowlylink.com/")
                ) {
                  window.open(buildProperUrl(item.link), "_blank");
                } else {
                  handleClickOpen();
                }
              }}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.85 } }}
              key={item.href}
            >
              <img
                style={{
                  width: "100%",
                  display: "block",
                  borderRadius: "20px",
                }}
                src={item.img}
                alt=""
              />
            </Stack>
          ))}
        </Masonry>
      </ResponsiveMasonry>

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{ m: 0, p: 2, color: "white" }}
          id="customized-dialog-title"
        >
          ITEM NAME
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
        <DialogContent sx={{ pt: 0 }}>
          <img
            style={{
              width: "100%",
              display: "block",
              marginBottom: "16px",
            }}
            src={currentPin.img}
            alt=""
          />
          {currentPin.description !== " " ? (
            <LabeledText
              keepTextFormatting
              label="Supplies"
              text={currentPin.description}
            />
          ) : null}
          {/* <Stack spacing={1}>
            <LabeledText label="Price" text="$8" />
            <LabeledText
              label="Cutters"
              text="Cutter seller 1, Cutter seller 2"
            />
            <LabeledText label="Packaging" text="Seller 1,Seller 2" />
            <LabeledText label="Other suppliers" text="Seller 1,Seller 2" />
          </Stack> */}
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default Discover;
