import { grey } from "@mui/material/colors";
import { ColorPalette, allPalettes, como } from "./colors";
import { useSession } from "Context/userAuthContext";

export const paletteDefault = {
  primary: {
    main: como[700],
    light: como[500],
    dark: como[800],
  },
  secondary: {
    main: grey[900],
    light: grey[700],
    dark: grey[800],
  },
  background: {
    default: grey[100],
  },
  text: {
    primary: "#222222",
    secondary: "#717171",
  },
  success: { main: como[700] },
  error: { main: "#CB3025" },
  warning: { main: "#E47600" },
};

export function createCustomPalette(userChoice: string): Record<string, any> {
  // Get the selected palette based on user choice or fallback to "como" if not found
  const selectedPalette: ColorPalette = allPalettes[userChoice] || como;

  return {
    primary: {
      main: selectedPalette[700],
      light: selectedPalette[500],
      dark: selectedPalette[800],
    },
    secondary: {
      main: grey[900],
      light: grey[700],
      dark: grey[800],
    },
    background: {
      default: selectedPalette[10],
    },
    text: {
      primary: "#222222",
      secondary: "#717171",
    },
    success: { main: como[700] },
    error: { main: "#CB3025" },
    warning: { main: "#E47600" },
    action: { hover: selectedPalette[20] },
  };
}
