import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultCart, CartItem } from "Model/Cart";
import { PresaleItem } from "Model/PresaleItem";
import { PresaleOrderItemCustomization } from "Model/PresaleOrder";

export const cartSlice = createSlice({
  name: "cart",
  initialState: defaultCart,
  reducers: {
    initializeCart: (state, action: PayloadAction<PresaleItem[]>) => {
      const cartItems: CartItem[] = action.payload.map((presaleItem) => {
        const { id, price, title, img } = presaleItem;
        return {
          presaleItemId: id,
          price,
          count: 0,
          personalization: [],
          customization: [],
          itemTotalPrice: 0,
          presaleItemName: title,
          img,
        };
      });
      state.items = cartItems.reduce(
        (itemsMap: { [key: string]: CartItem }, item) => {
          itemsMap[item.presaleItemId] = item;
          return itemsMap;
        },
        {}
      );
      state.totalPrice = 0;
    },

    updateItemByCount: (
      state,
      action: PayloadAction<{
        item: CartItem;
        deltaCount: number; // delta can only be +1 or -1
        customizable: boolean;
        customization: PresaleOrderItemCustomization;
        customizationToDeleteIndex: number;
      }>
    ) => {
      const { presaleItemId, price, personalization, presaleItemName, img } =
        action.payload.item;
      const customization = action.payload.customization;
      const deltaCount = action.payload.deltaCount;
      const existingItem = state.items[presaleItemId];
      const priceDifference = action.payload.customizable
        ? customization.price * deltaCount
        : price * deltaCount;
      if (existingItem) {
        existingItem.count += deltaCount;
        if (deltaCount > 0) {
          // add delta empty strings to the end of the array
          existingItem.customization.push(action.payload.customization);
        } else if (deltaCount < 0) {
          // remove the last -delta elements from the array
          existingItem.customization.splice(
            action.payload.customizationToDeleteIndex,
            1
          );
        }
        existingItem.itemTotalPrice += priceDifference;
        state.totalPrice += priceDifference;
      } else {
        state.items[presaleItemId] = {
          presaleItemId,
          price,
          count: deltaCount,
          personalization: [],
          customization: action.payload.customizable
            ? [action.payload.customization]
            : [],
          itemTotalPrice: priceDifference,
          presaleItemName,
          img,
        };
        state.totalPrice += priceDifference;
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      const presaleItemId = action.payload;
      const existingItem = state.items[presaleItemId];

      if (existingItem) {
        state.totalPrice -= existingItem.itemTotalPrice;
        delete state.items[presaleItemId];
      }
    },
    clearCart: (state) => {
      state.items = {};
      state.totalPrice = 0;
    },
  },
});
