import { businessInfoSlice } from "./Reducers/businessInfo";
import { cartSlice } from "./Reducers/cart";
import { ordersSlice } from "./Reducers/orders";
import { ordersStatusSlice } from "./Reducers/ordersStatus";
import { presaleOrdersSlice } from "./Reducers/presaleOrders";
import { presalesSlice } from "./Reducers/presales";
import { requestsSlice } from "./Reducers/requests";
import { selectedOrderSlice } from "./Reducers/selectedOrder";
import { selectedPresaleSlice } from "./Reducers/selectedPresale";
import { selectedRequestSlice } from "./Reducers/selectedRequest";
import { sellerProfileSlice } from "./Reducers/sellerProfile";
import { subscribersSlice } from "./Reducers/subscribers";

export const { create, edit } = ordersSlice.actions;
export const { select } = selectedOrderSlice.actions;
export const { editBusinessInfo, setBusinessInfoDirty } =
  businessInfoSlice.actions;
export const { editSellerProfile } = sellerProfileSlice.actions;
export const { sortOrdersStatus } = ordersStatusSlice.actions;
export const { createPresale, editPresale, updatePresales } =
  presalesSlice.actions;
export const { selectPresale } = selectedPresaleSlice.actions;
export const { initializeCart, updateItemByCount, removeItem, clearCart } =
  cartSlice.actions;
export const {} = presaleOrdersSlice.actions;
export const {} = requestsSlice.actions;
export const { selectRequest } = selectedRequestSlice.actions;
export const { deleteSubscriber } = subscribersSlice.actions;
